import LockedViewDialog, { type LockedViewDialogProps } from '../../shared/components/LockedViewDialog'
import ProductsUpgrade from '../../../assets/images/products-upgrade.png'
import MiniCalendarDays from '../../../assets/heroicons/MiniCalendarDays'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import RectangleStackSolid from '../../../assets/heroicons/RectangleStackSolid'
import { type Component } from 'solid-js'

const dialogConfig: LockedViewDialogProps = {
  title: 'ls_locked_products_title',
  image: ProductsUpgrade,
  features: [
    'ls_locked_products_feature_1',
    'ls_locked_products_feature_2',
    'ls_locked_products_feature_3',
    'ls_locked_products_feature_4',
    'ls_locked_products_feature_5'
  ],
  extraFeatures: [
    { icon: <MiniCalendarDays />, name: 'ls_locked_products_extra_feature_1' },
    { icon: <MiniEtsy />, name: 'ls_locked_products_extra_feature_2' },
    { icon: <RectangleStackSolid />, name: 'ls_locked_products_extra_feature_3' }
  ]
}

const LockedProductsDialog: Component = () => {
  return (
    <LockedViewDialog {...dialogConfig} />
  )
}

export default LockedProductsDialog
