import { type DeleteDataSource, type GetDataSource, type PutDataSource } from './data-source/data-source'
import { type Operation } from './operation/operation'
import { type Query } from './query/query'
import { type DeleteRepository, type GetRepository, type PutRepository, type Repository } from './repository'

export class SingleDataSourceRepository<T> implements Repository<T> {
  constructor (
    private readonly getDataSource: GetDataSource<T>,
    private readonly putDataSource: PutDataSource<T>,
    private readonly deleteDataSource: DeleteDataSource,
  ) {
  }

  public async get (query: Query, _operation: Operation): Promise<T> {
    return await this.getDataSource.get(query)
  }

  /**
   * @deprecated please use get with an array type instead
   */
  public async getAll (query: Query, _operation: Operation): Promise<T[]> {
    console.warn('getAll is deprecated. Please use get instead')
    return await this.getDataSource.getAll(query)
  }

  public async put (value: T | undefined, query: Query, _operation: Operation): Promise<T> {
    return await this.putDataSource.put(value, query)
  }

  /**
   * @deprecated please use put with an array type instead
   */
  public async putAll (values: T[] | undefined, query: Query, _operation: Operation): Promise<T[]> {
    console.warn('putAll is deprecated. Please use put instead')
    return await this.putDataSource.putAll(values, query)
  }

  public async delete (query: Query, _operation: Operation): Promise<void> {
    await this.deleteDataSource.delete(query)
  }
}

export class SingleGetDataSourceRepository<T> implements GetRepository<T> {
  constructor (private readonly getDataSource: GetDataSource<T>) {
  }

  public async get (query: Query, _operation: Operation): Promise<T> {
    return await this.getDataSource.get(query)
  }

  /**
   * @deprecated please use get with an array type instead
   */
  public async getAll (query: Query, _operation: Operation): Promise<T[]> {
    console.warn('getAll is deprecated. Please use get instead')
    return await this.getDataSource.getAll(query)
  }
}

export class SinglePutDataSourceRepository<T> implements PutRepository<T> {
  constructor (private readonly putDataSource: PutDataSource<T>) {
  }

  public async put (value: T | undefined, query: Query, _operation: Operation): Promise<T> {
    return await this.putDataSource.put(value, query)
  }

  /**
   * @deprecated please use put with an array type instead
   */
  public async putAll (values: T[] | undefined, query: Query, _operation: Operation): Promise<T[]> {
    console.warn('putAll is deprecated. Please use put instead')
    return await this.putDataSource.putAll(values, query)
  }
}

export class SingleDeleteDataSourceRepository implements DeleteRepository {
  constructor (
    private readonly deleteDataSource: DeleteDataSource,
  ) {
  }

  public async delete (query: Query, _operation: Operation): Promise<void> {
    await this.deleteDataSource.delete(query)
  }
}
