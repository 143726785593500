import { type Product } from './product'
import { type CogsType } from './cogs'

export enum ProductFilter {
  All = 'all',
  MultipleListing = 'multiple_listings',
  WithoutCOGS = 'cogs_issues',
  WithoutSKU = 'without_sku'
}

export enum CogsStatus {
  Valid = 'valid',
  Missing = 'missing',
  MissingWithoutOrders = 'missing_without_orders',
  FirstPeriodAfterFirstOrder = 'first_period_after_first_order',
  CurrentPeriodMismatch = 'current_period_mismatch',
  PreviousPeriodMismatch = 'previous_period_mismatch',
}

export class ProductCost {
  constructor (
    public readonly sku: string | undefined,
    public readonly cogsStatus: CogsStatus,
    public readonly hasMultipleCogs: boolean,
    public readonly productCount: number,
    public readonly minPrice: number,
    public readonly maxPrice: number,
    public readonly cogsType: CogsType,
    public readonly product: Product
  ) {
  }
}
