import {
  Show,
  type Component
} from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import MiniExclamationCircle from '../../../assets/heroicons/MiniExclamationCircle'

export interface SkuCellProps {
  readonly sku?: string
}

const SkuCell: Component<SkuCellProps> = (props) => {
  return (
    <div>
      <Show when={props.sku} fallback={
        <div class="flex items-center gap-2">
          <Trans key="ls_shared_wo_sku"/><span class="text-orange-500"><MiniExclamationCircle /></span>
        </div>
      }>{(sku) => (
        sku()
      )}</Show>
    </div>
  )
}

export default SkuCell
