import type { Component } from 'solid-js'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import MiniTag from '../../../assets/heroicons/MiniTag'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../../shared/app-routes'
import { type Listing } from '../../../features/product/domain/models/listing'
import { Trans } from '@mbarzda/solid-i18next'

export interface ViewProductListingOnEtsyCellProps {
  readonly listing: Listing
  readonly variantName: string
}

const ViewProductListingOnEtsyCell: Component<ViewProductListingOnEtsyCellProps> = (props) => {
  const listingTitle = props.listing.isDeleted() ? 'ls_listing_deleted' : props.listing.title
  return (
    <div class="flex flex-col gap-1">
      <div class="text-gray-900 flex gap-1 items-center">
        <a href={props.listing.url} target="_blank"><span class="w-4"><MiniEtsy size={4}/></span></a>
        <A href={AppRoutes.Listing(props.listing.id)}>
          <span class={`${props.listing.isDeleted() ? 'text-gray-500' : 'text-gray-900'} line-clamp-1`}><Trans key={listingTitle} /></span>
        </A>
      </div>
      <div class="text-xs text-blue-500">
        <div class="text-gray-500 flex items-center gap-1">
          <span class="w-4 flex justify-center"><MiniTag size={3.5}/></span>
          <span class="line-clamp-1">{props.variantName}</span>
        </div>
      </div>
    </div>
  )
}

export default ViewProductListingOnEtsyCell
