import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import AllInEtsyMasterclassCard from './AllInEtsyMasterclassCard'
import GroupCoachingSessionsCard from './GroupCoachingSessionsCard'
import AcademicCapSolid from '../../assets/heroicons/AcademicCapSolid'

export interface EducationProps {
  fullWidth?: true
}

const Education: Component<EducationProps> = (props) => {
  return (
    <div class="flex flex-col gap-2 w-full max-w-[1168px] mx-auto" classList={{ 'max-w-[1168px]': !props.fullWidth }}>
      <h1 class="page-title"><AcademicCapSolid /><Trans key="ls_education_title"/></h1>
      <span class="text-sm text-gray-500"><Trans key="ls_education_description"/></span>
      <div class="mt-6 flex flex-col xl:flex-row xl:flex-wrap items-center lg:items-start gap-6">
        <AllInEtsyMasterclassCard />
        <GroupCoachingSessionsCard />
      </div>
    </div>
  )
}

export default Education
