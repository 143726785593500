import { type Timeseries } from '../../../features/analytics/domain/models/timeseries'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../tailwind.config'
import { LinearTimeseriesVm } from './linear-timeseries-vm'
import { type KPI, KPISign } from '../../../features/analytics/domain/models/kpi'
import { formatDateToUTCMonthYear } from '../../../shared/helpers/date.functions'
import { PeriodType } from '../../../features/shared/period-type'

export const periodTypeToTimeSeriesPeriodType = (periodType: PeriodType): PeriodType => {
  switch (periodType) {
    case PeriodType.Today:
    case PeriodType.Yesterday:
      return PeriodType.Last7Days
    case PeriodType.MonthToDate:
      return PeriodType.CurrentMonth
    case PeriodType.QuarterToDate:
      return PeriodType.CurrentQuarter
    case PeriodType.YearToDate:
      return PeriodType.CurrentYear
    default:
      return periodType
  }
}

const periodIncludesIncompleteData = (periodType: PeriodType): boolean => {
  return [
    PeriodType.Today,
    PeriodType.Yesterday,
    PeriodType.Last7Days,
    PeriodType.Last30Days,
    PeriodType.MonthToDate,
    PeriodType.QuarterToDate,
    PeriodType.YearToDate
  ].includes(periodType)
}

export const timeseriesToLinearTimeseriesVmMapper = (
  series: Timeseries,
  prevSeries: Timeseries | undefined,
  kpi: KPI,
  periodType: PeriodType,
  dateFormatter: (date: Date, useUTC: boolean) => string
): LinearTimeseriesVm => {
  const colors = resolveConfig(tailwindConfig)?.theme?.colors
  if (!colors) throw new Error('Tailwind config is not valid')

  const labels = [PeriodType.YearToDate, PeriodType.LastYear].includes(periodType)
    ? series.dateTimes.map(datetime => {
      return formatDateToUTCMonthYear(datetime)
    })
    : series.dateTimes.map(datetime => {
      return dateFormatter(datetime, true)
    })

  const labelFormatter = (value: string): string => {
    if ([PeriodType.QuarterToDate, PeriodType.LastQuarter].includes(periodType)) {
      const position = labels.indexOf(value)
      return position % 5 === 1 ? value : ''
    }
    return value
  }

  const currentPeriodSeries = series.series[kpi.id] ?? []
  const cleanCurrentPeriodSeries = periodIncludesIncompleteData(periodType)
    ? removeLastTwoValuesFromArray(currentPeriodSeries, 1)
    : currentPeriodSeries

  return new LinearTimeseriesVm(
    labels,
    colors.blue['400'],
    cleanCurrentPeriodSeries,
    prevSeries?.series[kpi.id] ?? [],
    kpi.type,
    KPISign[kpi.id],
    labelFormatter,
    currentPeriodSeries
  )
}

const removeLastTwoValuesFromArray = <T>(arr: Array<T | null>, numValues: number): Array<T | null> => {
  let count = 0
  return arr
    .slice()
    .reverse()
    .map(value => {
      if (value !== null && count < numValues) {
        count++
        return null
      }
      return value
    })
    .reverse()
}
