import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { UpdateCogsHistoricQuery } from '../../data/queries/update-cogs-historic.query'
import { type Cogs } from '../models/cogs'

export class UpdateProductCogsHistoricInteractor {
  constructor(
    private readonly updateProductCogsHistoric: PutInteractor<void>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number,
    historic: Cogs[]
  ): Promise<void> {
    await this.updateProductCogsHistoric.execute(undefined, new UpdateCogsHistoricQuery(shopId, productId, historic))
  }
}
