import { DeleteNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { IntegrationType } from '../../domain/models/shop'

export class DisconnectIntegrationQuery extends DeleteNetworkQuery {
  constructor (private readonly shopId: number, integration: IntegrationType) {
    switch (integration) {
      case IntegrationType.Printful:
        super(ApiEndpoint.Printful)
        break
      case IntegrationType.Printify:
        super(ApiEndpoint.Printify)
        break
      default:
        throw new Error('Integration not supported')
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      shopId: this.shopId
    }
  }
}
