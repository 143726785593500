import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { IntegrationType } from '../../domain/models/shop'

export class IntegrationAuthUrlQuery extends PostNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly redirectUrl: string,
    integration: IntegrationType
  ) {
    switch (integration) {
      case IntegrationType.Printful:
        super(ApiEndpoint.PrintfulConnect)
        break
      case IntegrationType.Printify:
        super(ApiEndpoint.PrintifyConnect)
        break
      default:
        throw new Error('Integration not supported')
    }
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      redirect_url: this.redirectUrl
    }
  }
}
