import { type Component, For, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import CheckCircle from '../../assets/heroicons/CheckCircle'
import { t } from 'i18next'
import { type OfferingProduct, OfferingType } from '../../features/payment/domain/models/offering'
import { OfferingPeriod } from './offering-period'
import { type OfferingBoxProps } from './PaymentScreen'

export interface StarterOfferingBoxProps extends OfferingBoxProps {}

const StarterOfferingBox: Component<StarterOfferingBoxProps> = (props) => {
  const currencyFormatter = (value?: number, numFractionDigits: number = 2): string => {
    return new Intl
      .NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: numFractionDigits })
      .format(value!)
  }

  const featureList = [
    'ls_pay_starter_feature_1',
    'ls_pay_starter_feature_2',
    'ls_pay_starter_feature_3',
    'ls_pay_starter_feature_4',
    'ls_pay_starter_feature_5',
    'ls_pay_starter_feature_6'
  ]

  const getProduct = (): OfferingProduct => {
    return props.period === OfferingPeriod.Month
      ? props.offering.monthly
      : props.offering.annual
  }

  const getActionText = (activeProduct: OfferingProduct): string => {
    return activeProduct.offeringType === OfferingType.Starter ? 'ls_pay_select_plan' : 'ls_pay_downgrade'
  }

  return (
    <div class="flex flex-col w-full max-w-[360px] lg:max-w-[312px] rounded-2xl bg-white overflow-hidden md:shadow-md h-full">
      <div class="px-8 py-4 flex flex-col justify-center bg-japanese-600 h-[68px]">
        <span class="text-2xl text-white font-semibold"><Trans key="ls_pay_starter"/></span>
      </div>
      <div class="flex flex-col justify-center items-center p-4 gap-2">
        <div class="flex items-baseline gap-1">
          <Show when={props.period === OfferingPeriod.Month}>
            <span class="text-4xl text-gray-900 font-bold">
              {currencyFormatter(props.offering.monthly.price)}
            </span>
            <span>/ <Trans key="ls_shared_month_short"/></span>
          </Show>
          <Show when={props.period === OfferingPeriod.Year}>
            <span class="text-4xl text-gray-900 font-bold">
              {currencyFormatter(props.offering.annual.price / 12)}
            </span>
            <span>/ <Trans key="ls_shared_month_short"/></span>
          </Show>
        </div>
        <div class="flex flex-col text-[#059669] font-semibold text-sm text-center">
          <span><Trans key="ls_pay_early_offer"/></span>
          <span class="flex gap-1 justify-center">
            <span><Trans key="ls_pay_regular_price"/></span>
            {currencyFormatter(props.fakePrices[props.period])}/<Trans key="ls_shared_month_short"/>
          </span>
        </div>
      </div>
      <div class="p-6 flex flex-col flex-grow gap-4 border-t border-t-gray-200 text-sm">
        <span innerHTML={t('ls_pay_get_starter_access')}></span>
        <div class="flex flex-col gap-2">
          <For each={featureList}>{(feature) => (
            <div class="flex items-center gap-2">
              <span class="text-green-600"><CheckCircle size={4}/></span>
              <span innerHTML={t(feature)}></span>
            </div>
          )}</For>
        </div>
      </div>
      <div class="flex flex-col p-6 border-t border-t-gray-200 items-center gap-4 text-center">
        <Show when={!props.activeProduct}>
          <button onClick={() => { void props.showCheckout(getProduct().id) }} class="bg-[#FF5E2E] text-white h-14 w-full rounded-full hover:bg-orange-700 transition duration-200 font-medium capitalize">
            <Trans key="ls_pay_select_plan_info" options={{ interval: getProduct().trialPeriod.interval, frequency: getProduct().trialPeriod.frequency }}/>
          </button>
        </Show>
        <Show when={props.activeProduct}>{(activeProduct) => (
          <>
            <Show when={activeProduct().id === getProduct().id}>
              <div class="bg-gray-400 text-white h-14 w-full flex items-center justify-center rounded-full font-medium">
                <Trans key="ls_pay_current_plan"/>
              </div>
            </Show>
            <Show when={activeProduct().id !== getProduct().id}>
              <button onClick={() => { void props.showCheckout(getProduct().id) }} class="bg-[#FF5E2E] text-white h-14 w-full flex items-center justify-center rounded-full hover:bg-orange-700 hover:cursor-pointer transition duration-200 font-medium">
                <Trans key={getActionText(activeProduct())}/>
              </button>
            </Show>
          </>
        )}
        </Show>
      </div>
    </div>
  )
}

export default StarterOfferingBox
