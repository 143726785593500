import { Timeseries } from '../models/timeseries'
import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type TimeseriesEntity } from '../../data/entities/timeseries.entity'
import { type KPIType } from '../models/kpi'
import { startOfDay } from '../../../../shared/helpers/date.functions'
import { type TimeSeriesData } from '../../../../private/shared/timeseries/linear-timeseries-vm'

const removeFutureData = (kpiId: KPIType, from: TimeseriesEntity): TimeSeriesData => {
  return Object.values(from.points).map((point) => {
    return new Date(point.date * 1000) > startOfDay(new Date()) ? null : point.data_points[kpiId]
  })
}

export class TimeseriesEntityToTimeseriesMapper implements Mapper<TimeseriesEntity, Timeseries> {
  public map(from: TimeseriesEntity): Timeseries {
    const dates = Object.values(from.points).map((point) => new Date(point.date_label))
    const kpiIds = Object.keys(from.points[0].data_points) as KPIType[]
    const series: Partial<Record<KPIType, TimeSeriesData>> = {}
    for (const kpiId of kpiIds) {
      series[kpiId] = removeFutureData(kpiId, from)
    }
    return new Timeseries(dates, series)
  }
}
