import { type ProductEntity } from './product.entity'
import { type CogsStatus } from '../../domain/models/product-cost'
import { type CogsEntity } from './cogs.entity'

export class ProductCogsEntity {
  constructor (
    public readonly sku: string | undefined,
    public readonly cogs_status: CogsStatus,
    public readonly product: ProductEntity,
    public readonly number_of_products: number,
    public readonly cogs_historic: CogsEntity[],
    public readonly first_order_timestamp?: number
  ) {
  }
}
