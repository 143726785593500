import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type PeriodsState } from '../../../shared/period-state'
import { type SortDirection } from '../../../../shared/models/sort-direction'
import { type Order, type OrderStatus } from '../models/order'
import { GetOrdersQuery } from '../../data/queries/get-orders.query'
import type { ProductFilter } from '../models/product-cost'

export class GetOrdersInteractor {
  constructor(
    private readonly getOrders: GetInteractor<PaginationOffsetLimit<Order>>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    status?: OrderStatus[],
    orderBy?: string,
    searchTerm?: string,
    filter?: ProductFilter
  ): Promise<PaginationOffsetLimit<Order>> {
    const sortBy = orderBy?.split(':')[0] as keyof Order
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.getOrders.execute(
      new GetOrdersQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, offset, limit, searchTerm, sortBy, sortDirection, status, filter)
    )
  }
}
