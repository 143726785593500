import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { SetPrintifyShopQuery } from '../../data/queries/set-printify-shop.query'

export class SetPrintifyShopInteractor {
  constructor(
    private readonly setShop: PutInteractor<void>
  ) {}

  public async execute(shopId: number, printifyShopQuery: number): Promise<void> {
    await this.setShop.execute(undefined, new SetPrintifyShopQuery(shopId, printifyShopQuery))
  }
}
