import { type Component, onMount, Show } from 'solid-js'
import LoadingScreen from './loading-screen'
import { useNavigate } from '@solidjs/router'
import { AppRoutes } from '../shared/app-routes'
import { useUser } from './auth/user-provider'

export interface UnsubscribedGuardProps {
  readonly component: Component
}

const UnsubscribedGuard: Component<UnsubscribedGuardProps> = (props) => {
  const navigate = useNavigate()
  const { hasAccess } = useUser()

  onMount(async () => {
    if (hasAccess()) {
      navigate(AppRoutes.Dashboard(), { replace: true })
    }
  })

  return (
    <Show when={!hasAccess()} fallback={<LoadingScreen />}>
      <props.component/>
    </Show>
  )
}

export default UnsubscribedGuard
