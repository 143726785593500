import { Cogs, CogsType } from '../models/cogs'
import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type CogsEntity, CogsTypeEntity } from '../../data/entities/cogs.entity'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'

export class CogsEntityToCogsMapper implements Mapper<CogsEntity, Cogs> {
  public map(from: CogsEntity): Cogs {
    return new Cogs(
      mapTimestampToDate(from.from_date)!,
      from.amount_per_unit,
      from.currency
    )
  }
}

export class CogsTypeEntityToCogsTypeMapper implements Mapper<CogsTypeEntity, CogsType> {
  public map(from: CogsTypeEntity): CogsType {
    return from === CogsTypeEntity.Printful
      ? CogsType.Printful
      : from === CogsTypeEntity.Printify
        ? CogsType.Printify
        : CogsType.None
  }
}
