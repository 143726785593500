import type { Component } from 'solid-js'
import ProgressRatio from './ProgressRatio'
import { type Breakdown } from '../../../features/product/domain/models/breakdown'
import { For } from 'solid-js'
import { getValueColor } from '../../../shared/helpers/get-value-color'
import { t } from 'i18next'
import { type Position } from '../../../shared/helpers/dom.functions'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface BreakdownBoxProps {
  readonly breakdown: Breakdown
  readonly position?: Position
}

const BreakdownBox: Component<BreakdownBoxProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()

  const valuePerUnit = props.breakdown.unitsKPI.value && props.breakdown.unitsKPI.value !== 0
    ? (props.breakdown.total.value ?? 0) / props.breakdown.unitsKPI.value
    : undefined

  const prevPerUnit = props.breakdown.unitsKPI.prevValue && props.breakdown.total.prevValue && props.breakdown.unitsKPI.prevValue !== 0
    ? props.breakdown.total.prevValue / props.breakdown.unitsKPI.prevValue
    : undefined

  return (
    <div style={`
                  ${props.position ? 'left: ' + props.position.left + 'px' : 'left: 0'};
                  ${props.position ? ' top:' + props.position.top + 'px' : 'top: 24px'};
               `}
         class="flex border-[1px] border-gray-200 gap-2 min-w-[268px] text-sm text-gray-900 absolute bg-white shadow-lg rounded-lg z-30"
    >
      <div class="flex p-4">
        <div class="flex flex-col gap-3 min-w-[154px] whitespace-nowrap">
          <div class="mr-2 flex flex-col gap-3 w-full">
            <span class="font-bold">{ t('ls_breakdown_breakdown') }</span>
            <For each={props.breakdown.KPIS}>{(kpi) => (
              <span class="h-6 flex items-center whitespace-nowrap">
                {t(`ls_breakdown_${kpi.id}`)}
              </span>
            )}</For>
          </div>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class="font-bold mr-2 h-6 flex items-center">
            {t(`ls_breakdown_${props.breakdown.total.id}`)}
          </span>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class="text-xs mr-2 h-6 flex items-center">{ t('ls_breakdown_per_unit') }</span>
        </div>
        <div class="flex flex-col gap-3 items-end">
          <div class="mr-2 flex flex-col gap-3 items-end">
            <div class="h-5"></div>
            <For each={props.breakdown.KPIS}>{(kpi) => (
              <span class="h-6 flex items-center whitespace-nowrap">{currencyFormatter(kpi.value)}</span>
            )}</For>
          </div>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class={`${getValueColor(props.breakdown.total.value)} font-semibold mr-2 h-6 flex items-center whitespace-nowrap`}>
            {currencyFormatter(props.breakdown.total.value)}
          </span>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class="text-xs mr-2 h-6 flex items-center whitespace-nowrap">{currencyFormatter(valuePerUnit)}</span>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-col gap-3 mr-2">
            <div class="h-5"></div>
            <For each={props.breakdown.KPIS}>{(kpi) => (
              <ProgressRatio value={kpi.progressRatio} kpi={kpi.id}/>
            )}</For>
          </div>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <div class="mr-2"><ProgressRatio value={props.breakdown.total.progressRatio} kpi={props.breakdown.total.id}/></div>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <div class="w-full mr-2"></div>
        </div>
      </div>
      <div class="p-4 bg-gray-100 text-gray-500 rounded-r-lg">
        <div class="flex flex-col gap-3">
          <span class="whitespace-nowrap font-semibold">{ t('ls_shared_prev_period') }</span>
          <For each={props.breakdown.KPIS}>{(kpi) => (
            <span class="h-6 flex items-center whitespace-nowrap">{currencyFormatter(kpi.prevValue)}</span>
          )}</For>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class="h-6 flex items-center whitespace-nowrap">{currencyFormatter(props.breakdown.total.prevValue)}</span>
          <div class="h-[1px] w-full bg-gray-200"></div>
          <span class="text-xs h-6 flex items-center whitespace-nowrap">{currencyFormatter(prevPerUnit, 2)}</span>
        </div>
      </div>
    </div>
  )
}

export default BreakdownBox
