import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { type ProductCostsWithUnits } from '../models/product-costs-with-units'
import { GetCogsIssuesQuery } from '../../data/queries/get-cogs-issues.query'

export class GetCogsIssuesInteractor {
  constructor(
    private readonly getCogsIssues: GetInteractor<PaginationOffsetLimit<ProductCostsWithUnits>>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    searchTerm: string | undefined
  ): Promise<PaginationOffsetLimit<ProductCostsWithUnits>> {
    return await this.getCogsIssues.execute(new GetCogsIssuesQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, offset, limit, searchTerm))
  }
}
