import { type KPIType } from '../../domain/models/kpi'

interface DataPoint {
  readonly date: number
  readonly date_label: string
  readonly data_points: Record<KPIType, number>
}

export class TimeseriesEntity {
  constructor(
    public readonly date: number,
    public readonly points: DataPoint[]
  ) {
  }
}
