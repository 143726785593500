import { createContext, createSignal, type JSX, useContext } from 'solid-js'
import { PeriodType } from '../../features/shared/period-type'
import { getPeriodsByType } from '../helpers/date.functions'
import { type PeriodsState } from '../../features/shared/period-state'

export interface PeriodContextType {
  periods: () => PeriodsState
  setPeriods: (periods: PeriodsState) => void
  isProPeriod: () => boolean
  setIsProPeriod: (isProPeriod: boolean) => void
}

const [periods, setPeriods] = createSignal<PeriodsState>({
  type: PeriodType.Last7Days,
  ...getPeriodsByType(PeriodType.Last7Days)
})

const updatePeriods = (periods: PeriodsState): void => {
  setIsProPeriod(!StarterPeriods.includes(periods.type))
  setPeriods(periods)
}

const [isProPeriod, setIsProPeriod] = createSignal<boolean>(false)

const store: PeriodContextType = {
  periods,
  setPeriods: updatePeriods,
  isProPeriod,
  setIsProPeriod
}

const PeriodContext = createContext<PeriodContextType>(store)

export const PeriodProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <PeriodContext.Provider value={store}>
      {props.children}
    </PeriodContext.Provider>
  )
}

export const usePeriods = (): PeriodContextType => {
  return useContext(PeriodContext)
}

export const StarterPeriods: PeriodType[] = [
  PeriodType.Today,
  PeriodType.Yesterday,
  PeriodType.Last7Days,
  PeriodType.Last30Days,
  PeriodType.MonthToDate
]
