import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type Purchases } from '../models/purchases'
import { GetPurchasesQuery } from '../../data/queries/get-purchases.query'

export class GetPurchasesInteractor {
  constructor(
    private readonly getPurchases: GetInteractor<Purchases>
  ) {
  }

  public async execute(): Promise<Purchases> {
    return await this.getPurchases.execute(new GetPurchasesQuery())
  }
}
