import { type User } from '../models/user'
import { UpdateCurrentUserQuery } from '../../data/queries/update-current-user.query'
import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'

export class UpdateCurrentUserInteractor {
  constructor(
    private readonly updateCurrentUser: PutInteractor<User>
  ) {
  }

  public async execute(name: string): Promise<User> {
    return await this.updateCurrentUser.execute(undefined, new UpdateCurrentUserQuery(name))
  }
}
