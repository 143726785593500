import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'

export class GetListingReportQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly listingId: number,
    private readonly startTime: number,
    private readonly endTime: number,
    private readonly prevStartTime: number
  ) {
    super(ApiEndpoint.Listing)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      listingId: this.listingId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.startTime,
      to_date: this.endTime,
      prev_from_date: this.prevStartTime
    }
  }
}
