import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type IntegrationShopEntity } from '../../data/entities/integration-shop.entity'
import { IntegrationShop } from '../models/integration-shop'

export class IntegrationShopEntityToIntegrationShopMapper implements Mapper<IntegrationShopEntity, IntegrationShop> {
  public map (from: IntegrationShopEntity): IntegrationShop {
    return new IntegrationShop(
      from.id,
      from.title
    )
  }
}
