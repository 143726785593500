import type { Component } from 'solid-js'
import { ProductFilter } from '../../features/product/domain/models/product-cost'
import Squares2x2 from '../../assets/heroicons/Squares2x2'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'
import MiniReceiptStackSolid from '../../assets/heroicons/MiniReceiptPercentSolid'
import MiniQrCode from '../../assets/heroicons/MiniQrCode'
import TableFilter from '../shared/components/TableFilter'
import {
  type ValueByFilter
} from '../../features/product/domain/interactors/get-product-costs-filters.interactor'
import styles from './Catalog.module.scss'

export interface CatalogFiltersProps {
  readonly productsByFilter?: ValueByFilter<ProductFilter>
  readonly selectedFilter: () => ProductFilter
  readonly setSelectedFilter: (value: ProductFilter | undefined) => void
}

const CatalogFilters: Component<CatalogFiltersProps> = (props) => {
  return (
    <div>
      <ul role="list" class={`${styles.filtersContainer} grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4`}>
        <li class="col-span-1">
          <TableFilter filterId={ProductFilter.All} value={ props.productsByFilter?.all } icon={Squares2x2} iconColor="text-japanese-500" selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>
        </li>
        <li class="col-span-1">
          <TableFilter filterId={ProductFilter.MultipleListing} value={ props.productsByFilter?.multiple_listings } icon={RectangleStackSolid} iconColor="text-purple-500" selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>
        </li>
        <li class="col-span-1">
          <TableFilter filterId={ProductFilter.WithoutCOGS} value={ props.productsByFilter?.cogs_issues } icon={MiniReceiptStackSolid} iconColor="text-red-600" backgroundColor="red" valueColor="red" selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter} type="cogs-alert"/>
        </li>
        <li class="col-span-1">
          <TableFilter filterId={ProductFilter.WithoutSKU} value={ props.productsByFilter?.without_sku } icon={MiniQrCode} iconColor="text-orange-500" backgroundColor="amber" type="alert" selectedFilter={props.selectedFilter} setSelectedFilter={props.setSelectedFilter}/>
        </li>
      </ul>
    </div>
  )
}

export default CatalogFilters
