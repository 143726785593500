import { PutNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class CancelSubscriptionQuery extends PutNetworkQuery {
  constructor (
    private readonly subscriptionId: string
  ) {
    super(ApiEndpoint.CancelSubscription)
  }

  public get urlParameters(): HttpParameters {
    return {
      subscriptionId: this.subscriptionId
    }
  }
}
