export class UserEntity {
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly email_verified: boolean,
    public readonly name: string,
    public readonly picture_url: string,
    public readonly customer_id: string,
    public readonly intercom_hash: string
  ) {
  }
}
