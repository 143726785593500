import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { SubscriptionChange } from '../models/subscription-change'
import { type SubscriptionChangeEntity } from '../../data/entities/subscription-change.entity'

export class SubscriptionChangeEntityToSubscriptionChangeMapper implements Mapper<SubscriptionChangeEntity, SubscriptionChange> {
  public map(from: SubscriptionChangeEntity): SubscriptionChange {
    return new SubscriptionChange(
      from.amount_due_today,
      from.credit_to_balance,
      from.currency,
      from.credit_used,
      from.prorated_cost
    )
  }
}
