import { type Component } from 'solid-js'
import { useError } from '../shared/providers/error.provider'
import { Trans } from '@mbarzda/solid-i18next'
import Issue from '../assets/images/issue.png'
import { t } from 'i18next'

const LoadingError: Component = () => {
  const { setError } = useError()

  return (
    <div class="flex flex-col h-full items-center text-center justify-center gap-5 max-w-[360px] mx-auto">
      <img alt="error" class="h-24" src={Issue} />
      <span class="text-gray-900 text-base font-bold">
        <Trans key="ls_generic_error_title" />
      </span>
      <span class="text-gray-500 text-xs ">
        <Trans key="ls_generic_error_line1" />
      </span>
      <span class="text-gray-500 text-xs">
        <Trans key="ls_generic_error_line2" />
      </span>
      <span onClick={() => { window.Intercom('show') }} class="text-gray-500 text-xs" innerHTML={t('ls_generic_error_line3')}></span>
      <div class="flex justify-center gap-4 p-4">
        <button type="button"
                onClick={() => { setError(false) }}
                class="min-w-[156px] rounded-md !bg-gray-800 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:!bg-gray-700 transition duration-200">
          <Trans key="ls_generic_retry" />
        </button>
      </div>
    </div>
  )
}

export default LoadingError
