import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import {
  type OfferingEntity,
  type OfferingItemEntity,
  type OfferingProductEntity
} from '../../data/entities/offering.entity'
import {
  MainPlansItem,
  MainPlans,
  OfferingProductInterval,
  OfferingType
} from '../models/offering'
import { OfferingProductEntityToOfferingProductMapper } from './offering.mappers'

export class OfferingEntityToMainPlansMapper implements Mapper<OfferingEntity, MainPlans> {
  private getProductByInterval(item: OfferingItemEntity, interval: OfferingProductInterval): OfferingProductEntity | undefined {
    return item.prices.find(price => price.billing_interval?.interval === interval)
  }

  public map(from: OfferingEntity): MainPlans {
    const productMapper = new OfferingProductEntityToOfferingProductMapper()

    const starterOffer = from.offerings.find(offer => offer.offering === OfferingType.Starter)!
    const proOffer = from.offerings.find(offer => offer.offering === OfferingType.Pro)!

    const starterMonthly = this.getProductByInterval(starterOffer, OfferingProductInterval.Month)!
    const starterAnnual = this.getProductByInterval(starterOffer, OfferingProductInterval.Year)!
    const proMonthly = this.getProductByInterval(proOffer, OfferingProductInterval.Month)!
    const proAnnual = this.getProductByInterval(proOffer, OfferingProductInterval.Year)!

    return new MainPlans(
      from.id,
      from.name,
      from.type,
      new MainPlansItem(
        productMapper.map(starterMonthly),
        productMapper.map(starterAnnual)
      ),
      new MainPlansItem(
        productMapper.map(proMonthly),
        productMapper.map(proAnnual)
      )
    )
  }
}
