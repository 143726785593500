import { type KPI } from '../../../analytics/domain/models/kpi'

export enum ListingStatus {
  Active = 'active',
  Inactive = 'edit',
  SoldOut = 'sold_out',
  Expired = 'expired',
}

export interface ImgSizeUrls {
  readonly sm: string
  readonly md: string
  readonly lg: string
}

export class Listing {
  constructor (
    public readonly id: number,
    public readonly url: string,
    public readonly imgUrls: ImgSizeUrls,
    public readonly title: string,
    public readonly status: ListingStatus
  ) {
  }

  public isDeleted (): boolean {
    return !this.status
  }
}

export class ListingReport extends Listing {
  constructor (
    id: number,
    url: string,
    imgUrls: ImgSizeUrls,
    title: string,
    status: ListingStatus,
    public readonly missingCogs: boolean,
    public readonly orders: KPI,
    public readonly unitsSold: KPI,
    public readonly netSales: KPI,
    public readonly cogs: KPI,
    public readonly opExpenses: KPI,
    public readonly opProfit: KPI,
    public readonly profitMargin: KPI,
    public readonly roi: KPI,
    public readonly breakEvenRoas: KPI
  ) {
    super(id, url, imgUrls, title, status)
  }
}
