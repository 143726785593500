import type { Component } from 'solid-js'

export interface TextCellProps {
  readonly text: string
}

const TextCell: Component<TextCellProps> = (props) => {
  return (
    <div class="mb-1 line-clamp-2 text-gray-900">{props.text}</div>
  )
}

export default TextCell
