import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class GetTaskStatusQuery extends GetNetworkQuery {
  constructor (private readonly taskId: string) {
    super('')
  }

  public get urlParameters(): HttpParameters {
    return {
      taskId: this.taskId
    }
  }
}
