import { createEffect, createSignal, onCleanup } from 'solid-js'

export interface DateFormatterResult {
  readonly shortDateFormatter: (date: Date) => string
}

export const useDateFormatter = (): DateFormatterResult => {
  const [locale, setLocale] = createSignal<string>()

  const handleLanguageChange = (): void => {
    setLocale(window.navigator.language ?? 'en-US')
  }

  window.addEventListener('languagechange', handleLanguageChange)
  onCleanup(() => {
    window.removeEventListener('languagechange', handleLanguageChange)
  })

  createEffect(() => {
    setLocale(window.navigator.language)
  })

  const shortDateFormatter = (date: Date, useUTC = false): string => {
    const formatter = new Intl.DateTimeFormat(locale(), {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      ...(useUTC && { timeZone: 'UTC' })
    })
    return formatter.format(date)
  }

  return {
    shortDateFormatter
  }
}
