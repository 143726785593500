import {
  createContext,
  useContext,
  createSignal,
  type Accessor,
  type Component,
  createEffect,
  type JSX
} from 'solid-js'
import { useLocation } from '@solidjs/router'

interface ErrorContextType {
  error: Accessor<boolean>
  setError: (error: boolean) => void
}

const [error, setError] = createSignal<boolean>(false)

const ErrorContext = createContext<ErrorContextType>({
  error,
  setError
})

export const ErrorProvider: Component<{ children: JSX.Element }> = (props) => {
  const location = useLocation()

  createEffect(() => {
    void location.pathname
    setError(false)
  })

  const value: ErrorContextType = {
    error,
    setError
  }

  return (
    <ErrorContext.Provider value={value}>
      {props.children}
    </ErrorContext.Provider>
  )
}

export const useError = (): ErrorContextType => {
  return useContext(ErrorContext)
}
