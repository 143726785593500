import { type Component } from 'solid-js'
import Dialog from '../shared/components/Dialog/Dialog'
import LottieAnimation from '../shared/components/LottieAnimation'
import logoAnimation from '../../assets/animations/logo-animated.json'
import { Trans } from '@mbarzda/solid-i18next'

const ProcessingPlanChangeDialog: Component = () => {
  return (
    <Dialog maxWidth={'606px'}>
      <div class="flex flex-col gap-6 p-6 text-center justify-center items-center">
        <span class="text-gray-900 font-bold"><Trans key="ls_plan_change_processing"/></span>
        <LottieAnimation animationData={logoAnimation} width="32px"/>
        <span class="text-xs text-gray-500"><Trans key="ls_plan_change_wait"/></span>
      </div>
    </Dialog>
  )
}

export default ProcessingPlanChangeDialog
