import { type Accessor, createSignal, Show, type Component } from 'solid-js'
import TableSortingPreset, { type TableSortingPresetConfig } from './TableSortingPreset'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'
import { Trans } from '@mbarzda/solid-i18next'
import ChevronUpDown from '../../../assets/heroicons/ChevronUpDown'
import { type ProductSorting } from '../../../features/shared/product-sorting'

export interface TableSortingPresetPairConfig {
  readonly pairId?: ProductSorting
  readonly desc: TableSortingPresetConfig
  readonly asc: TableSortingPresetConfig
}

export interface TableSortingPresetProps {
  readonly orderBy: Accessor<string | undefined>
  readonly setOrderBy: (id: string | undefined) => void
  readonly presets: TableSortingPresetPairConfig
  readonly options: Record<string, string>
}

const TableSortingPresetPair: Component<TableSortingPresetProps> = (props) => {
  const isAscSelected = (): boolean => props.options[props.presets.asc.presetId] === props.orderBy()
  const isDescSelected = (): boolean => props.options[props.presets.desc.presetId] === props.orderBy()
  const isSelected = (): boolean => isAscSelected() || isDescSelected()

  const [isHovered, setIsHovered] = createSignal<boolean>(false)

  const onPresetClick = (): void => {
    if (isAscSelected()) {
      props.setOrderBy(props.options[props.presets.desc.presetId])
      return
    }

    if (isDescSelected()) {
      props.setOrderBy(props.options[props.presets.asc.presetId])
      return
    }

    props.setOrderBy(props.options[props.presets.desc.presetId])
  }

  return (
    <div class={`relative ${isSelected() ? 'bg-gray-900 text-white' : 'bg-white text-black'} h-10 transition duration-300 shadow rounded-lg py-2 px-4 flex justify-between gap-2 items-center cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onPresetClick}
    >
      <Show when={isAscSelected()} fallback={
        <TableSortingPreset config={props.presets.desc} isSelected={isAscSelected()}/>
      }>
        <TableSortingPreset config={props.presets.asc} isSelected={isDescSelected()}/>
      </Show>
      <div class="flex text-gray-500">
        <ChevronUpDown direction={isAscSelected() ? 'up' : isDescSelected() ? 'down' : undefined}/>
      </div>
      <Show when={props.presets.pairId && isHovered()}>
        <div class="flex gap-2 border-[1px] border-gray-200 w-[236px] text-sm absolute top-12 bg-white p-4 left-0 shadow-lg rounded-lg break-words z-50">
          <span class="text-gray-400"><MiniInformationCircle /></span><Trans key={`ls_table_filter_${props.presets.pairId}_hint`} />
        </div>
      </Show>
    </div>
  )
}

export default TableSortingPresetPair
