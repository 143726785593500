import { DiffType } from '../../features/analytics/domain/models/kpi'

export const getValueColor = (value?: number): string => {
  if (value === undefined) {
    return 'text-blue-500'
  }

  return value > 0
    ? 'text-green-500'
    : value === 0
      ? 'text-blue-500'
      : 'text-red-500'
}

export const getValueColorInversed = (value?: number): string => {
  if (value === undefined) {
    return 'text-blue-500'
  }

  return value > 0
    ? 'text-red-500'
    : value === 0
      ? 'text-blue-500'
      : 'text-green-500'
}

export const getValueColorByKPI = (value?: number, type?: DiffType): string => {
  switch (type) {
    case DiffType.Neutral:
      return 'text-gray-400'
    case DiffType.Inverse:
      return getValueColorInversed(value)
    default:
      return getValueColor(value)
  }
}
