import { type NumericalDataType } from '../../../../shared/models/numerical-data-type'
import { type Breakdown } from '../../../product/domain/models/breakdown'

export enum DiffType {
  Regular,
  Inverse,
  Neutral
}

export enum KPIType {
  OperatingExpenses = 'operating_expenses',
  OperatingProfit = 'operating_profit',
  Profit = 'profit_margin',
  Roi = 'roi',
  UnitsSold = 'units_sold',
  Orders = 'orders',
  AvgOrderValue = 'avg_order_value',
  AvgShippingCost = 'avg_shipping_cost',
  ShippingBalance = 'shipping_balance',
  ShippingCollected = 'shipping_collected',
  ShippingCosts = 'shipping_costs',
  Roas = 'roas',
  BreakEvenRoas = 'break_even_roas',
  NetSales = 'net_sales',
  Cogs = 'cogs',
  GrossSales = 'gross_sales',
  Discounts = 'discounts',
  Refunds = 'refunds',
  TransactionFees = 'transaction_fees',
  ListingFees = 'listing_fees',
  ProcessingFees = 'processing_fees',
  AdCosts = 'ad_costs',
  Views = 'views',
  ViewsToOrderConversionRate = 'views_to_order_conversion_rate'
}

export const KPIDiffType: Record<KPIType, DiffType> = {
  [KPIType.OperatingExpenses]: DiffType.Neutral,
  [KPIType.OperatingProfit]: DiffType.Regular,
  [KPIType.Profit]: DiffType.Regular,
  [KPIType.Roi]: DiffType.Regular,
  [KPIType.UnitsSold]: DiffType.Regular,
  [KPIType.Orders]: DiffType.Regular,
  [KPIType.AvgOrderValue]: DiffType.Regular,
  [KPIType.AvgShippingCost]: DiffType.Inverse,
  [KPIType.ShippingBalance]: DiffType.Regular,
  [KPIType.ShippingCollected]: DiffType.Neutral,
  [KPIType.ShippingCosts]: DiffType.Neutral,
  [KPIType.Roas]: DiffType.Regular,
  [KPIType.BreakEvenRoas]: DiffType.Inverse,
  [KPIType.NetSales]: DiffType.Regular,
  [KPIType.Cogs]: DiffType.Neutral,
  [KPIType.GrossSales]: DiffType.Regular,
  [KPIType.Discounts]: DiffType.Neutral,
  [KPIType.Refunds]: DiffType.Inverse,
  [KPIType.TransactionFees]: DiffType.Neutral,
  [KPIType.ListingFees]: DiffType.Neutral,
  [KPIType.ProcessingFees]: DiffType.Neutral,
  [KPIType.AdCosts]: DiffType.Neutral,
  [KPIType.Views]: DiffType.Neutral,
  [KPIType.ViewsToOrderConversionRate]: DiffType.Neutral
}

export const KPISign: Record<KPIType, 'positive' | 'negative'> = {
  [KPIType.OperatingExpenses]: 'negative',
  [KPIType.OperatingProfit]: 'positive',
  [KPIType.Profit]: 'positive',
  [KPIType.Roi]: 'positive',
  [KPIType.UnitsSold]: 'positive',
  [KPIType.Orders]: 'positive',
  [KPIType.AvgOrderValue]: 'positive',
  [KPIType.AvgShippingCost]: 'positive',
  [KPIType.ShippingBalance]: 'positive',
  [KPIType.ShippingCollected]: 'positive',
  [KPIType.ShippingCosts]: 'negative',
  [KPIType.Roas]: 'positive',
  [KPIType.BreakEvenRoas]: 'positive',
  [KPIType.NetSales]: 'positive',
  [KPIType.Cogs]: 'negative',
  [KPIType.GrossSales]: 'positive',
  [KPIType.Discounts]: 'positive',
  [KPIType.Refunds]: 'positive',
  [KPIType.TransactionFees]: 'positive',
  [KPIType.ListingFees]: 'positive',
  [KPIType.ProcessingFees]: 'positive',
  [KPIType.AdCosts]: 'negative',
  [KPIType.Views]: 'positive',
  [KPIType.ViewsToOrderConversionRate]: 'positive'
}

export class KPI {
  constructor (
    public readonly id: KPIType,
    public readonly value: number | undefined,
    public readonly type: NumericalDataType,
    public readonly progressRatio: number,
    public readonly prevValue?: number,
    public readonly breakdown?: Breakdown
  ) {
  }
}

export interface KPIVM {
  readonly id: KPIType
  readonly value?: number
  readonly type?: NumericalDataType
  readonly progressRatio?: number
  readonly prevValue?: number
  readonly breakdown?: Breakdown
}
