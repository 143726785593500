import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import question from '../../../assets/images/girl_question_mark.png'
import Dialog from './Dialog/Dialog'
import DialogActions from './Dialog/DialogActions'
import { t } from 'i18next'
import TranslateWithAction from './TranslateWithAction'
import { useError } from '../../../shared/providers/error.provider'
import { MainModule } from '../../../features/main-module'

export interface ShopReconnectedDialogProps {
  readonly cancel: () => void
}

const ShopReconnectedDialog: Component<ShopReconnectedDialogProps> = (props) => {
  const { setError } = useError()

  const getEtsyAuthUrl = MainModule.getShopComponent().provideGetEtsyAuthUrl()
  const onActionAddNewShop = async (): Promise<void> => {
    try {
      window.location.href = await getEtsyAuthUrl.execute()
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }

  return (
    <Dialog maxWidth={'415px'}>
      <div class="flex flex-col gap-2 text-sm text-gray-500 p-6">
        <img src={question} class="h-24 mx-auto" alt="error" />
        <span class="text-base text-gray-900 font-bold"><Trans key="ls_reconnected_title"/></span>
        <span><Trans key="ls_reconnected_detected"/></span>
        <span><Trans key="ls_reconnected_steps"/></span>
        <ul>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full" />
            <span innerHTML={t('ls_reconnected_step1')}/>
          </li>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full"/>
            <span innerHTML={t('ls_reconnected_step2')}/>
          </li>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full"/>
            <span innerHTML={t('ls_reconnected_step3')}/>
          </li>
        </ul>
        <span innerHTML={t('ls_reconnected_return')} />
        <TranslateWithAction key='ls_reconnected_still_problems' action={ () => { window.Intercom('show') } } />
      </div>
      <DialogActions>
        <button
          onClick={props.cancel}
          class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-white text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 duration-200">
          <Trans key="ls_generic_close"/>
        </button>
        <button
          onClick={onActionAddNewShop}
          class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-gray-800 text-sm font-semibold text-white shadow-sm hover:bg-gray-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800 duration-200">
          <Trans key="ls_generic_try_again"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default ShopReconnectedDialog
