import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type OneTimePurchaseEntity } from '../../data/entities/one-time-purchase.entity'
import { OneTimePurchase } from '../models/one-time-purchase'
import { type OfferingProductEntity } from '../../data/entities/offering.entity'
import { type OfferingProduct } from '../models/offering'
import { SubscriptionStatus } from '../models/subscription'

export class OneTimePurchaseEntityToOneTimePurchaseMapper implements Mapper<OneTimePurchaseEntity, OneTimePurchase> {
  constructor(
    private readonly offeringProductMapper: Mapper<OfferingProductEntity, OfferingProduct>
  ) {}

  public map(from: OneTimePurchaseEntity): OneTimePurchase {
    return new OneTimePurchase(
      from.id,
      new Date(from.billed_at),
      this.offeringProductMapper.map(from.product_price),
      SubscriptionStatus.Active
    )
  }
}
