import { type Component } from 'solid-js'
import { AppRoutes } from '../../shared/app-routes'
import ShoppingBag from '../../assets/heroicons/ShoppingBag'
import ShoppingCart from '../../assets/heroicons/ShoppingCart'
import RectangleStack from '../../assets/heroicons/RectangleStack'
import ChartPie from '../../assets/heroicons/ChartPie'
import './Sidebar.module.scss'
import SidebarItem from './SibebarItem'
import ShopSelector from './ShopSelector'
import { Trans } from '@mbarzda/solid-i18next'
import Squares2x2 from '../../assets/heroicons/Squares2x2'
import Cube from '../../assets/heroicons/Cube'
import ExclamationTriangle from '../../assets/heroicons/ExclamationTriangle'
import AcademicCap from '../../assets/heroicons/AcademicCap'

export interface SidebarContentProps {
  readonly onItemSelected: () => void
}

const SidebarContent: Component<SidebarContentProps> = (props) => {
  return (
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center">
        <img class="h-7 w-auto" src="/images/profit-tree-logo.svg" alt="Profit Tree"/>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <div class="text-xs font-semibold leading-6 text-white"><Trans key={'ls_sidebar_my_shops'}/></div>
            <ul role="list" class="space-y-1">
              <li>
                <ShopSelector onItemSelected={props.onItemSelected}/>
              </li>
            </ul>
          </li>
          <li class="flex flex-col gap-2">
            <div class="text-xs font-semibold leading-6 text-white"><Trans key={'ls_sidebar_reports'}/></div>
            <ul role="list" class="space-y-1 flex flex-col gap-2">
              <li>
                <SidebarItem url={AppRoutes.Dashboard()} icon={<ChartPie/>} textKey={'dashboard'}
                             onItemSelected={props.onItemSelected}/>
              </li>
              <li>
                <SidebarItem url={AppRoutes.Products()} icon={<ShoppingBag/>} textKey={'products'}
                             onItemSelected={props.onItemSelected}/>
              </li>
              <li>
                <SidebarItem url={AppRoutes.Listings()} icon={<RectangleStack/>} textKey={'listing'}
                             onItemSelected={props.onItemSelected}/>
              </li>
              <li>
                <SidebarItem url={AppRoutes.Orders()} icon={<ShoppingCart/>} textKey={'orders'}
                             onItemSelected={props.onItemSelected}/>
              </li>
              <li>
                <SidebarItem url={AppRoutes.CogsIssues()} icon={<ExclamationTriangle/>}
                             textKey={'cogs_issues'} onItemSelected={props.onItemSelected}/>
              </li>
            </ul>
          </li>
          <li class="flex flex-col gap-2">
            <div class="text-xs font-semibold leading-6 text-white"><Trans key={'ls_sidebar_shop'}/></div>
            <ul role="list" class="space-y-1 flex flex-col gap-2">
              <li>
                <SidebarItem url={AppRoutes.Catalog()} icon={<Cube/>} textKey={'catalog'}
                             onItemSelected={props.onItemSelected}/>
              </li>
              <li>
                <SidebarItem url={AppRoutes.Integrations()} icon={<Squares2x2/>}
                             textKey={'integrations'} onItemSelected={props.onItemSelected}/>
              </li>
            </ul>
          </li>
          <li class="flex flex-col gap-2">
            <div class="text-xs font-semibold leading-6 text-white"><Trans key={'ls_sidebar_others'}/></div>
            <ul role="list" class="space-y-1 flex flex-col gap-2">
              <li>
                <SidebarItem url={AppRoutes.Education()} icon={<AcademicCap />} textKey={'education'}
                             onItemSelected={props.onItemSelected}/>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default SidebarContent
