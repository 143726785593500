import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class PreviewSubscriptionChangeQuery extends GetNetworkQuery {
  constructor (
    private readonly subscriptionId: string,
    private readonly priceId: string
  ) {
    super(ApiEndpoint.PreviewSubscriptionChange)
  }

  public get urlParameters(): HttpParameters {
    return {
      subscriptionId: this.subscriptionId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      price_id: this.priceId
    }
  }
}
