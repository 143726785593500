import type { Component } from 'solid-js'
import { t } from 'i18next'
import { type Position } from '../../../shared/helpers/dom.functions'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'

export interface TableColumnTooltipProps {
  readonly columnId: string
  readonly position?: Position
}

const TableColumnTooltip: Component<TableColumnTooltipProps> = (props) => {
  return (
    <div style={`
            ${props.position ? 'left: ' + props.position.left + 'px' : 'left: 0'};
            ${props.position ? ' top:' + props.position.top + 'px' : 'top: 24px'};
         `}
         class="flex flex-col gap-2 border-[1px] border-gray-200 w-[236px] text-gray-900 text-sm absolute bg-white p-4 shadow-lg rounded-lg break-words z-50">

      <div class="flex gap-2 font-bold">
        <span class="text-gray-400"><MiniInformationCircle/></span>
        {t(`ls_table_col_${props.columnId}`)}
      </div>
      <span class="font-normal">{t(`ls_table_col_hint_${props.columnId}_hint`)}</span>
    </div>
  )
}

export default TableColumnTooltip
