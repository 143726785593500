import { type SubscriptionChange } from '../models/subscription-change'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { PreviewSubscriptionChangeQuery } from '../../data/queries/preview-subscription-change.query'

export class PreviewSubscriptionChangeInteractor {
  constructor(
    private readonly previewChange: GetInteractor<SubscriptionChange>
  ) {
  }

  public async execute(subscriptionId: string, priceId: string): Promise<SubscriptionChange> {
    return await this.previewChange.execute(new PreviewSubscriptionChangeQuery(subscriptionId, priceId))
  }
}
