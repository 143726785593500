import { type Component, createSignal, onMount, Show } from 'solid-js'
import UnsubscribedSectionCard from './UnsubscribedSectionCard'
import SubscribedSectionCard from './SubscribedSectionCard'
import ProfileSectionCard from './ProfileSectionCard'
import UpgradePlanCard from './UpgradePlanCard'
import ProcessingSubscriptionUpdateDialog from '../payment/ProcessingSubscriptionUpdateDialog'
import { AppRoutes } from '../../shared/app-routes'
import { useNavigate, useSearchParams } from '@solidjs/router'
import { useUser } from '../../public/auth/user-provider'
import LifetimeSectionCard from './LifetimeSectionCard'
import { isSubscription } from '../../features/payment/domain/models/subscription'
import { MainModule } from '../../features/main-module'

const AccountSettings: Component = () => {
  const { userPurchases, currentSubscription, hasAccess, setUserPurchases } = useUser()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isUpdatingSubscription, setIsUpdatingSubscription] = createSignal<string>()

  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()

  onMount(async(): Promise<void> => {
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)
  })

  const onSubscriptionUpdated = (): void => {
    setIsUpdatingSubscription(undefined)
    if (location.pathname === AppRoutes.UnsubscribedAccountSettings()) {
      navigate(AppRoutes.AccountSettings(), { replace: true })
      return
    }

    cleanQueryPaddleParams()
  }

  const cleanQueryPaddleParams = (): void => {
    const params = searchParams
    delete params._ptxn

    setSearchParams(params)
  }

  return (
    <div class="flex flex-wrap justify-center gap-8 w-full">
      <Show when={isUpdatingSubscription()}>{(subId) => (
        <ProcessingSubscriptionUpdateDialog onSubscriptionReady={onSubscriptionUpdated} newSubscriptionId={subId()}/>
      )}</Show>
      <Show when={hasAccess()} fallback={<UnsubscribedSectionCard />}>
        <ProfileSectionCard />
        <div class="flex flex-col gap-4 flex-grow items-center max-w-[600px] h-fit">
          <Show when={isSubscription(currentSubscription())} fallback={
            <LifetimeSectionCard />
          }>
            <SubscribedSectionCard onUpdateSubscription={setIsUpdatingSubscription} />
          </Show>
          <Show when={!userPurchases()?.isPro}>
            <UpgradePlanCard />
          </Show>
        </div>
      </Show>
    </div>
  )
}

export default AccountSettings
