import { type ApexOptions } from 'apexcharts'
import { type LinearTimeseriesVm } from '../shared/timeseries/linear-timeseries-vm'
import { t } from 'i18next'
import { type NumericalFormatterFunc } from '../../shared/helpers/currency-formatter'

export const getPeriodsComparisonChartVM = (data: LinearTimeseriesVm, isPrevPeriodVisible: boolean, formatNumericValue: NumericalFormatterFunc): ApexOptions => {
  const allValues = [...data.currentPeriod]
  const series = [{
    name: t('ls_dashboard_chart_cur_period'),
    type: 'line',
    data: data.currentPeriod
  }]

  series.push({
    name: 'incomplete',
    type: 'area',
    data: data.incompleteData
  })
  allValues.push(...data.previousPeriod)

  if (isPrevPeriodVisible) {
    series.push({
      name: t('ls_dashboard_chart_prev_period'),
      type: 'line',
      data: data.previousPeriod
    })
    allValues.push(...data.previousPeriod)
  }

  const actualNumbers: number[] = allValues.filter((value): value is number => !!value)

  const max = Math.max(...actualNumbers)
  const min = Math.min(...actualNumbers)

  return {
    series,
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [data.kpiColor, data.kpiColor, '#9ca3af'],
    stroke: {
      width: [4, 4, 2],
      curve: 'smooth',
      dashArray: [0, 6, 8]
    },
    fill: {
      type: ['solid', 'gradient', 'solid'],
      opacity: 1,
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        gradientToColors: [data.kpiColor, '#ffffff'],
        opacityFrom: 0.45,
        opacityTo: 0.45,
        type: 'vertical',
        stops: [0, 80]
      }
    },
    grid: {
      show: true,
      borderColor: '#CCCCCC',
      strokeDashArray: 2,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      area: {
        fillTo: 'end'
      }
    },
    xaxis: {
      tooltip: {
        enabled: false
      },
      labels: {
        rotate: -35,
        style: {
          colors: '#6B7280',
          fontSize: '11px'
        },
        formatter: data.labelFormatter
      }
    },
    yaxis: {
      min: min >= 0 ? 0 : min * 1.2,
      max: max >= 0 ? max * 1.2 : 0,
      reversed: data.yAxisDirection === 'negative',
      tickAmount: 5,
      labels: {
        style: {
          colors: '#6B7280'
        },
        formatter: (y: number): string => {
          if (typeof y !== 'undefined') {
            return formatNumericValue(data.type, y)!
          }
          return y
        }
      }
    },
    labels: data.dates,
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'right',
      fontSize: '11px',
      fontWeight: 300,
      markers: {
        width: 32,
        height: 6,
        offsetY: -1
      },
      itemMargin: {
        horizontal: 12
      }
    },
    markers: {
      size: 0,
      showNullDataPoints: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (x: number): string => {
          return data.dates[x - 1]
        }
      },
      y: {
        formatter: (y: number, { seriesIndex, dataPointIndex }): string => {
          const incompleteData = data.incompleteData[dataPointIndex]
          const regularFormat = y ? formatNumericValue(data.type, y)! : '-'
          return seriesIndex === 0 && incompleteData ? formatNumericValue(data.type, incompleteData)! : regularFormat
        }
      }
    }
  }
}
