import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetOfferingQuery } from '../../data/queries/get-offering.query'
import { type MainPlans } from '../models/offering'

export class GetMainPlansInteractor {
  constructor(
    private readonly getMainPlans: GetInteractor<MainPlans>
  ) {
  }

  public async execute(offer: string): Promise<MainPlans> {
    return await this.getMainPlans.execute(new GetOfferingQuery(offer))
  }
}
