import { type ProductCost, type ProductFilter } from '../models/product-cost'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { GetProductCostsQuery } from '../../data/queries/get-product-costs.query'

export class GetCatalogInteractor {
  constructor(
    private readonly getProductCosts: GetInteractor<PaginationOffsetLimit<ProductCost>>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    searchTerm: string | undefined,
    filter: ProductFilter
  ): Promise<PaginationOffsetLimit<ProductCost>> {
    return await this.getProductCosts.execute(new GetProductCostsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, offset, limit, searchTerm, filter))
  }
}
