import { type Component, type JSX } from 'solid-js'

export interface DialogActionsProps {
  readonly children: JSX.Element
}

const DialogActions: Component<DialogActionsProps> = (props) => {
  return (
    <div class="py-4 px-6 flex justify-end bg-gray-100 gap-6 rounded-b-md">
      { props.children }
    </div>
  )
}

export default DialogActions
