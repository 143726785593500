import type { Component } from 'solid-js'
import { formatDateToTimeAMPM } from '../../../shared/helpers/date.functions'
import { useDateFormatter } from '../../../shared/helpers/date-formatter'

export interface DateTimeCellProps {
  readonly date: Date
}

const DateTimeCell: Component<DateTimeCellProps> = (props) => {
  const { shortDateFormatter } = useDateFormatter()
  return (
    <div class="flex flex-col gap-1 font-normal">
      <span class="text-sm text-gray-900">{ shortDateFormatter(props.date) }</span>
      <span class="text-xs text-gray-500">{ formatDateToTimeAMPM(props.date) }</span>
    </div>
  )
}

export default DateTimeCell
