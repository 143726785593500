import { onCleanup } from 'solid-js'

type SignalSetter<T> = (value: T) => void

export const useDebounce = <T>(signalSetter: SignalSetter<T>, delay: number): SignalSetter<T> => {
  let timerHandle: NodeJS.Timeout

  const debouncedSignalSetter: SignalSetter<T> = (value: T): void => {
    clearTimeout(timerHandle)
    timerHandle = setTimeout(() => { signalSetter(value) }, delay)
  }

  onCleanup(() => { clearInterval(timerHandle) })

  return debouncedSignalSetter
}
