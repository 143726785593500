import { type NumericalDataType } from '../../../shared/models/numerical-data-type'

export type TimeSeriesData = Array<number | null>

export class LinearTimeseriesVm {
  constructor (
    public readonly dates: string[],
    public readonly kpiColor: string,
    public readonly currentPeriod: TimeSeriesData,
    public readonly previousPeriod: TimeSeriesData,
    public readonly type: NumericalDataType,
    public readonly yAxisDirection: 'positive' | 'negative',
    public readonly labelFormatter: (value: string) => string,
    public readonly incompleteData: TimeSeriesData
  ) {
  }
}
