import { type KpiEntity } from './kpi.entity'
import { type KPIType } from '../../domain/models/kpi'

export class AnalyticsEntity {
  constructor(
    public readonly currency_code: string,
    public readonly data_points: Record<KPIType, KpiEntity>,
    public readonly missing_cogs: number
  ) {
  }
}
