import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetCogsUpdateStatusQuery extends GetNetworkQuery {
  constructor (protected readonly shopId: number) {
    super(ApiEndpoint.ProductCogsBulkUpdate)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }
}
