import { type SubscriptionStatus } from '../../domain/models/subscription'
import { type OfferingProductEntity } from '../../../payment/data/entities/offering.entity'

export class SubscriptionEntity {
  constructor(
    public readonly id: string,
    public readonly status: SubscriptionStatus,
    public readonly product_price: OfferingProductEntity,
    public readonly next_payment_timestamp?: number,
    public readonly end_billing_period_timestamp?: number,
    public readonly trial_ends_timestamp?: number,
    public readonly cancel_url?: string,
    public readonly manage_url?: string
  ) {
  }
}
