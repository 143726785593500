export enum CogsType {
  None = 'none',
  Printful = 'printful',
  Printify = 'printify'
}

export class Cogs {
  constructor(
    public readonly fromDate: Date,
    public readonly amount: number,
    public readonly currency: string
  ) {
  }
}
