import { type Product } from './product'
import { type CogsType } from './cogs'
import { type CogsStatus, ProductCost } from './product-cost'

export class ProductCostsWithUnits extends ProductCost {
  constructor (
    sku: string | undefined,
    cogsStatus: CogsStatus,
    hasMultipleCogs: boolean,
    productCount: number,
    minPrice: number,
    maxPrice: number,
    cogsType: CogsType,
    product: Product,
    public readonly unitsSold: number
  ) {
    super(sku, cogsStatus, hasMultipleCogs, productCount, minPrice, maxPrice, cogsType, product)
  }
}
