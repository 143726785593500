import { type OfferingProduct } from './offering'
import type { OneTimePurchase } from './one-time-purchase'

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Inactive = 'inactive'
}

export class Subscription {
  constructor(
    public readonly id: string,
    public readonly status: SubscriptionStatus,
    public readonly productPrice: OfferingProduct,
    public readonly nextPaymentDate?: Date,
    public readonly endBillingPeriodDate?: Date,
    public readonly trialEndsDate?: Date,
    public readonly manageUrl?: string
  ) {
  }

  public get isSubscriptionActive(): boolean {
    return this.status !== SubscriptionStatus.PastDue
  }

  // todo: remove when we don't receive canceled subscriptions from the backend
  public get isValid(): boolean {
    return this.status === SubscriptionStatus.Active ||
      this.status === SubscriptionStatus.Trialing ||
      this.status === SubscriptionStatus.Canceled
  }
}

export const isSubscription = (sub?: OneTimePurchase | Subscription): Subscription | undefined => {
  return sub instanceof Subscription ? sub : undefined
}
