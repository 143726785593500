import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCurrentUserQuery } from '../../data/queries/get-current-user.query'
import { type User } from '../models/user'

export class GetCurrentUserInteractor {
  constructor(
    private readonly getCurrentUser: GetInteractor<User>
  ) {
  }

  public async execute(): Promise<User> {
    return await this.getCurrentUser.execute(new GetCurrentUserQuery())
  }
}
