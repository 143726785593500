import { createSignal, Show } from 'solid-js'
import type { Component, Accessor, JSX } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type ProductFilter } from '../../../features/product/domain/models/product-cost'
import MiniExclamationCircle from '../../../assets/heroicons/MiniExclamationCircle'
import MiniCheckCircle from '../../../assets/heroicons/MiniCheckCircle'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { type ProductSorting } from '../../../features/shared/product-sorting'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'
import { type OrderSorting } from '../../orders/order-sorting'

export type AcceptedFilters = ProductFilter | ProductSorting | OrderSorting

export interface TableFilterProps<T extends AcceptedFilters> {
  readonly filterId: T
  readonly selectedFilter: Accessor<T | undefined>
  readonly setSelectedFilter: (value: T | undefined) => void
  readonly icon: Component
  readonly iconColor: string
  readonly value?: number
  readonly backgroundColor?: string
  readonly valueColor?: string
  readonly type?: 'cogs-alert' | 'alert'
  readonly withHint?: true
  readonly isToggle?: true
}

const TableFilter = <T extends AcceptedFilters,>(props: TableFilterProps<T>): JSX.Element => {
  const isFilterSelected = (): boolean => props.selectedFilter() === props.filterId
  const [isHovered, setIsHovered] = createSignal<boolean>(false)

  const onFilterClicked = (): void => {
    if (props.isToggle) {
      props.setSelectedFilter(isFilterSelected() ? undefined : props.filterId)
    } else {
      props.setSelectedFilter(props.filterId)
    }
  }

  return (
    <div class="relative">
      <div
          onClick={onFilterClicked}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          class={`${isFilterSelected()
              ? 'bg-gray-900 text-white'
              : `${props.backgroundColor && props.value ? `bg-${props.backgroundColor}-100` : 'bg-white'} text-black transition duration-300`}` +
              ' shadow rounded-lg py-2 px-4 flex justify-between gap-2 items-center cursor-pointer'}
      >
        <p class="text-current text-xs flex gap-2">
          <span class={`${props.iconColor}`}>{ props.icon({ size: 4 }) }</span> <Trans key={`ls_table_filter_${props.filterId}`} />
        </p>
        <div class="flex gap-2 items-center">
          <span class={`${isFilterSelected() ? 'text-white' : props.valueColor && props.value ? `text-${props.valueColor}-500` : 'text-black'}`}>{props.value}</span>
          <Show when={props.type}>
            <Show when={props.value === 0}>
              <span class="text-green-500"><MiniCheckCircle size={4}/></span>
            </Show>
            <Show when={props.value}>
              <span class="text-red-500">
                <Show when={props.type === 'cogs-alert'}>
                  <MiniExclamationTriangle/>
                </Show>
                <Show when={props.type === 'alert'}>
                  <MiniExclamationCircle/>
                </Show>
              </span>
            </Show>
          </Show>
        </div>
      </div>
      <Show when={props.withHint && isHovered()}>
        <div class="flex gap-2 border-[1px] border-gray-200 w-[236px] text-sm absolute top-12 bg-white p-4 left-0 shadow-lg rounded-lg break-words z-50">
          <span class="text-gray-400"><MiniInformationCircle /></span><Trans key={`ls_table_filter_${props.filterId}_hint`} />
        </div>
      </Show>
    </div>
  )
}

export default TableFilter
