import { type Accessor, type Component, createEffect, createSignal, onCleanup, Show } from 'solid-js'
import ApexCharts from 'apexcharts'
import { type KPI, KPIType } from '../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import { getFinancialBreakdownChartVM } from './financial-breakdown.chart-vm'
import BreakdownChartLegendItem, { type BreakdownChartLegendItemProps } from './BreakdownChartLegendItem'
import styles from './FinancialBreakdownChart.module.scss'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export const KpiColor = {
  [KPIType.Cogs]: 'blue-500',
  [KPIType.TransactionFees]: 'red-600',
  [KPIType.ListingFees]: 'red-500',
  [KPIType.ProcessingFees]: 'red-400',
  [KPIType.ShippingCosts]: 'orange-500',
  [KPIType.AdCosts]: 'purple-600',
  [KPIType.OperatingProfit]: 'green-600'
}

export type LegendVM = Partial<Record<KPIType, BreakdownChartLegendItemProps>>

export interface FinancialBreakdownChartProps {
  kpis: KPI[]
}

const FinancialBreakdownChart: Component<FinancialBreakdownChartProps> = (props) => {
  const [legendVM, setLegendVM] = createSignal<LegendVM>()
  const { currencyFormatter } = useNumberFormatter()

  let chart: ApexCharts | undefined

  const renderChart = async (kpis: KPI[], total: KPI): Promise<void> => {
    const chartOptions = getFinancialBreakdownChartVM(kpis, total, currencyFormatter(total.value))
    if (chart) {
      await chart.updateOptions(chartOptions)
    } else {
      chart = new ApexCharts(document.querySelector('#financialBreakdownChart'), chartOptions)
      await chart.render()
    }
  }

  createEffect(async () => {
    const kpis = props.kpis
    const totalValues = kpis.find(kpi => kpi.id === KPIType.NetSales)?.value ?? 0

    if (kpis.length) {
      setLegendVM(kpis.reduce<Record<string, BreakdownChartLegendItemProps>>((acc, kpi) => {
        acc[kpi.id] = {
          kpi,
          ratio: (totalValues === 0 || !kpi.value) ? 0 : Math.abs(kpi.value) / totalValues,
          color: KpiColor[kpi.id as keyof typeof KpiColor]
        }
        return acc
      }, {}))

      const shownKpis = kpis.filter(kpi => kpi.id !== KPIType.NetSales)
      const total = kpis.find(kpi => kpi.id === KPIType.NetSales)!
      void renderChart(shownKpis, total)
    }
  })

  onCleanup(() => {
    if (chart) {
      chart.destroy()
    }
  })

  return (
    <>
      <div class="flex justify-between sm:pt-4 sm:px-4">
        <h2 class="mr-3 text-sm font-medium text-gray-900"><Trans key={'ls_fin_break_title'}/></h2>
      </div>
      <div class="max-w-[64rem] mx-auto flex my-6 gap-0 sm:gap-6 md:gap-2 lg:gap-0 xl:gap-6 flex-col md:flex-row">
        <div id="financialBreakdownChart" class={`${styles.finBreakChartContainer} md:w-1/2`}></div>
        <div class="flex items-center justify-center md:justify-start">
          <div class="flex flex-wrap gap-4 sm:gap-2 md:gap-2 lg:gap-0 xl:gap-8 justify-center">
            <Show when={legendVM()}>{(legend: Accessor<LegendVM>) => (
              <>
                <div class="flex flex-col gap-4 min-w-[160px]">
                  <BreakdownChartLegendItem {...legend().cogs!} />
                  <BreakdownChartLegendItem {...legend().transaction_fees!} />
                  <BreakdownChartLegendItem {...legend().listing_fees!} />
                  <BreakdownChartLegendItem {...legend().processing_fees!} />
                </div>
                <div class="flex flex-col gap-4 min-w-[160px]">
                  <BreakdownChartLegendItem {...legend().shipping_costs!} />
                  <BreakdownChartLegendItem {...legend().ad_costs!} />
                  <BreakdownChartLegendItem {...legend().operating_profit!} />
                </div>
              </>
            )}</Show>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinancialBreakdownChart
