export type EnumBoolRecord<T extends Record<string, string>> = Record<T[keyof T], boolean>

export const fromEnumToBoolObject = <T extends Record<string, string>>(enumObject: T): EnumBoolRecord<T> => {
  if (!enumObject) {
    throw new Error('Invalid input')
  }

  return Object.values(enumObject).reduce((acc, key) => {
    return {
      ...acc,
      [key]: false
    }
  }, {}) as Record<keyof T, false>
}
