import type { Component } from 'solid-js'
import StatusCell from '../shared/components/StatusCell'
import { t } from 'i18next'
import ViewOnEtsyCell from '../shared/components/ViewOnEtsyCell'
import { type Order, OrderStatus } from '../../features/product/domain/models/order'
import ValueCell from '../shared/components/ValueCell'
import DateTimeCell from '../shared/components/DateTimeCell'
import { NumericalDataType } from '../../shared/models/numerical-data-type'

export const OrderStatusColor: Record<OrderStatus, string> = {
  [OrderStatus.Canceled]: 'red',
  [OrderStatus.Completed]: 'blue',
  [OrderStatus.FullyRefunded]: 'black',
  [OrderStatus.Open]: 'orange',
  [OrderStatus.Paid]: 'green',
  [OrderStatus.PartiallyRefunded]: 'orange',
  [OrderStatus.PaymentProcessing]: 'yellow'
}

export interface OrdersTableRowProps {
  readonly order: Order
  readonly isLoading: () => boolean
}

const OrdersTableRow: Component<OrdersTableRowProps> = (props) => {
  const order = props.order

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 text-sm font-medium sm:pl-3 min-w-[120px]">
        <DateTimeCell date={order.createdAt}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ViewOnEtsyCell text={`#${order.receiptId}`} url={order.url}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <StatusCell color={OrderStatusColor[order.status]} status={t(`ls_order_status_${order.status}`)}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.itemCount} type={NumericalDataType.Units}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.paid} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.salesTax} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.totalPrice} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.shipping} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[160px]">
        <ValueCell value={order.refunds} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.discounts} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={order.totalCogs} type={NumericalDataType.Currency} warning={props.order.missingCogs}/>
      </td>
      <td class="tableCell min-w-[132px] font-semibold">
        <ValueCell value={order.grossProfit} type={NumericalDataType.Currency} colored/>
      </td>
    </tr>
  )
}

export default OrdersTableRow
