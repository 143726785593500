export enum OrderStatus {
  Paid = 'paid',
  Open = 'open',
  Completed = 'completed',
  PaymentProcessing = 'payment_processing',
  Canceled = 'canceled',
  PartiallyRefunded = 'partially_refunded',
  FullyRefunded = 'fully_refunded'
}

export class Order {
  constructor(
    public readonly createdAt: Date,
    public readonly discounts: number,
    public readonly receiptId: number,
    public readonly status: OrderStatus,
    public readonly url: string,
    public readonly currency: string,
    public readonly itemCount: number,
    public readonly paid: number,
    public readonly refunds: number,
    public readonly totalPrice: number,
    public readonly shipping: number,
    public readonly salesTax: number,
    public readonly totalCogs: number,
    public readonly missingCogs: boolean,
    public readonly grossProfit: number
  ) {
  }
}
