import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import issue from '../../../assets/images/issue.png'
import Dialog from './Dialog/Dialog'
import DialogActions from './Dialog/DialogActions'
import { t } from 'i18next'
import { type ShopType } from '../../../features/shop/domain/models/shop'
import { capitalize } from '../../../shared/helpers/string.functions'

export interface ShopReconnectionMismatchDialogProps {
  readonly retry: () => void
  readonly cancel: () => void
  readonly shopType: ShopType
  readonly shopName: string
}

const ShopReconnectionMismatchDialog: Component<ShopReconnectionMismatchDialogProps> = (props) => {
  const shopType = capitalize(props.shopType)

  const getShopUrl = (shopType: ShopType): string => {
    return `https://${shopType}.com`
  }

  return (
    <Dialog maxWidth={'434px'}>
      <div class="flex flex-col gap-2 text-sm text-gray-500 p-6">
        <img src={issue} class="h-24 mx-auto mb-2" alt="error" />
        <span class="text-base text-gray-900 font-bold"><Trans key="ls_reconnection_mismatch_title" options={{ platform: shopType }}/></span>
        <span><Trans key="ls_reconnection_mismatch_different_account" options={{ platform: shopType }}/></span>
        <span><Trans key="ls_reconnection_mismatch_steps" options={{ name: props.shopName }}/></span>
        <ul>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full" />
            <span innerHTML={t('ls_reconnection_mismatch_step1', { platform: shopType, url: getShopUrl(props.shopType) })} />
          </li>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full"/>
            <span innerHTML={t('ls_reconnection_mismatch_step2')}/>
          </li>
          <li class="flex items-center gap-2">
            <span class="ml-1.5 h-1 w-1 bg-gray-500 rounded-full"/>
            <span innerHTML={t('ls_reconnection_mismatch_step3')}/>
          </li>
        </ul>
        <span innerHTML={t('ls_reconnection_mismatch_return')} />
        <span><Trans key="ls_reconnection_mismatch_retry" options={{ name: props.shopName }} /></span>
      </div>
      <DialogActions>
        <button
          onClick={props.cancel}
          class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-white text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 duration-200">
          <Trans key="ls_generic_close"/>
        </button>
        <button
          onClick={props.retry}
          class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-gray-800 text-sm font-semibold text-white shadow-sm hover:bg-gray-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800 duration-200">
          <Trans key="ls_generic_retry"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default ShopReconnectionMismatchDialog
