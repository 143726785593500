import { type Accessor, type Component, For } from 'solid-js'
import TableSortingPresetPair, { type TableSortingPresetPairConfig } from '../shared/components/TableSortingPresetPair'
import MiniClock from '../../assets/heroicons/MiniClock'
import MiniArrowTrendingUp from '../../assets/heroicons/MiniArrowTrendingUp'
import MiniArrowTrendingDown from '../../assets/heroicons/MiniArrowTrendingDown'
import TableFilter from '../shared/components/TableFilter'
import MiniReceiptStackSolid from '../../assets/heroicons/MiniReceiptPercentSolid'
import { ProductFilter } from '../../features/product/domain/models/product-cost'
import { OrderFilterSorting, OrderSorting } from './order-sorting'
import MiniShoppingCart from '../../assets/heroicons/MiniShoppingCart'

export interface OrderFiltersProps {
  readonly orderBy: Accessor<string | undefined>
  readonly setOrderBy: (sorting: string | undefined) => void
  readonly isLoading: Accessor<boolean>
  readonly missingCogsCounter?: number
  readonly selectedFilter: () => ProductFilter | undefined
  readonly setSelectedFilter: (filter?: ProductFilter) => void
}

const OrderFilters: Component<OrderFiltersProps> = (props) => {
  const OrderPresets: TableSortingPresetPairConfig[] = [
    {
      desc: {
        presetId: OrderSorting.MostRecent,
        icon: MiniClock,
        iconColor: 'text-japanese-500'
      },
      asc: {
        presetId: OrderSorting.Oldest,
        icon: MiniClock,
        iconColor: 'text-japanese-500'
      }
    },
    {
      desc: {
        presetId: OrderSorting.BiggestChart,
        icon: MiniShoppingCart,
        iconColor: 'text-green-500'
      },
      asc: {
        presetId: OrderSorting.SmallestChart,
        icon: MiniShoppingCart,
        iconColor: 'text-red-500'
      }
    },
    {
      desc: {
        presetId: OrderSorting.MostProfitable,
        icon: MiniArrowTrendingUp,
        iconColor: 'text-green-500'
      },
      asc: {
        presetId: OrderSorting.LessProfitable,
        icon: MiniArrowTrendingDown,
        iconColor: 'text-red-500'
      }
    }
  ]

  return (
    <ul role="list" class="grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
      <For each={OrderPresets}>{(presets) => (
        <li class="col-span-1">
          <TableSortingPresetPair options={OrderFilterSorting} presets={presets} orderBy={props.orderBy} setOrderBy={props.setOrderBy}/>
        </li>
      )}</For>
      <li class="col-span-1">
        <TableFilter filterId={ProductFilter.WithoutCOGS} value={props.missingCogsCounter}
                     icon={MiniReceiptStackSolid} iconColor="text-red-600" backgroundColor="red"
                     valueColor="red" selectedFilter={props.selectedFilter}
                     setSelectedFilter={props.setSelectedFilter} type="cogs-alert" isToggle/>
      </li>
    </ul>
  )
}

export default OrderFilters
