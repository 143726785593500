import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { Trans } from '@mbarzda/solid-i18next'
import { type Cogs } from '../../../features/product/domain/models/cogs'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface CogsValueCellProps {
  readonly cogs?: Cogs
  readonly warning?: boolean
}

const CogsValueCell: Component<CogsValueCellProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()

  const getValue = (cogs?: Cogs): string => {
    if (!cogs) return 'ls_generic_n_a'
    return currencyFormatter(cogs.amount)!
  }

  return (
    <div class="flex gap-2 items-center">
      <Show when={props.warning}>
        <span class="text-orange-500"><MiniExclamationTriangle /></span>
      </Show>
      <Trans key={getValue(props.cogs)} />
    </div>
  )
}

export default CogsValueCell
