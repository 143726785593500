import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type ProductCost } from '../../../features/product/domain/models/product-cost'
import MiniExclamationCircle from '../../../assets/heroicons/MiniExclamationCircle'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'

export interface CatalogSkuCellProps {
  readonly product: ProductCost
}

const CatalogSkuCell: Component<CatalogSkuCellProps> = (props) => {
  const numListings = props.product.productCount

  return (
    <>
      <div class="mb-1">{props.product.product.sku}</div>
      <Show when={!props.product.product.sku}>
        <div class="mb-1 flex items-center gap-1">
          <span class="text-red-500"><MiniExclamationCircle /></span><Trans key="ls_shared_wo_sku" />
        </div>
      </Show>
      <div class="text-xs text-gray-500">
        <Show when={numListings === 1}>
          <a href={props.product.product.listing.url} target="_blank" class="flex items-center gap-1">
            <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy" />
          </a>
        </Show>
        <Show when={numListings > 1}>
          <span>{numListings} <Trans key="ls_catalog_listings" /></span>
        </Show>
      </div>
    </>
  )
}

export default CatalogSkuCell
