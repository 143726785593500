import { type Component, createSignal, For } from 'solid-js'
import { KPIType } from '../../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import { getKPIVMsInGroup } from '../../shared/functions/kpi.functions'
import { KPIGroupId } from '../../../shared/models/kpi-group'
import StatsGroup from '../../shared/components/StatsGroup'
import { AnalyticsConfig } from '../../shared/analytics.vm'
import AnalyticsChart from '../../shared/components/AnalyticsChart'
import SkuSummaryBox from '../../cogs/SkuSummaryBox'
import PerUnitMainStats from '../../shared/components/PerUnitMainStats'
import { MOCKED_SKU_PRODUCTS_REPORTS } from '../MockedSkuProductReports'
import { MockedTimeSeriesInteractor } from '../../shared/functions/mocked-timeseries.interactor'
import LockedListingsDialog from '../../shared/components/LockedListingsDialog'
import SearchBar from '../../shared/components/SearchBar'
import ProductFilters from '../../shared/components/ProductFilters'
import { ProductFilterSorting, ProductSorting } from '../../../features/shared/product-sorting'
import Table, { type TableColumn, TableStatus } from '../../shared/components/Table'
import TablePagination from '../../shared/components/TablePagination'
import RectangleStackSolid from '../../../assets/heroicons/RectangleStackSolid'
import ProductListingTableRow from './ProductListingsTableRow'
import { MOCKED_LISTINGS } from '../../listings/MockedListings'

const ProductLocked: Component = () => {
  const [selectedKpiType, setSelectedKpiType] = createSignal(KPIType.OperatingProfit)
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])

  const getTimeseries = new MockedTimeSeriesInteractor()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'listing' },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true }
  ]

  const product = MOCKED_SKU_PRODUCTS_REPORTS[0]
  const listings = MOCKED_LISTINGS
  const kpis = [
    product.unitsSold,
    product.roi,
    product.opExpenses,
    product.opProfit,
    product.profitRatio,
    product.cogsKPI,
    product.netSales
  ]

  return (
    <div class="relative">
      <LockedListingsDialog />
      <h1 class="mb-4 page-title"><Trans key="ls_product_title"/></h1>
      <div class="flex flex-col gap-9">
        <SkuSummaryBox product={product.product} numListings={1}/>
        <div class="grid gap-9 content-center">
          <PerUnitMainStats kpis={getKPIVMsInGroup(kpis, KPIGroupId.Main)}
                            selectedKPI={selectedKpiType}
                            setSelectedKPI={setSelectedKpiType} units={product.unitsSold.value}/>
          <AnalyticsChart kpi={product.netSales} getTimeseriesAction={getTimeseries}
                          entityId={product.product.id}/>
          <div class="sm:hidden">
            <StatsGroup config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
          <div class="hidden sm:block">
            <StatsGroup style={'row'} config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <h2 class="page-title"><RectangleStackSolid size={6}/><Trans
              key="ls_product_listings"/></h2>
            <span class="text-sm"><Trans key="ls_product_appears"/></span>
          </div>
          <SearchBar setSearchTerm={() => {}} placeholder="ls_search_title_or_variation"/>
          <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={() => false}/>
          <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
            <div
              class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
                <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns}
                       status={() => TableStatus.Loaded} tableId="product_listings">
                  <For each={listings}>{(listing) => (
                    <ProductListingTableRow listing={listing} product={product.product}
                                            isLoading={() => false}/>
                  )}</For>
                </Table>
              </div>
              <TablePagination totalCount={listings.length} page={() => 1} setPage={() => {}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductLocked
