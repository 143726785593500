import { type Component, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'

export interface OneOrClampCellProps {
  readonly value: string
  readonly numValues: number
}

const OneOrClampCell: Component<OneOrClampCellProps> = (props) => {
  return (
    <>
      <Show when={props.numValues === 1}>
        <div class="line-clamp-2 text-gray-900">
          {props.value}
        </div>
      </Show>
      <Show when={props.numValues > 1}>
        <div class="flex flex-col">
          <span class="text-gray-500"><Trans key="ls_generic_multiple" /></span>
          <span class="line-clamp-1 text-gray-900">{props.value}</span>
        </div>
      </Show>
    </>
  )
}

export default OneOrClampCell
