import { type Component } from 'solid-js'
import styles from './MiniLoading.module.scss'

const MiniLoading: Component = () => {
  return (
    <div class="flex items-center gap-[3px]">
      <span class={styles.dot}></span>
      <span class={styles.dot}></span>
      <span class={styles.dot}></span>
    </div>
  )
}

export default MiniLoading
