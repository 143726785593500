import { type GetRepository } from '../repository'
import { type Query } from '../query/query'
import { DefaultOperation, type Operation } from '../operation/operation'

export class GetInteractor<T> {
  constructor (private readonly repository: GetRepository<T>) {
  }

  public async execute (query: Query, operation: Operation = new DefaultOperation()): Promise<T> {
    return await this.repository.get(query, operation)
  }
}
