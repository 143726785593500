import { useShop } from '../providers/shop.provider'
import { createEffect, createSignal, onCleanup } from 'solid-js'
import { NumericalDataType } from '../models/numerical-data-type'

export type DecimalFormatterFunc = (value?: number, numFractionDigits?: number) => string | undefined
export type NumericalFormatterFunc = (type: NumericalDataType, value?: number, numFractionDigits?: number) => string | undefined

export interface currencyFormatterResult {
  readonly currencyFormatter: DecimalFormatterFunc
  readonly numberFormatter: (value: number, numFractionDigits?: number) => string | undefined
  readonly percentageFormatter: DecimalFormatterFunc
  readonly formatNumericValue: NumericalFormatterFunc
}

export const useNumberFormatter = (): currencyFormatterResult => {
  const [locale, setLocale] = createSignal<string>()
  const { selectedShop } = useShop()

  const currency = (): string => selectedShop()?.currencyCode ?? 'USD'

  const handleLanguageChange = (): void => {
    setLocale(window.navigator.language ?? 'en-US')
  }

  window.addEventListener('languagechange', handleLanguageChange)
  onCleanup(() => {
    window.removeEventListener('languagechange', handleLanguageChange)
  })

  createEffect(() => {
    setLocale(window.navigator.language ?? 'en-US')
  })

  const currencyFormatter = (value?: number, numFractionDigits: number = 2): string => {
    return new Intl
      .NumberFormat(locale(), { style: 'currency', currency: currency(), maximumFractionDigits: 2, minimumFractionDigits: numFractionDigits })
      .format(value!)
  }

  const numberFormatter = (value?: number, numFractionDigits: number = 0): string | undefined => {
    if (!value) {
      return undefined
    }

    return Intl.NumberFormat(locale(), { maximumFractionDigits: 2, minimumFractionDigits: numFractionDigits }).format(value)
  }

  const percentageFormatter = (value: number | undefined, numFractionDigits: number = 0): string | undefined => {
    if (!value) {
      return undefined
    }

    return `${numberFormatter(value * 100, numFractionDigits)}%`
  }

  const formatNumericValue = (type: NumericalDataType, value: number | undefined, numFractionDigits?: number): string | undefined => {
    switch (type) {
      case NumericalDataType.Currency:
        return currencyFormatter(value, numFractionDigits)
      case NumericalDataType.Percentage:
        return percentageFormatter(value, numFractionDigits)
      default:
        return numberFormatter(value, numFractionDigits)
    }
  }

  return {
    currencyFormatter,
    numberFormatter,
    percentageFormatter,
    formatNumericValue
  }
}

/* eslint-disable no-extend-native */
declare global {
  export interface Number {
    isPositive: () => boolean
    isNegative: () => boolean
  }
}

Number.prototype.isPositive = function (): boolean {
  return +this > 0
}

Number.prototype.isNegative = function (): boolean {
  return +this < 0
}
