import { type ProductCost, ProductFilter } from '../models/product-cost'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import type { PeriodsState } from '../../../shared/period-state'
import { GetProductCostsQuery } from '../../data/queries/get-product-costs.query'

export type ValueByFilter<T extends string> = Record<T, number>

export class GetProductCostsFiltersInteractor {
  constructor(
    private readonly getProductsByFilter: GetInteractor<PaginationOffsetLimit<ProductCost>>
  ) {
  }

  public async execute(
    shopId: number,
    periods: PeriodsState,
    searchTerm?: string
  ): Promise<ValueByFilter<ProductFilter>> {
    return await Promise.all([
      this.getProductsByFilter.execute(
        new GetProductCostsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, 0, 1, searchTerm, ProductFilter.All)
      ),
      this.getProductsByFilter.execute(
        new GetProductCostsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, 0, 1, searchTerm, ProductFilter.MultipleListing)
      ),
      this.getProductsByFilter.execute(
        new GetProductCostsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, 0, 1, searchTerm, ProductFilter.WithoutCOGS)
      ),
      this.getProductsByFilter.execute(
        new GetProductCostsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, 0, 1, searchTerm, ProductFilter.WithoutSKU)
      )
    ]).then(([all, multipleListing, withoutCOGS, withoutSKU]) => {
      return {
        [ProductFilter.All]: all.size,
        [ProductFilter.MultipleListing]: multipleListing.size,
        [ProductFilter.WithoutCOGS]: withoutCOGS.size,
        [ProductFilter.WithoutSKU]: withoutSKU.size
      }
    })
  }
}
