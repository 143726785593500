import type { Accessor, Component } from 'solid-js'
import { createEffect, createSignal, For, Match, Show, Switch } from 'solid-js'
import TablePaginationCell from './TablePaginationCell'
import { Trans } from '@mbarzda/solid-i18next'

export interface TablePaginationProps {
  readonly pageSize?: number
  readonly totalCount: number
  readonly page: Accessor<number>
  readonly setPage: (page: number) => void
}

const TablePagination: Component<TablePaginationProps> = (props) => {
  const [currentPage, setCurrentPage] = createSignal(1)
  const [pagesShown, setPagesShown] = createSignal<Array<number | undefined>>([])

  const pageSize = (): number => props.pageSize ?? 15
  const firstItemNum = (): number => (currentPage() - 1) * pageSize() + 1
  const lastItemNum = (): number => props.totalCount ? Math.min(currentPage() * pageSize(), props.totalCount) : 0
  const lastPage = (): number => props.totalCount ? Math.ceil(props.totalCount / pageSize()) : 0

  const onActionPrevPage = (): void => {
    onActionGoToPage(currentPage() > 1 ? currentPage() - 1 : currentPage())
  }

  const onActionNextPage = (): void => {
    onActionGoToPage(currentPage() < lastPage() ? currentPage() + 1 : currentPage())
  }

  const onActionGoToPage = (pageIdx: number): void => {
    setCurrentPage(pageIdx)
    props.setPage(currentPage())
  }

  createEffect(() => {
    if (lastPage() <= 6) {
      setPagesShown(
        Array.from({ length: lastPage() }, (_, index) => index + 1)
      )
    }

    if (lastPage() > 6) {
      let pages: Array<number | undefined>
      if (currentPage() < 3 || currentPage() > lastPage() - 2) {
        pages = [1, 2, 3, undefined, lastPage() - 2, lastPage() - 1, lastPage()]
      } else if (currentPage() === 3) {
        pages = [1, 2, 3, 4, undefined, lastPage() - 1, lastPage()]
      } else if (currentPage() === lastPage() - 2) {
        pages = [1, 2, undefined, lastPage() - 3, lastPage() - 2, lastPage() - 1, lastPage()]
      } else {
        pages = [1, undefined, currentPage() - 1, currentPage(), currentPage() + 1, undefined, lastPage()]
      }
      setPagesShown(pages)
    }
  })

  createEffect(() => {
    setCurrentPage(props.page())
  })

  return (
    <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div class="flex flex-1 justify-between sm:hidden">
        <a href="#" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
        <a href="#" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <Show when={props.totalCount}>
            <p class="text-sm text-gray-700">
              Showing <span class="font-medium">{firstItemNum()}</span> to <span class="font-medium">{lastItemNum()}</span> of <span class="font-medium">{props.totalCount}</span> results
            </p>
          </Show>
        </div>
        <Show when={lastPage() > 1}>
          <div>
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">

              <button disabled={currentPage() === 1} onClick={onActionPrevPage} class={`${currentPage() === 1 ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'} relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300`}>
                <span class="sr-only"><Trans key="ls_generic_prev" /></span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                </svg>
              </button>

              <Switch>
                <Match when={lastPage() >= 7}>
                  <TablePaginationCell pageNum={pagesShown()[0]} onClick={onActionGoToPage} isSelected={pagesShown()[0] === currentPage()}/>
                  <TablePaginationCell pageNum={pagesShown()[1]} onClick={onActionGoToPage} isSelected={pagesShown()[1] === currentPage()}/>
                  <TablePaginationCell pageNum={pagesShown()[2]} onClick={onActionGoToPage} isSelected={pagesShown()[2] === currentPage()} onlyInXL/>
                  <TablePaginationCell pageNum={pagesShown()[3]} onClick={onActionGoToPage} isSelected={pagesShown()[3] === currentPage()}/>
                  <TablePaginationCell pageNum={pagesShown()[4]} onClick={onActionGoToPage} isSelected={pagesShown()[4] === currentPage()} onlyInXL/>
                  <TablePaginationCell pageNum={pagesShown()[5]} onClick={onActionGoToPage} isSelected={pagesShown()[5] === currentPage()}/>
                  <TablePaginationCell pageNum={pagesShown()[6]} onClick={onActionGoToPage} isSelected={pagesShown()[6] === currentPage()}/>
                </Match>
                <Match when={lastPage() <= 6}>
                  <For each={pagesShown()}>{(page) => (
                    <TablePaginationCell pageNum={page} onClick={onActionGoToPage} isSelected={page === currentPage()}/>
                  )}</For>
                </Match>
              </Switch>

              <button disabled={currentPage() === lastPage()} onClick={onActionNextPage} class={`${currentPage() === lastPage() ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'} relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300`}>
                <span class="sr-only"><Trans key="ls_generic_next" /></span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
              </button>
            </nav>
          </div>
        </Show>
      </div>
    </div>
  )
}

export default TablePagination
