import type { Component } from 'solid-js'
import ImageCell from '../shared/components/ImageCell'
import ValueProgressCell from '../shared/components/ValueProgressCell'
import { type SKUProductReport } from '../../features/product/domain/models/product'
import ProductListingCell from './ProductListingCell'
import ProductSkuCell from './ProductSkuCell'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import NoListing from '../../assets/images/no-listing-sm.svg'

export interface ProductTableRowProps {
  readonly product: SKUProductReport
}

const ProductTableRow: Component<ProductTableRowProps> = (props) => {
  const product = props.product
  const listingImage = product.product.isListingDeleted() ? NoListing : product.product.listing.imgUrls.sm

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 sm:pl-6 min-w-[88px]">
        <A href={AppRoutes.Product(product.product.id)}>
          <ImageCell imgUrl={listingImage} isMultiple={false} integrationType={product.cogsType}/>
        </A>
      </td>
      <td class="tableCell min-w-[240px]">
        <ProductSkuCell product={product} />
      </td>
      <td class="tableCell min-w-[240px]">
        <ProductListingCell product={product} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.unitsSold} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.netSales} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.cogsKPI} warning={product.missingCogs} productId={product.product.id}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueProgressCell kpi={product.opExpenses} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.opProfit} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.profitRatio} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.roi} />
      </td>
    </tr>
  )
}

export default ProductTableRow
