import { type Mapper } from './mapper/mapper'
import { type Operation } from './operation/operation'
import { type Query } from './query/query'
import { type DeleteRepository, type GetRepository, type PutRepository, type Repository } from './repository'

/**
 * This repository uses mappers to map objects and redirects them to the contained repository, acting as a simple "translator".
 *
 * @param getRepository Repository with get operations
 * @param putRepository Repository with put operations
 * @param deleteRepository Repository with delete operations
 * @param toOutMapper Mapper to map data objects to domain objects
 * @param toInMapper Mapper to map domain objects to data objects
 */
export class RepositoryMapper<In, Out> implements Repository<Out> {
  private readonly getMapper: GetRepositoryMapper<In, Out>
  private readonly putMapper: PutRepositoryMapper<In, Out>

  constructor (
    getRepository: GetRepository<In>,
    putRepository: PutRepository<In>,
    private readonly deleteRepository: DeleteRepository,
    toOutMapper: Mapper<In, Out>,
    toInMapper: Mapper<Out, In>,
  ) {
    this.getMapper = new GetRepositoryMapper(getRepository, toOutMapper)
    this.putMapper = new PutRepositoryMapper(putRepository, toOutMapper, toInMapper)
  }

  public async get (query: Query, operation: Operation): Promise<Out> {
    return await this.getMapper.get(query, operation)
  }

  public async put (value: Out | undefined, query: Query, operation: Operation): Promise<Out> {
    return await this.putMapper.put(value, query, operation)
  }

  public async delete (query: Query, operation: Operation): Promise<void> {
    await this.deleteRepository.delete(query, operation)
  }
}

/**
 * This repository uses mappers to map objects and redirects them to the contained repository, acting as a simple "translator".
 *
 * @param getRepository Repository with get operations
 * @param toOutMapper Mapper to map data objects to domain objects
 */
export class GetRepositoryMapper<In, Out> implements GetRepository<Out> {
  constructor (private readonly getRepository: GetRepository<In>, private readonly toOutMapper: Mapper<In, Out>) {
  }

  public async get (query: Query, operation: Operation): Promise<Out> {
    const result: In = await this.getRepository.get(query, operation)
    return this.toOutMapper.map(result)
  }
}

/**
 * This repository uses mappers to map objects and redirects them to the contained repository, acting as a simple "translator".
 *
 * @param putRepository Repository with put operations
 * @param toOutMapper Mapper to map data objects to domain objects
 * @param toInMapper Mapper to map domain objects to data objects
 */
export class PutRepositoryMapper<In, Out> implements PutRepository<Out> {
  constructor (
    private readonly putRepository: PutRepository<In>,
    private readonly toOutMapper: Mapper<In, Out>,
    private readonly toInMapper: Mapper<Out, In>
  ) {
  }

  public async put (value: Out | undefined, query: Query, operation: Operation): Promise<Out> {
    const mapped: In | undefined = value ? this.toInMapper.map(value) : undefined
    const result: In = await this.putRepository.put(mapped, query, operation)
    return this.toOutMapper.map(result)
  }
}
