import { type Component, Match, Switch } from 'solid-js'
import RectangleStackSolid from '../../../assets/heroicons/RectangleStackSolid'
import Printful from '../../../assets/images/printful.svg'
import Printify from '../../../assets/images/printify.svg'
import { CogsType } from '../../../features/product/domain/models/cogs'

export interface ImageCellProps {
  readonly isMultiple?: boolean
  readonly integrationType?: CogsType
  readonly imgUrl: string
}

const ImageCell: Component<ImageCellProps> = (props) => {
  return (
    <div class="relative h-[52px] w-[52px]">
      <img class="rounded-lg" src={props.imgUrl} alt=""/>
      <Switch>
        <Match when={props.integrationType === CogsType.Printful}>
          <div class="absolute bottom-0.5 right-1 rounded-sm">
            <img src={Printful} alt="Printful"/>
          </div>
        </Match>
        <Match when={props.integrationType === CogsType.Printify}>
          <div class="absolute bottom-0.5 right-1 rounded-sm">
            <img src={Printify} alt="Printify"/>
          </div>
        </Match>
        <Match when={props.integrationType === CogsType.None && props.isMultiple}>
          <div class="absolute bottom-0.5 right-1 text-white bg-gray-900 p-0.5 rounded-sm">
            <RectangleStackSolid size={4}/>
          </div>
        </Match>
      </Switch>
    </div>
  )
}

export default ImageCell
