import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type CreditEntity } from '../../data/entities/credit.entity'
import { Credit } from '../models/credit'

export class CreditToCreditEntityMapper implements Mapper<CreditEntity, Credit> {
  public map(from: CreditEntity): Credit {
    return new Credit(
      from.available,
      from.currency
    )
  }
}
