import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type PeriodsState } from '../../../shared/period-state'
import { type SortDirection } from '../../../../shared/models/sort-direction'
import { type ProductReport } from '../../../product/domain/models/product'
import { GetListingProductReportsQuery } from '../../data/queries/get-listing-product-reports.query'

export class GetListingProductReportsInteractor {
  constructor(
    private readonly getProducts: GetInteractor<PaginationOffsetLimit<ProductReport>>
  ) {
  }

  public async execute (
    shopId: number,
    listingId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    orderBy?: string,
    searchTerm?: string
  ): Promise<PaginationOffsetLimit<ProductReport>> {
    const sortBy = orderBy?.split(':')[0] as keyof ProductReport
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.getProducts.execute(
      new GetListingProductReportsQuery(shopId, listingId, periods.current.from, periods.current.to, periods.prev.from, offset, limit, searchTerm, sortBy, sortDirection)
    )
  }
}
