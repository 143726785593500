import {
  type Accessor,
  type Component,
  createEffect,
  createSignal,
  For,
  type Setter,
  Show
} from 'solid-js'
import Stat, { type StatCustomStyles } from './Stat'
import styles from './MainStats.module.scss'
import { type KPIType, type KPIVM } from '../../../features/analytics/domain/models/kpi'
import type { Breakdown } from '../../../features/product/domain/models/breakdown'
import BreakdownBox from './BreakdownBox'

export interface MainKPIsProps {
  readonly kpis: KPIVM[]
  readonly selectedKPI: Accessor<KPIType>
  readonly setSelectedKPI: Setter<KPIType>
}

const MainStats: Component<MainKPIsProps> = (props) => {
  const { selectedKPI, setSelectedKPI } = props
  const [hovered, setHovered] = createSignal<KPIType>()

  const isKPISelected = (kpi: KPIVM): boolean => selectedKPI() === kpi.id
  const isLastElement = (kpi: KPIVM): boolean => kpi === props.kpis[props.kpis.length - 1]

  const customKPIStyles: StatCustomStyles = {
    label: styles.customResponsiveSize__label,
    value: styles.customResponsiveSize__value,
    valueContainer: styles.customResponsiveSize__valueContainer,
    diff: styles.customResponsiveSize__diff,
    placeholderSize: 'sm'
  }

  createEffect(() => {
    void props.kpis
    setHovered(undefined)
  })

  createEffect(() => {
    const kpiType = hovered()
    if (!kpiType) return

    const kpiContainer = document.getElementById(kpiType)
    const kpiBreakdown = document.getElementById(`${kpiType}_breakdown`)
    const dashboard = document.querySelector('#dashboard')

    if (kpiContainer && kpiBreakdown) {
      const dashboardRect = dashboard!.getBoundingClientRect()
      const { bottom, left } = kpiContainer.getBoundingClientRect()

      kpiBreakdown.style.left = `${left - dashboardRect.left + window.scrollX}px`
      kpiBreakdown.style.top = `${bottom - 8 - dashboardRect.top + window.scrollY}px`
    }
  })

  return (
    <div>
      <dl class="grid rounded-lg overflow-hidden bg-white shadow grid-cols-1 sm:grid-cols-4">
        <For each={props.kpis}>{(kpi) => (
          <>
            <div
              onClick={() => { setSelectedKPI(kpi.id) }}
              onMouseEnter={() => setHovered(kpi.id)}
              onMouseLeave={() => { setHovered(undefined) }}
              class={`${styles.stepContainer} cursor-pointer`}
              id={kpi.id}
            >
              <div class={`${isKPISelected(kpi) ? 'bg-gray-900 text-white' : 'bg-white hover:bg-gray-100 transition duration-300'} ${styles.step} justify-center relative`}>
                <div class={`${isLastElement(kpi) ? (kpi.value?.isPositive() ? 'text-green-500' : 'text-red-500') : ''} max-w-xxs w-full uppercase`}>
                  <Stat customStyles={customKPIStyles} stat={kpi}/>
                </div>
              </div>
            </div>
            <Show when={hovered() === kpi.id && kpi.breakdown}>{(breakdown: Accessor<Breakdown>) => (
              <div id={`${kpi.id}_breakdown`} class="absolute">
                <BreakdownBox breakdown={breakdown()} />
              </div>
            )}</Show>
          </>
        )}</For>
      </dl>
    </div>
  )
}

export default MainStats
