import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetAnalyticsQuery } from '../../data/queries/get-analytics.query'
import { type PeriodsState } from '../../../shared/period-state'
import { type Analytics } from '../models/analytics'

export class GetAnalyticsInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Analytics>
  ) {
  }

  public async execute (shopId: number, period: PeriodsState): Promise<Analytics> {
    return await this.getAnalytics.execute(new GetAnalyticsQuery(shopId, period.current.from, period.current.to, period.prev.from))
  }
}
