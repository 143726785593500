import { type Component } from 'solid-js'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import { Trans } from '@mbarzda/solid-i18next'
import { type Listing } from '../../../features/product/domain/models/listing'
import NoListing from '../../../assets/images/no-listing-sm.svg'

export interface ImageViewOnEtsyCellProps {
  readonly listing: Listing
}

const ImageViewOnEtsyCell: Component<ImageViewOnEtsyCellProps> = (props) => {
  const listingImage = props.listing.isDeleted() ? NoListing : props.listing.imgUrls.sm
  const listingTitle = props.listing.isDeleted() ? 'ls_listing_deleted' : props.listing.title

  return (
    <div class="flex gap-6">
      <div class="relative">
        <img class="h-[52px] min-w-[52px] rounded-lg" src={listingImage} alt=""/>
      </div>
      <div>
        <div class={`${props.listing.isDeleted() ? 'text-gray-500' : 'text-gray-900'} mb-1 line-clamp-1`}>
          <Trans key={listingTitle} />
        </div>
        <div class="text-xs text-blue-500">
          <a href={props.listing.url} target="_blank" class="text-gray-500 flex items-center gap-1">
            <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ImageViewOnEtsyCell
