import { type DeleteInteractor } from '@coris-ts/repository/interactor/delete.interactor'
import { DeleteCogsStatusQuery } from '../../data/queries/delete-cogs-status.query'

export class MarkCogsUpdateAsViewedInteractor {
  constructor(
    private readonly markCogsAsViewed: DeleteInteractor
  ) {
  }

  public async execute (
    shopId: number,
    statusId: string
  ): Promise<void> {
    await this.markCogsAsViewed.execute(
      new DeleteCogsStatusQuery(shopId, statusId)
    )
  }
}
