import { Product } from '../models/product'
import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type ProductEntity } from '../../data/entities/product.entity'
import { type ListingEntity } from '../../data/entities/listing.entity'
import { type Listing } from '../models/listing'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'
import { type Cogs, type CogsType } from '../models/cogs'
import { type CogsEntity, type CogsTypeEntity } from '../../data/entities/cogs.entity'
import { t } from 'i18next'

export class ProductEntityToProductMapper implements Mapper<ProductEntity, Product> {
  constructor(
    private readonly listingMapper: Mapper<ListingEntity, Listing>,
    private readonly cogsMapper: Mapper<CogsEntity, Cogs>,
    private readonly cogsTypeMapper: Mapper<CogsTypeEntity, CogsType>
  ) {
  }

  public map(from: ProductEntity): Product {
    return new Product(
      from.product_id,
      from.sku,
      from.variant_name || t('ls_shared_no_variants'),
      this.listingMapper.map(from.listing),
      this.cogsTypeMapper.map(from.cogs_type),
      from.price,
      from.quantity,
      from.is_available,
      from.is_deleted,
      from.cogs ? this.cogsMapper.map(from.cogs) : undefined,
      mapTimestampToDate(from.first_order_timestamp)
    )
  }
}
