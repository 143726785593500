import { type Component, type JSX } from 'solid-js'
import { A } from '@solidjs/router'
import { Trans } from '@mbarzda/solid-i18next'

export interface SidebarItemProps {
  readonly url: string
  readonly icon: JSX.Element
  readonly textKey: string
  readonly onItemSelected?: () => void
}

const SidebarItem: Component<SidebarItemProps> = (props) => {
  return (
    <A onClick={props.onItemSelected} activeClass="bg-japanese-800 !text-june_bud-500" href={props.url} class="text-white hover:text-june_bud-500 hover:bg-japanese-800 flex items-center rounded-md p-2 text-sm leading-6 font-semibold justify-between">
      <div class="flex gap-3">
        {props.icon}
        <span class="text-white"><Trans key={`ls_sidebar_${props.textKey}`} /></span>
      </div>
    </A>
  )
}

export default SidebarItem
