import { type Component, createEffect, createSignal, Show } from 'solid-js'
import { MainModule } from '../../features/main-module'
import { Trans } from '@mbarzda/solid-i18next'
import { useShop } from '../../shared/providers/shop.provider'
import { useError } from '../../shared/providers/error.provider'
import { useLocation, useParams } from '@solidjs/router'
import { type ProductCogs } from '../../features/product/domain/models/product-cogs'
import SkuSummaryBox from './SkuSummaryBox'
import CogsFormDialog, { type CogsFormData } from './CogsFormDialog'
import { CogsStatus } from '../../features/product/domain/models/product-cost'
import MiniPlus from '../../assets/heroicons/MiniPlus'
import CogsCurrentPeriod from './CogsCurrentPeriod'
import CogsPeriodHistory from './CogsPeriodHistory'
import { type Cogs as COGs } from '../../features/product/domain/models/cogs'
import { eqDate, isToday, theDayAfter, theDayBefore } from '../../shared/helpers/date.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { type Product } from '../../features/product/domain/models/product'
import { useTracking } from '../../shared/providers/tracking.provider'

enum CogsTabs {
  CurrentPeriod,
  PeriodHistory
}

const Cogs: Component = () => {
  const [productCogs, setProductCogs] = createSignal<ProductCogs | undefined>(undefined)
  const [activeTab, setActiveTab] = createSignal<CogsTabs>(CogsTabs.CurrentPeriod)
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [isCreatingCogsPeriod, setIsCreatingCogsPeriod] = createSignal<boolean>(false)
  const [isAddingCogsPeriod, setIsAddingCogsPeriod] = createSignal<boolean>(false)
  const [editingCogsPeriod, setEditingCogsPeriod] = createSignal<COGs>()
  const [isReconciliating, setIsReconcialiting] = createSignal<boolean>(false)

  const { selectedShopId } = useShop()
  const { setError } = useError()
  const { trackEvent } = useTracking()

  const getProductCogs = MainModule.getProductComponent().provideGetProductCogs()
  const reconcileCogs = MainModule.getProductComponent().provideReconcileCogs()
  const createCogsPeriod = MainModule.getProductComponent().provideCreateCogsPeriod()
  const updateCogsHistoric = MainModule.getProductComponent().provideUpdateProductCogsHistoricInteractor()

  const location = useLocation()

  createEffect(() => {
    const shopId = selectedShopId()
    if (!shopId) return

    const params = useParams()
    reloadProduct(shopId, +params.productId)
  })

  const firstProduct = (): Product => productCogs()!.product

  const reloadProduct = (shopId: number, productId: number): void => {
    setIsLoading(true)
    executeWithUrlScope(
      async () => {
        await getProductCogs.execute(shopId, productId).then((productCogs) => {
          setProductCogs(productCogs)
        })
      }, location
    )
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onCreateCogsPeriod = (data: CogsFormData): void => {
    setIsLoading(true)
    setIsCreatingCogsPeriod(false)
    setIsAddingCogsPeriod(false)

    const productId = firstProduct().id
    const firstOrderDate = productCogs()!.firstOrderDate
    const date = isToday(data.date) ? new Date() : data.date

    let time = date.getTime()
    if (firstOrderDate) {
      time = eqDate(date, firstOrderDate) ? firstOrderDate.getTime() + 1000 : time
    }

    trackEvent('Cogs Update', { shopId: selectedShopId(), type: 'create period' })

    void createCogsPeriod
      .execute(selectedShopId()!, productId, data.amount, time)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        reloadProduct(selectedShopId()!, productId)
      })
  }

  const onReconciliateCogs = (data: CogsFormData): void => {
    setIsLoading(true)
    setIsReconcialiting(false)

    const productId = firstProduct().id
    trackEvent('Cogs Update', { shopId: selectedShopId(), type: 'reconciliate' })

    void reconcileCogs
      .execute(selectedShopId()!, productId, data.amount)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        reloadProduct(selectedShopId()!, productId)
      })
  }

  const onUpdateCogsPeriod = (data: CogsFormData): void => {
    setIsLoading(true)

    const newHistoric = productCogs()!.cogsHistory.map(cogs => {
      return cogs === editingCogsPeriod() ? { ...cogs, amount: data.amount } : cogs
    })

    setEditingCogsPeriod(undefined)

    const productId = firstProduct().id
    trackEvent('Cogs Update', { shopId: selectedShopId(), type: 'update period' })

    void updateCogsHistoric
      .execute(selectedShopId()!, productId, newHistoric)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        reloadProduct(selectedShopId()!, productId)
      })
  }

  const onDeleteCogsPeriod = (date: Date): void => {
    setIsLoading(true)
    setEditingCogsPeriod(undefined)

    const newHistoric = productCogs()!.cogsHistory.filter(cogs => cogs.fromDate !== date)

    const productId = firstProduct().id
    trackEvent('Cogs Update', { shopId: selectedShopId(), type: 'delete period' })

    void updateCogsHistoric
      .execute(selectedShopId()!, productId, newHistoric)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        reloadProduct(selectedShopId()!, productId)
      })
  }

  const getDayAfterCurrentCogsOrToday = (): Date | undefined => {
    const lastCogsDate = productCogs()?.product.cogs?.fromDate
    if (!lastCogsDate) {
      return undefined
    }

    return new Date(
      Math.min(
        theDayAfter(lastCogsDate).getTime(),
        new Date().getTime()
      )
    )
  }

  return (
    <div class="flex flex-col gap-4">
      <h1 class="page-title"><Trans key="ls_cogs_title"/></h1>
      <SkuSummaryBox product={productCogs()?.product} numListings={productCogs()?.productCount}/>

      <div class="flex justify-between gap-4 items-center">
        <nav
          class="font-medium border-b border-gray-200 text-gray-500 -mb-px flex gap-2 md:gap-8 grow"
          aria-label="Tabs">
          <button onClick={() => setActiveTab(CogsTabs.CurrentPeriod)}
                  class={`${activeTab() === CogsTabs.CurrentPeriod ? 'text-japanese-600 border-japanese-600' : 'hover:border-gray-300 hover:text-gray-500 border-transparent'} border-b-2 whitespace-nowrap py-4 px-1 text-sm font-medium`}>
            <Trans key="ls_cogs_current_cost_period" />
          </button>
          <Show when={productCogs()?.cogsStatus === CogsStatus.Valid}>
            <button onClick={() => setActiveTab(CogsTabs.PeriodHistory)}
                    class={`${activeTab() === CogsTabs.PeriodHistory ? 'text-japanese-600 border-japanese-600' : 'hover:border-gray-300 hover:text-gray-500 border-transparent'} border-b-2 whitespace-nowrap py-4 px-1 text-sm font-medium`}>
              <Trans key="ls_cogs_current_period_history" />
            </button>
          </Show>
        </nav>

        <Show when={productCogs()}>{(cogs) => (
          <>
            <Show when={[CogsStatus.Valid, CogsStatus.MissingWithoutOrders].includes(cogs().cogsStatus) && activeTab() === CogsTabs.CurrentPeriod}>
              <button
                onClick={() => cogs().cogsStatus === CogsStatus.MissingWithoutOrders ? setIsReconcialiting(true) : setIsCreatingCogsPeriod(true)}
                class="w-fit gap-2 flex items-center px-3 py-2 justify-center h-fit rounded-md bg-japanese-600 text-sm font-semibold text-white shadow-sm hover:bg-japanese-800 duration-200">
                <MiniPlus size={4}/> <Trans key="ls_cogs_update_costs" />
              </button>
            </Show>

            <Show when={cogs()?.cogsStatus === CogsStatus.Valid && activeTab() === CogsTabs.PeriodHistory}>
              <Show when={cogs().cogsHistory.length > 0}>
                <button
                  onClick={() => setIsAddingCogsPeriod(true)}
                  class="w-fit gap-2 flex items-center px-3 py-2 justify-center h-fit rounded-md bg-japanese-600 text-sm font-semibold text-white shadow-sm hover:bg-japanese-800 duration-200">
                  <MiniPlus size={4}/> <Trans key="ls_cogs_add_cost_period" />
                </button>
              </Show>
            </Show>
          </>
        )}</Show>
      </div>

      <Show when={activeTab() === CogsTabs.CurrentPeriod}>
        <CogsCurrentPeriod productCogs={productCogs()} isLoading={isLoading()} onReconciliate={() => setIsReconcialiting(true)}/>
      </Show>

      <Show when={activeTab() === CogsTabs.PeriodHistory}>
        <CogsPeriodHistory productCogs={productCogs()} isLoading={isLoading()} onEditCogsPeriod={setEditingCogsPeriod}/>
      </Show>

      <Show when={isReconciliating()}>
        <CogsFormDialog
          type='reconcile'
          defaultDate={productCogs()?.firstOrderDate}
          cancel={() => setIsReconcialiting(false)}
          submit={onReconciliateCogs}
          otherPeriods={[]}
          isDateDisabled={true} />
      </Show>

      <Show when={isCreatingCogsPeriod()}>
        <CogsFormDialog
          type='create'
          firstAllowedDate={getDayAfterCurrentCogsOrToday()}
          lastAllowedDate={new Date()}
          cancel={() => setIsCreatingCogsPeriod(false)}
          submit={onCreateCogsPeriod}
          otherPeriods={[]}
          isDateDisabled={eqDate(firstProduct().cogs!.fromDate, new Date())} />
      </Show>

      <Show when={isAddingCogsPeriod()}>
        <CogsFormDialog
          type='create'
          defaultDate={theDayBefore(firstProduct().cogs!.fromDate)}
          firstAllowedDate={productCogs()!.firstOrderDate}
          lastAllowedDate={theDayBefore(firstProduct().cogs!.fromDate)}
          cancel={() => setIsAddingCogsPeriod(false)}
          otherPeriods={productCogs()!.cogsHistory}
          submit={onCreateCogsPeriod} />
      </Show>

      <Show when={editingCogsPeriod()}>{(cogs) => (
        <CogsFormDialog
          type='update'
          defaultDate={cogs().fromDate}
          defaultAmount={cogs().amount}
          cancel={() => { setEditingCogsPeriod(undefined) }}
          delete={onDeleteCogsPeriod}
          submit={onUpdateCogsPeriod}
          canBeDeleted={productCogs()!.cogsHistory.reduce((a, b) => a.fromDate < b.fromDate ? a : b) !== cogs()}
          otherPeriods={productCogs()!.cogsHistory.filter(period => period !== editingCogsPeriod())}
          isDateDisabled={true} />
      )}</Show>
    </div>
  )
}

export default Cogs
