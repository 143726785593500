import { type Subscription } from '../models/subscription'
import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { PurchaseProductQuery } from '../../data/queries/purchase-product.query'

export class PurchaseProductInteractor {
  constructor(
    private readonly purchase: PutInteractor<Subscription>
  ) {
  }

  public async execute(subscriptionId: string, priceId: string): Promise<Subscription> {
    return await this.purchase.execute(undefined, new PurchaseProductQuery(subscriptionId, priceId))
  }
}
