import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type OrderEntity } from '../../data/entities/order.entity'
import { Order } from '../models/order'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'

export class OrderEntityToOrderMapper implements Mapper<OrderEntity, Order> {
  public map(from: OrderEntity): Order {
    return new Order(
      mapTimestampToDate(from.created_timestamp)!,
      from.discounts,
      from.receipt_id,
      from.status,
      from.url,
      from.currency,
      from.item_count,
      from.paid,
      from.refunds,
      from.total_price,
      from.shipping,
      from.sales_tax,
      from.total_cogs,
      from.missing_cogs,
      from.gross_profit
    )
  }
}
