export enum ProductSorting {
  MostRecent = 'most_recent',
  Oldest = 'oldest',
  BestSellers = 'best_sellers',
  Underperformers = 'underperformers',
  MostProfitable = 'most_profitable',
  LessProfitable = 'less_profitable',
  TrendSetters = 'trend_setters',
  FallingStars = 'falling_stars',
}

export const ProductFilterSorting = {
  [ProductSorting.MostRecent]: 'last_order_date:desc',
  [ProductSorting.Oldest]: 'last_order_date:asc',
  [ProductSorting.BestSellers]: 'units_sold:desc',
  [ProductSorting.Underperformers]: 'units_sold:asc',
  [ProductSorting.MostProfitable]: 'operating_profit:desc',
  [ProductSorting.LessProfitable]: 'operating_profit:asc',
  [ProductSorting.TrendSetters]: 'ratio_units_sold:desc',
  [ProductSorting.FallingStars]: 'ratio_units_sold:asc'
}
