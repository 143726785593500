import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type SortDirection } from '../../../../shared/models/sort-direction'
import { type OrderStatus } from '../../domain/models/order'
import { ProductFilter } from '../../domain/models/product-cost'

export class GetOrdersQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly startTime: number,
    private readonly endTime: number,
    private readonly prevStartTime: number,
    private readonly offset: number,
    private readonly limit: number,
    private readonly searchTerm?: string,
    private readonly sortBy?: string,
    private readonly sortDirection?: SortDirection,
    private readonly status?: OrderStatus[],
    private readonly filter?: ProductFilter
  ) {
    super(ApiEndpoint.Orders)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.startTime,
      to_date: this.endTime,
      prev_from_date: this.prevStartTime,
      offset: this.offset,
      limit: this.limit,
      ...this.searchTerm && { order_id_filter: this.searchTerm },
      ...this.sortBy && { sort_by: this.sortBy },
      ...this.sortDirection && { sort_order: this.sortDirection },
      ...this.status?.length && { states: this.status.join(',') },
      ...(this.filter && this.filter !== ProductFilter.All) && { orders_filter: this.filter }
    }
  }
}
