import type { Component } from 'solid-js'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'

export interface ViewOnEtsyCellProps {
  readonly text: string
  readonly url: string
}

const ViewOnEtsyCell: Component<ViewOnEtsyCellProps> = (props) => {
  return (
    <div class="text-sm text-gray-500">
      <a href={props.url} target="_blank" class="flex items-center gap-2">
        <MiniEtsy size={4}/><span>{props.text}</span>
      </a>
    </div>
  )
}

export default ViewOnEtsyCell
