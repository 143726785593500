import { createSignal, For, Show } from 'solid-js'
import type { JSX } from 'solid-js'
import { clickOutside } from '../../../shared/directives/click-outside'
import MiniChevronDown from '../../../assets/heroicons/MiniChevronDown'
import { Trans } from '@mbarzda/solid-i18next'
import {
  type EnumBoolRecord,
  fromEnumToBoolObject
} from '../../../shared/helpers/from-enum-to-bool-object'

export interface MultiSelectProps<T> {
  readonly options: T
  readonly name: string
  readonly placeholder: string
  readonly setSelectedOptions: (filters: Array<T[keyof T]>) => void
  readonly width?: 'content' | 'full'
}

const MultiSelect = <T extends Record<string, string>,>(props: MultiSelectProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = createSignal<boolean>(false)
  const [optionsState, setOptionsState] = createSignal<EnumBoolRecord<T>>(fromEnumToBoolObject<T>(props.options))

  const getSelectedFilters = (): Array<T[keyof T]> => (
    Object.entries(optionsState()).filter(([, value]) => value).map(([key]) => key as T[keyof T])
  )

  const handleCheckboxChange = (option: T[keyof T], event: Event): void => {
    const target = event.target as HTMLInputElement
    setOptionsState((prevOptionsState: EnumBoolRecord<T>) => {
      return {
        ...prevOptionsState,
        [option]: target.checked
      }
    })
    props.setSelectedOptions(getSelectedFilters())
  }

  return (
    <div class="relative" classList={{ 'w-full pl-4 pr-2': props.width === 'full' }}>
      <button onClick={() => setIsOpen(true)} type="button" class="cursor-pointer relative w-full bg-white py-1.5 pr-8 text-left text-gray-900 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
        <span class="block truncate text-sm"><Trans key={props.placeholder} /></span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
          <MiniChevronDown />
        </span>
      </button>
      <Show when={isOpen()}>
        <ul ref={el => { clickOutside(el, () => setIsOpen(false)) }}
            class={`${props.width === 'full' ? 'right-0 left-0 top-10' : 'right-2 w-auto'} absolute flex flex-col gap-4 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`} tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option">
          <For each={Object.keys(optionsState())}>{(option: T[keyof T]) => (
            <li class="text-gray-900 text-sm relative cursor-default select-none px-4" role="option">
              <label onChange={(ev) => { handleCheckboxChange(option, ev) }} for={`option-${option}`} class="flex items-center gap-2 cursor-pointer">
                <input checked={optionsState()[option]} id={`option-${option}`} aria-describedby={`option-${option}`} type="checkbox" class="h-4 w-4 cursor-pointer !rounded !border-gray-300 focus:!ring-transparent !text-japanese-600 checked:!border-0" />
                <span class="font-normal block truncate"><Trans key={`${props.name}_${option}`} /></span>
              </label>
            </li>
          )}</For>
        </ul>
      </Show>
    </div>
  )
}

export default MultiSelect
