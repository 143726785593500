import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetProductQuery } from '../../data/queries/get-product.query'
import { type ProductCogs } from '../models/product-cogs'

export class GetProductCogsInteractor {
  constructor(
    private readonly getProduct: GetInteractor<ProductCogs>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number
  ): Promise<ProductCogs> {
    return await this.getProduct.execute(new GetProductQuery(shopId, productId))
  }
}
