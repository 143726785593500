import { type TaskStatus } from '../models/task-status'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetTaskStatusQuery } from '../../data/queries/get-task-status.query'

export class GetBulkUpdateStatusInteractor {
  constructor (
    private readonly getTaskStatus: GetInteractor<TaskStatus>
  ) {}

  public async execute(taskId: string): Promise<TaskStatus> {
    return await this.getTaskStatus.execute(new GetTaskStatusQuery(taskId))
  }
}
