import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import {
  getNetSalesBreakdownKpis,
  getOpExpensesBreakdownKpis
} from '../../../../private/shared/functions/kpi.functions'
import { type ProductReportEntity } from '../../data/entities/product.entity'
import { ProductReport } from '../models/product'
import { type ListingEntity } from '../../data/entities/listing.entity'
import { type Listing } from '../models/listing'
import { type CogsEntity, type CogsTypeEntity } from '../../data/entities/cogs.entity'
import { type Cogs, type CogsType } from '../models/cogs'
import { KPIEntityToKPIMapper } from '../../../analytics/domain/mappers/kpi.mappers'
import { KPIType } from '../../../analytics/domain/models/kpi'
import { t } from 'i18next'

export class ProductReportEntityToProductReportMapper implements Mapper<ProductReportEntity, ProductReport> {
  constructor(
    private readonly listingMapper: Mapper<ListingEntity, Listing>,
    private readonly cogsMapper: Mapper<CogsEntity, Cogs>,
    private readonly cogsTypeMapper: Mapper<CogsTypeEntity, CogsType>
  ) {
  }

  public map(from: ProductReportEntity): ProductReport {
    const product = from.product
    const KPIs = from.data_points

    const UnitsSold = KPIEntityToKPIMapper(KPIs[KPIType.UnitsSold], KPIType.UnitsSold)

    return new ProductReport(
      product.product_id,
      product.sku,
      product.variant_name ?? t('ls_shared_no_variant'),
      this.listingMapper.map(product.listing),
      this.cogsTypeMapper.map(product.cogs_type),
      product.price,
      product.quantity,
      product.is_available,
      product.is_deleted,
      product.cogs ? this.cogsMapper.map(product.cogs) : undefined,
      product.first_order_timestamp ? new Date(product.first_order_timestamp) : undefined,
      from.missing_cogs,
      KPIEntityToKPIMapper(KPIs[KPIType.Cogs], KPIType.Cogs),
      KPIEntityToKPIMapper(KPIs[KPIType.Discounts], KPIType.Discounts),
      KPIEntityToKPIMapper(KPIs[KPIType.GrossSales], KPIType.GrossSales),
      KPIEntityToKPIMapper(KPIs[KPIType.ListingFees], KPIType.ListingFees),
      KPIEntityToKPIMapper(KPIs[KPIType.NetSales], KPIType.NetSales, getNetSalesBreakdownKpis(KPIs), UnitsSold),
      KPIEntityToKPIMapper(KPIs[KPIType.OperatingExpenses], KPIType.OperatingExpenses, getOpExpensesBreakdownKpis(KPIs), UnitsSold),
      KPIEntityToKPIMapper(KPIs[KPIType.OperatingProfit], KPIType.OperatingProfit),
      KPIEntityToKPIMapper(KPIs[KPIType.Orders], KPIType.Orders),
      KPIEntityToKPIMapper(KPIs[KPIType.ProcessingFees], KPIType.ProcessingFees),
      KPIEntityToKPIMapper(KPIs[KPIType.Profit], KPIType.Profit),
      KPIEntityToKPIMapper(KPIs[KPIType.Refunds], KPIType.Refunds),
      KPIEntityToKPIMapper(KPIs[KPIType.Roi], KPIType.Roi),
      KPIEntityToKPIMapper(KPIs[KPIType.ShippingCollected], KPIType.ShippingCollected),
      KPIEntityToKPIMapper(KPIs[KPIType.ShippingCosts], KPIType.ShippingCosts),
      KPIEntityToKPIMapper(KPIs[KPIType.TransactionFees], KPIType.TransactionFees),
      UnitsSold
    )
  }
}
