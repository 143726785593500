import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type IntegrationShop } from '../models/integration-shop'
import { GetPrintifyShopsQuery } from '../../data/queries/get-printify-shops.query'

export class GetPrintifyShopsInteractor {
  constructor(
    private readonly getShops: GetInteractor<IntegrationShop[]>
  ) {}

  public async execute(shopId: number): Promise<IntegrationShop[]> {
    return await this.getShops.execute(new GetPrintifyShopsQuery(shopId))
  }
}
