import type { Accessor, Component } from 'solid-js'
import { Show } from 'solid-js'
import { CogsStatus } from '../../features/product/domain/models/product-cost'
import { NumericalDataType } from '../../shared/models/numerical-data-type'
import ImageCell from '../shared/components/ImageCell'
import MiniPencil from '../../assets/heroicons/MiniPencil'
import NoListing from '../../assets/images/no-listing-sm.svg'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import MinMaxTableCell from '../shared/components/MinMaxTableCell'
import OneOrClampCell from '../shared/components/OneOrClampCell'
import { CogsType } from '../../features/product/domain/models/cogs'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  type Size
} from '../../shared/helpers/dom.functions'
import Tooltip from '../shared/components/Tooltip'
import { useMeasureComponent } from '../shared/functions/use-measure'
import { Trans } from '@mbarzda/solid-i18next'
import CostsCogsValueCell from '../shared/components/CostsCogsValueCell'
import CatalogSkuCell from '../shared/components/CatalogSkuCell'
import ValueCell from '../shared/components/ValueCell'
import { type ProductCostsWithUnits } from '../../features/product/domain/models/product-costs-with-units'

export interface CogsIssuesTableRowProps {
  readonly product: ProductCostsWithUnits
}

const CogsIssuesTableRow: Component<CogsIssuesTableRowProps> = (props) => {
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  const product = props.product

  if (product.cogsType !== CogsType.None) {
    tooltipSize = useMeasureComponent(<Tooltip title="ls_cogs_automatic" text="ls_cogs_automatic_description"/>)
  }

  const showWarning = (): boolean => {
    return ![CogsStatus.Valid, CogsStatus.MissingWithoutOrders].includes(props.product.cogsStatus)
  }

  const showTooltip = (event: MouseEvent): void => {
    if (!tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <Tooltip title="ls_cogs_automatic" text="ls_cogs_automatic_description" position={position}/>
    )
  }

  const hideTooltip = (): void => {
    removeTooltip()
  }

  const listingImage = product.product.isListingDeleted() ? NoListing : product.product.listing.imgUrls.sm
  const listingTitle = product.product.listing.isDeleted() ? 'ls_listing_deleted' : product.product.listing.title

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 sm:pl-6 min-w-[88px]">
        <ImageCell imgUrl={listingImage} isMultiple={product.productCount > 1}
                   integrationType={product.cogsType}/>
      </td>
      <td class="tableCell min-w-[220px] whitespace-nowrap">
        <CatalogSkuCell product={product}/>
      </td>
      <td class="tableCell min-w-[260px]">
        <Show when={!product.product.isListingDeleted()} fallback={
          <span class="mb-[20px] block text-gray-500"><Trans key="ls_listing_deleted"/></span>
        }>
          <OneOrClampCell value={listingTitle} numValues={product.productCount}/>
        </Show>
      </td>
      <td class="tableCell min-w-[164px]">
        <OneOrClampCell value={product.product.variantName} numValues={product.productCount}/>
      </td>
      <td class="tableCell min-w-[140px]">
        <MinMaxTableCell min={product.minPrice} max={product.maxPrice} type={NumericalDataType.Currency}/>
      </td>
      <td class="tableCell min-w-[120px]">
        <ValueCell value={product.unitsSold} type={NumericalDataType.Units}/>
      </td>
      <td class="tableCell min-w-[112px] max-w-[145px]">
        <CostsCogsValueCell value={product.product.cogs?.amount} multipleCogs={product.hasMultipleCogs} warning={showWarning()}/>
      </td>
      <td class="px-3 w-12">
        <div class="flex items-center justify-center text-gray-400">
          <Show when={product.cogsType === CogsType.None} fallback={
            <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
              <MiniInformationCircle size={4}/>
            </div>
          }>
            <A href={AppRoutes.ProductCogs(product.product.id)}><MiniPencil/></A>
          </Show>
        </div>
      </td>
    </tr>
  )
}

export default CogsIssuesTableRow
