import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetAnalyticsTimeseriesQuery } from '../../data/queries/get-analytics-timeseries.query'
import { type PeriodTime } from '../../../shared/period-state'
import { type Timeseries } from '../models/timeseries'
import { type TimeseriesResolution } from '../../../../private/shared/analytics.vm'

export class GetAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution): Promise<Timeseries> {
    return await this.getAnalytics.execute(new GetAnalyticsTimeseriesQuery(shopId, period.from, period.to, resolution))
  }
}
