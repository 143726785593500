import { type Component, onCleanup, onMount } from 'solid-js'
import lottie, { type AnimationItem } from 'lottie-web'

export interface LottieAnimationProps {
  readonly animationData: object
  readonly width?: string
}

const LottieAnimation: Component<LottieAnimationProps> = (props) => {
  let animationContainer: HTMLDivElement | undefined
  let animationInstance: AnimationItem | null = null

  onMount(() => {
    if (animationContainer) {
      if (props.width) {
        animationContainer.style.width = props.width
      }

      animationInstance = lottie.loadAnimation({
        container: animationContainer, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: props.animationData // direct JSON object
      })
    }
  })

  onCleanup(() => {
    animationInstance?.destroy()
  })

  return (
    <div ref={animationContainer}></div>
  )
}

export default LottieAnimation
