import { type Component, For, Show } from 'solid-js'
import Table, { type TableColumn, type TableStatus } from '../shared/components/Table'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { type ProductCogs } from '../../features/product/domain/models/product-cogs'
import PeriodHistoryCogsTableRow from './PeriodHistoryCogsTableRow'
import { type Cogs } from '../../features/product/domain/models/cogs'
import NoCogsHistory from './NoCogsHistory'

export interface CogsPeriodHistoryProps {
  readonly productCogs?: ProductCogs
  readonly isLoading: boolean
  readonly onEditCogsPeriod: (cogs: Cogs) => void
}

const CogsPeriodHistory: Component<CogsPeriodHistoryProps> = (props) => {
  const columns: TableColumn[] = [
    { id: 'from_date' },
    { id: 'to_date' },
    { id: 'listings' },
    { id: 'cogs' },
    { id: 'empty' }
  ]

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(props.isLoading, false, false, props.productCogs?.cogsHistory)
  }

  const getFinishDate = (cogs: Cogs): Date => {
    const history = props.productCogs!.cogsHistory.toReversed()
    const index = history.findIndex(cog => cog.fromDate === cogs.fromDate)
    if (index !== -1 && index + 1 < history.length) {
      return history[index + 1].fromDate
    }

    return props.productCogs!.product.cogs!.fromDate
  }

  return (
    <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
      <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
          <Table columns={columns} status={getTableStatus}>
            <For each={props.productCogs?.cogsHistory}>{(cogs) => (
              <PeriodHistoryCogsTableRow product={props.productCogs!.product} numListings={props.productCogs!.productCount} cogs={cogs} nextCogsDate={getFinishDate(cogs)} onEditCogsPeriod={props.onEditCogsPeriod}/>
            )}</For>
            <Show when={props.productCogs?.cogsHistory.length === 0}>
              <td class="h-60">
                <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                  <NoCogsHistory />
                </div>
              </td>
            </Show>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default CogsPeriodHistory
