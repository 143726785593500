import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { type IntegrationType } from '../models/shop'
import { IntegrationAuthUrlQuery } from '../../data/queries/integration-auth-url.query'

export class GetIntegrationAuthUrlInteractor {
  constructor (
    private readonly getIntegrationAuthUrl: PutInteractor<string>
  ) {}

  public async execute (shopId: number, integration: IntegrationType): Promise<string> {
    return await this.getIntegrationAuthUrl.execute(undefined, new IntegrationAuthUrlQuery(shopId, window.location.href, integration))
  }
}
