import ArrowTrendingUp from '../../assets/heroicons/ArrowTrendingUp'
import ShoppingBag from '../../assets/heroicons/ShoppingBag'
import Truck from '../../assets/heroicons/Truck'
import RectangleGroup from '../../assets/heroicons/RectangleGroup'
import { KPIGroupId, type KPIGroupsConfig } from '../../shared/models/kpi-group'
import { KPIType } from '../../features/analytics/domain/models/kpi'

export const AnalyticsGroups: Record<KPIGroupId, KPIType[]> = {
  [KPIGroupId.Main]: [KPIType.NetSales, KPIType.Cogs, KPIType.OperatingExpenses, KPIType.OperatingProfit],
  [KPIGroupId.Performance]: [KPIType.Profit, KPIType.Roi, KPIType.UnitsSold],
  [KPIGroupId.Orders]: [KPIType.Orders, KPIType.AvgOrderValue, KPIType.AvgShippingCost],
  [KPIGroupId.ListingOrders]: [KPIType.Orders],
  [KPIGroupId.Shipping]: [KPIType.ShippingCollected, KPIType.ShippingCosts, KPIType.ShippingBalance],
  [KPIGroupId.Advertisement]: [KPIType.AdCosts, KPIType.Roas, KPIType.BreakEvenRoas],
  [KPIGroupId.ListingAdvertisement]: [KPIType.BreakEvenRoas],
  [KPIGroupId.FinancialDashboard]: [KPIType.NetSales, KPIType.GrossSales, KPIType.ShippingCollected, KPIType.Discounts, KPIType.Refunds, KPIType.Cogs, KPIType.TransactionFees, KPIType.ListingFees, KPIType.ProcessingFees, KPIType.ShippingCosts, KPIType.AdCosts, KPIType.OperatingProfit, KPIType.OperatingExpenses],
  [KPIGroupId.FinancialChart]: [KPIType.Cogs, KPIType.TransactionFees, KPIType.ListingFees, KPIType.ProcessingFees, KPIType.ShippingCosts, KPIType.AdCosts, KPIType.OperatingProfit, KPIType.NetSales],
  [KPIGroupId.NetSales]: [KPIType.GrossSales, KPIType.ShippingCollected, KPIType.Discounts, KPIType.Refunds, KPIType.NetSales],
  [KPIGroupId.Cogs]: [KPIType.Cogs, KPIType.Cogs],
  [KPIGroupId.OperatingExpenses]: [KPIType.TransactionFees, KPIType.ListingFees, KPIType.ProcessingFees, KPIType.ShippingCosts, KPIType.AdCosts, KPIType.OperatingExpenses]
}

export const AnalyticsConfig: KPIGroupsConfig = {
  [KPIGroupId.Main]: {
    id: KPIGroupId.Main,
    icon: ArrowTrendingUp
  },
  [KPIGroupId.Performance]: {
    id: KPIGroupId.Performance,
    icon: ArrowTrendingUp
  },
  [KPIGroupId.Orders]: {
    id: KPIGroupId.Orders,
    icon: ShoppingBag
  },
  [KPIGroupId.ListingOrders]: {
    id: KPIGroupId.ListingOrders,
    icon: ShoppingBag
  },
  [KPIGroupId.Shipping]: {
    id: KPIGroupId.Shipping,
    icon: Truck
  },
  [KPIGroupId.Advertisement]: {
    id: KPIGroupId.Advertisement,
    icon: RectangleGroup
  },
  [KPIGroupId.ListingAdvertisement]: {
    id: KPIGroupId.ListingAdvertisement,
    icon: RectangleGroup
  }
}

export enum TimeseriesResolution {
  Day = 'day',
  Month = 'month'
}
export const TimeseriesResolutionFromPeriod = (start: number, end: number): TimeseriesResolution => {
  const MAX_QUARTER = 93
  const period = end - start
  const days = period / (60 * 60 * 24)
  if (days > MAX_QUARTER) return TimeseriesResolution.Month
  return TimeseriesResolution.Day
}
