import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import issue from '../../assets/images/issue.png'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import { type ShopType } from '../../features/shop/domain/models/shop'
import TranslateWithAction from '../shared/components/TranslateWithAction'

export interface IntegrationConnectionErrorDialogProps {
  readonly cancel: () => void
  readonly integration: ShopType
}

const IntegrationConnectionErrorDialog: Component<IntegrationConnectionErrorDialogProps> = (props) => {
  const integrationName = props.integration[0].toUpperCase() + props.integration.slice(1).toLowerCase()
  return (
    <Dialog maxWidth={'434px'}>
      <div class="py-6 px-8 mx-auto text-center text-black flex gap-2 flex-col items-center">
        <img src={issue} class="h-24" alt="error"/>
        <span class="text-base text-gray-900 font-bold">
          <Trans key="ls_integrations_couldnt_connect" options={{ integration: integrationName }}/>
        </span>
        <span class="text-sm text-gray-500">
          <Trans key="ls_integrations_couldnt_connect_description" options={{ integration: integrationName }}/>
          <TranslateWithAction key='ls_integrations_couldnt_connect_cta'
                               action={() => {
                                 window.Intercom('show')
                               }}
          />
        </span>
      </div>

      <DialogActions>
        <button onClick={props.cancel} class="btn btn--white">
          <Trans key="ls_generic_close"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default IntegrationConnectionErrorDialog
