import { type Component, createEffect, onCleanup } from 'solid-js'
import { MainModule } from '../../features/main-module'
import ProcessingPlanChangeDialog from './ProcessingPlanChangeDialog'
import { useUser } from '../../public/auth/user-provider'

export interface ProcessingSubscriptionUpdateDialogProps {
  readonly onSubscriptionReady: () => void
  readonly newSubscriptionId: string
}

const ProcessingSubscriptionUpdateDialog: Component<ProcessingSubscriptionUpdateDialogProps> = (props) => {
  const { setUserPurchases } = useUser()
  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()

  createEffect(() => {
    const intervalId = setInterval(async (): Promise<void> => {
      const purchases = await getPurchases.execute()
      const isSubscriptionReady = purchases.subscriptions.some(sub => sub.productPrice.id === props.newSubscriptionId)
      if (isSubscriptionReady) {
        setUserPurchases(purchases)
        clearInterval(intervalId)
        props.onSubscriptionReady()
      }
    }, 2000)

    onCleanup(() => { clearInterval(intervalId) })
  })

  return (
    <ProcessingPlanChangeDialog />
  )
}

export default ProcessingSubscriptionUpdateDialog
