import { type Component, createEffect, createSignal, For, Match, Show, Switch } from 'solid-js'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import CurrentPeriodCogsTableRow from './CurrentPeriodCogsTableRow'
import MissingCogsBanner from './MissingCogsBanner'
import { type ProductCogs } from '../../features/product/domain/models/product-cogs'
import { CogsStatus } from '../../features/product/domain/models/product-cost'
import MismatchCogsBanner from './MismatchCogsBanner'
import { type Product } from '../../features/product/domain/models/product'
import TablePagination from '../shared/components/TablePagination'
import { MainModule } from '../../features/main-module'
import type { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { useShop } from '../../shared/providers/shop.provider'
import { useError } from '../../shared/providers/error.provider'
import { useLocation } from '@solidjs/router'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import FirstPeriodAfterFirstOrderBanner from './FirstPeriodAfterFirstOrderBanner'

export interface CogsCurrentPeriodProps {
  readonly productCogs?: ProductCogs
  readonly isLoading: boolean
  readonly onReconciliate: () => void
}

const CogsCurrentPeriod: Component<CogsCurrentPeriodProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 15
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [products, setProducts] = createSignal<PaginationOffsetLimit<Product>>()
  const [page, setPage] = createSignal(0)

  const { selectedShop } = useShop()
  const { setError } = useError()
  const location = useLocation()

  const columns: TableColumn[] = [
    { id: 'period' },
    { id: 'products' },
    { id: 'variant' },
    { id: 'cogs' }
  ]

  const getProductProducts = MainModule.getProductComponent().provideGetProductProducts()

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getProductProducts.execute(
        shop.id,
        props.productCogs!.product.id,
        offset,
        DEFAULT_PAGE_SIZE
      ), location
    )
      .then((listings) => {
        setProducts(listings)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showNoResults = (): boolean => {
    return products()?.values.length === 0
  }

  createEffect(() => {
    if (props.productCogs) {
      setPage(1)
    }
  })

  createEffect(() => {
    if (props.productCogs) {
      refreshTable(page())
    }
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), false, products()?.values)
  }

  const getNumWithOrdersWithoutCogs = (): number => {
    return products()?.values.filter((product) => !product.cogs && !!product.firstOrderDate).length ?? 0
  }

  return (
    <>
      <Switch>
        <Match when={ getNumWithOrdersWithoutCogs() }>{(numMissingCogs) => (
          <MissingCogsBanner numMissingCogs={numMissingCogs()} openCogsEditForm={props.onReconciliate}/>
        )}
        </Match>
        <Match when={ props.productCogs?.cogsStatus === CogsStatus.FirstPeriodAfterFirstOrder}>
          <FirstPeriodAfterFirstOrderBanner openCogsEditForm={props.onReconciliate} numProducts={props.productCogs?.productCount ?? 0}/>
        </Match>
        <Match when={ props.productCogs?.cogsStatus === CogsStatus.CurrentPeriodMismatch}>
          <MismatchCogsBanner period="current" openCogsEditForm={props.onReconciliate}/>
        </Match>
        <Match when={ props.productCogs?.cogsStatus === CogsStatus.PreviousPeriodMismatch}>rt
          <MismatchCogsBanner period="prev" openCogsEditForm={props.onReconciliate}/>
        </Match>
      </Switch>

      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table columns={columns} status={getTableStatus}>
              <Show when={products()?.values}>{(products) => (
                <For each={products()}>{(product) => (
                  <CurrentPeriodCogsTableRow product={product} cogsStatus={props.productCogs!.cogsStatus} />
                )}</For>
              )}</Show>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={products()?.size ?? 0} page={page} setPage={setPage}/>
          </Show>
        </div>
      </div>
    </>
  )
}

export default CogsCurrentPeriod
