import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { A } from '@solidjs/router'
import { type ProductReport } from '../../../features/product/domain/models/product'
import { AppRoutes } from '../../../shared/app-routes'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import MiniTag from '../../../assets/heroicons/MiniTag'

export interface ViewListingProductOnEtsyCellProps {
  readonly product: ProductReport
}

const ViewListingProductOnEtsyCell: Component<ViewListingProductOnEtsyCellProps> = (props) => {
  return (
    <>
      <A href={AppRoutes.Product(props.product.id)}>
        <div class="text-gray-900 flex gap-1 items-center mb-1">
          <MiniTag size={3.5}/><span class="line-clamp-1">{props.product.variantName}</span>
        </div>
      </A>
      <a href={props.product.listing.url} target="_blank" class="text-gray-500 text-xs flex items-center gap-1">
        <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy" />
      </a>
    </>
  )
}

export default ViewListingProductOnEtsyCell
