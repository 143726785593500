import { type Component } from 'solid-js'
import DateCell from '../shared/components/DateCell'
import CogsValueCell from '../shared/components/CogsValueCell'
import { type Cogs } from '../../features/product/domain/models/cogs'
import MiniPencil from '../../assets/heroicons/MiniPencil'
import ImageListingsCell from './ImageListingsCell'
import { type Product } from '../../features/product/domain/models/product'

export interface PeriodHistoryCogsTableRowProps {
  readonly cogs: Cogs
  readonly product: Product
  readonly numListings: number
  readonly nextCogsDate: Date
  readonly onEditCogsPeriod: (cogs: Cogs) => void
}

const PeriodHistoryCogsTableRow: Component<PeriodHistoryCogsTableRowProps> = (props) => {
  return (
    <tr>
      <td class="px-3 py-3.5 text-xs text-gray-900 sm:pl-6 min-w-[152px] md:pl-3">
        <DateCell date={props.cogs.fromDate}/>
      </td>
      <td class="px-3 py-3.5 text-xs text-gray-900 min-w-[152px] !table-cell">
        <DateCell date={props.nextCogsDate}/>
      </td>
      <td class="px-3 py-3.5 text-sm text-gray-900 min-w-[240px] !table-cell">
        <ImageListingsCell product={props.product} numListings={props.numListings} />
      </td>
      <td class="px-3 py-3.5 text-sm text-gray-900 min-w-[145px] !table-cell">
        <CogsValueCell cogs={props.cogs}/>
      </td>
      <td class="px-3 py-3.5 text-sm min-w-[48px] text-gray-500 w-12 !table-cell">
        <button onClick={() => { props.onEditCogsPeriod(props.cogs) }} class="cursor-pointer">
          <MiniPencil size={4}/>
        </button>
      </td>
    </tr>
  )
}

export default PeriodHistoryCogsTableRow
