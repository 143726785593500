import { type KpiEntity } from '../../../analytics/data/entities/kpi.entity'
import { type ListingStatus } from '../../domain/models/listing'
import { type KPIType } from '../../../analytics/domain/models/kpi'

export enum ImageSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ListingType {
  Physical = 'physical',
  Digital = 'digital',
}

export class ListingEntity {
  constructor(
    public readonly listing_id: number,
    public readonly image_urls: Record<ImageSize, string>,
    public readonly title: string,
    public readonly type: ListingType,
    public readonly state: ListingStatus,
    public readonly url: string,
    public readonly updated_timestamp: number,
    public readonly created_timestamp: number
  ) {
  }
}

export class ListingReportEntity {
  constructor (
    public readonly data_points: Record<KPIType, KpiEntity>,
    public readonly listing: ListingEntity,
    public readonly missing_cogs: boolean
  ) {
  }
}
