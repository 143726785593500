import { lastValueFrom, type Observable } from 'rxjs'
import { type DataSource } from './data-source'
import { type Query } from '../query/query'
import { InvalidHttpMethodError, MethodNotImplementedError, QueryNotSupportedError } from '../errors'
import { DataSourceMapper } from './data-source-mapper'
import {ArrayMapper, BlankMapper, JsonDeserializerMapper, Mapper} from '../mapper/mapper'
import { type ApiRequestService } from './api-request.service'
import { HttpMethod, NetworkQuery } from '../query/network.query'
import { type Logger } from '../../helpers/logger/logger'
import { DeviceConsoleLogger } from '../../helpers/logger/device-console.logger'
import { type HttpRequestBuilder } from '../../data/http-request.builder'
import { type Type } from '../../helpers/types'

export class NetworkDataSource implements DataSource<unknown> {
  constructor (
    private readonly requestService: ApiRequestService,
    private readonly logger: Logger = new DeviceConsoleLogger(undefined, 'NetworkDataSource')
  ) {
  }

  public async get (query: Query): Promise<unknown> {
    if (query instanceof NetworkQuery) {
      if (query.method === HttpMethod.Get) {
        return lastValueFrom(this.getRequestWithParameters<unknown>(query).get())
      }
      throw new InvalidHttpMethodError(`Only GET method is allowed in a get action, using ${query.method}`)
    }
    throw new QueryNotSupportedError()
  }

  public async put (value: unknown | undefined, query: Query): Promise<unknown> {
    let request$: Observable<unknown>
    if (query instanceof NetworkQuery) {
      if (query.method === HttpMethod.Post) {
        request$ = this.getRequestWithParameters<unknown>(query).post()
      } else if (query.method === HttpMethod.Put) {
        request$ = this.getRequestWithParameters<unknown>(query, value).put()
      } else if (query.method === HttpMethod.Patch) {
        request$ = this.getRequestWithParameters<unknown>(query, value).patch()
      } else {
        throw new InvalidHttpMethodError(
          `Only POST & PUT methods are allowed in a put action, using ${query.method}`
        )
      }
    } else {
      throw new QueryNotSupportedError()
    }

    return lastValueFrom(request$)
  }

  public async delete (query: Query): Promise<void> {
    if (query instanceof NetworkQuery) {
      if (query.method === HttpMethod.Delete) {
        return lastValueFrom(this.getRequestWithParameters<void>(query).delete())
      }
      throw new InvalidHttpMethodError(`Only DELETE method is allowed in a delete action, using ${query.method}`)
    }

    throw new QueryNotSupportedError()
  }

  public async getAll (_query: Query): Promise<unknown[]> {
    throw new MethodNotImplementedError()
  }

  public async putAll (_values: unknown[] | undefined, _query: Query): Promise<unknown[]> {
    throw new MethodNotImplementedError()
  }

  private getRequestWithParameters<T extends unknown | void>(
    query: NetworkQuery,
    value?: unknown | undefined
  ): HttpRequestBuilder<T> {
    const request = this.requestService
      .builder<T>(query.path)
      .setQueryParameters(query.queryParameters)
      .setCustomHeaders(query.headers)
      .setUrlParameters(query.urlParameters)

    if (value) {
      request.setBody(value)
      if (query.body) {
        this.logger.warn('Both value and query.body are set, using value')
      }
    } else {
      request.setBody(query.body)
    }

    return request
  }
}

export function provideDefaultNetworkDataSource<T extends unknown | void> (requestService: ApiRequestService, type?: Type<T>): DataSource<T> {
  const dataSource = new NetworkDataSource(requestService)
  return new DataSourceMapper(
    dataSource,
    dataSource,
    dataSource,
    (type ? new JsonDeserializerMapper(type) : new BlankMapper<T>()) as Mapper<unknown, T>,
    new BlankMapper<T>()
  )
}

export function provideDefaultArrayNetworkDataSource<T> (
  requestService: ApiRequestService,
  type: Type<T>
): DataSource<T[]> {
  const dataSource = new NetworkDataSource(requestService)
  return new DataSourceMapper(
    dataSource,
    dataSource,
    dataSource,
    (new ArrayMapper(new JsonDeserializerMapper(type))) as Mapper<unknown, T[]>,
    new ArrayMapper(new BlankMapper())
  )
}
