import { type KPIGroupId } from '../../../shared/models/kpi-group'
import { type KPI, type KPIVM, KPIType } from '../../../features/analytics/domain/models/kpi'
import { KPIEntityToKPIMapper } from '../../../features/analytics/domain/mappers/kpi.mappers'
import { type KpiEntity } from '../../../features/analytics/data/entities/kpi.entity'
import { AnalyticsGroups } from '../analytics.vm'

export const getKPIVMsInGroup = (kpis: KPIVM[], groupId: KPIGroupId): KPIVM[] => {
  return AnalyticsGroups[groupId].map((type) => kpis.find(kpi => kpi.id === type) ?? { id: type })
}

export const getKPIsInGroup = (kpis: KPI[], groupId: KPIGroupId): KPI[] => {
  if (!kpis.length) return []
  return AnalyticsGroups[groupId].map((type) => kpis.find(kpi => kpi.id === type)!)
}

export const getNetSalesBreakdownKpis = (kpis: Record<KPIType, KpiEntity>): KPI[] => [
  KPIEntityToKPIMapper(kpis[KPIType.GrossSales], KPIType.GrossSales),
  KPIEntityToKPIMapper(kpis[KPIType.ShippingCollected], KPIType.ShippingCollected),
  KPIEntityToKPIMapper(kpis[KPIType.Discounts], KPIType.Discounts),
  KPIEntityToKPIMapper(kpis[KPIType.Refunds], KPIType.Refunds)
]

export const getOpExpensesBreakdownKpis = (kpis: Record<KPIType, KpiEntity>): KPI[] => [
  KPIEntityToKPIMapper(kpis[KPIType.TransactionFees], KPIType.TransactionFees),
  KPIEntityToKPIMapper(kpis[KPIType.ProcessingFees], KPIType.ProcessingFees),
  KPIEntityToKPIMapper(kpis[KPIType.ListingFees], KPIType.ListingFees),
  KPIEntityToKPIMapper(kpis[KPIType.ShippingCosts], KPIType.ShippingCosts),
  kpis[KPIType.AdCosts] ? KPIEntityToKPIMapper(kpis[KPIType.AdCosts], KPIType.AdCosts) : undefined
].filter(kpi => !!kpi) as KPI[]
