export enum OrderSorting {
  MostRecent = 'most_recent',
  Oldest = 'oldest',
  BiggestChart = 'biggest_chart',
  SmallestChart = 'smallest_chart',
  MostProfitable = 'most_profitable',
  LessProfitable = 'less_profitable',
}

export const OrderFilterSorting = {
  [OrderSorting.MostRecent]: 'created_timestamp:desc',
  [OrderSorting.Oldest]: 'created_timestamp:asc',
  [OrderSorting.BiggestChart]: 'item_count:desc',
  [OrderSorting.SmallestChart]: 'item_count:asc',
  [OrderSorting.MostProfitable]: 'gross_profit:desc',
  [OrderSorting.LessProfitable]: 'gross_profit:asc'
}
