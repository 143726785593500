import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { ProductFilter } from '../../domain/models/product-cost'

export class GetProductCostsQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly startTime: number,
    private readonly endTime: number,
    private readonly prevStartTime: number,
    private readonly offset: number,
    private readonly limit: number,
    private readonly searchTerm?: string,
    private readonly filter?: ProductFilter
  ) {
    super(ApiEndpoint.ProductCosts)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.startTime,
      to_date: this.endTime,
      prev_from_date: this.prevStartTime,
      offset: this.offset,
      limit: this.limit,
      ...this.searchTerm && { search: this.searchTerm },
      ...(this.filter && this.filter !== ProductFilter.All) && { products_filter: this.filter }
    }
  }
}
