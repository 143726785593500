import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetOfferingQuery } from '../../data/queries/get-offering.query'
import { type Offering } from '../models/offering'

export class GetOfferingInteractor {
  constructor(
    private readonly getOffering: GetInteractor<Offering>
  ) {
  }

  public async execute(offer: string): Promise<Offering> {
    return await this.getOffering.execute(new GetOfferingQuery(offer))
  }
}
