import type { HttpParameters } from '@coris-ts/data/parameter-type'
import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetOfferingQuery extends GetNetworkQuery {
  constructor (
    private readonly offer: string
  ) {
    super(ApiEndpoint.PaddleOffering)
  }

  public get queryParameters(): HttpParameters {
    return {
      ...this.offer && { product_type: this.offer }
    }
  }

  public get headers(): Record<string, string> {
    return {
      'X-Pt-Api-Key': import.meta.env.VITE_PT_API_KEY
    }
  }
}
