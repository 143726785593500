import { type Component, createEffect, createSignal, onCleanup, onMount, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Search from '../../../assets/images/search.svg'
import { useShop } from '../../../shared/providers/shop.provider'
import styles from './FetchingShopDataBanner.module.scss'
import { MainModule } from '../../../features/main-module'
import { type Shop } from '../../../features/shop/domain/models/shop'

const FetchingShopDataBanner: Component = () => {
  const { selectedShop, setSelectedShop } = useShop()

  const getShop = MainModule.getShopComponent().provideGetShop()
  const [height, setHeight] = createSignal('auto')
  const [tempShop, setTempShop] = createSignal<Shop>()

  let bannerRef: HTMLDivElement

  const fetchingStateHasChanged = (shop: Shop): boolean => {
    return shop.firstFetch !== selectedShop()?.firstFetch || shop.fullFetch !== selectedShop()?.fullFetch
  }

  const closeBanner = (): void => {
    setHeight(`${bannerRef.clientHeight}px`)
    setTimeout(() => {
      setHeight('0px')
    }, 200)
  }

  const fetchShop = async (): Promise<Shop | undefined> => {
    const shopId = selectedShop()?.id
    if (!shopId) return

    const shop = await getShop.execute(shopId)
    setTempShop(shop)
    if (fetchingStateHasChanged(shop)) {
      if (shop.isFullyFetched) {
        setTimeout(() => {
          closeBanner()
        }, 5000)
      } else {
        setSelectedShop(tempShop()!)
      }
    }
    return shop
  }

  onMount(() => {
    setTempShop(selectedShop())
  })

  createEffect(() => {
    if (selectedShop()?.isFullyFetched) {
      return
    }

    const intervalId = setInterval(async (): Promise<void> => {
      const shop = await fetchShop()
      if (shop?.isFullyFetched) {
        clearInterval(intervalId)
      }
    }, 2000)

    onCleanup(() => { clearInterval(intervalId) })
  })

  const getText = (type: 'title' | 'description'): string => tempShop()?.isFullyFetched
    ? `ls_shop_fetch_${type}_completed`
    : tempShop()?.isFirstFetchReady
      ? `ls_shop_fetch_${type}_step2`
      : `ls_shop_fetch_${type}_step1`

  return (
    <Show when={selectedShop() && !selectedShop()?.isFullyFetched}>
      <div
        ref={(el) => { bannerRef = el }}
        onTransitionEnd={() => { setSelectedShop(tempShop()!) }}
        style={{ height: height() }}
        class={`${height() === '0px' ? 'py-0' : 'py-2'} transition-all duration-500 overflow-hidden text-white relative flex gap-4 items-center bg-cyan-900 justify-between flex-col xl:flex-row px-8 xl:px-4`}
      >
        <img alt="wait" src={Search} class="absolute hidden sm:block sm:w-36 xl:w-28 sm:top-2 sm:-left-5 xl:top-auto xl:bottom-0 xl:left-4"/>
        <div class="flex flex-col xl:flex-row xl:items-center sm:pl-24 xl:pl-32 gap-4 w-full">
          <div class="flex flex-col gap-2 justify-center w-full">
            <div class="flex flex-col">
              <p class="text-md m-0 font-semibold">
                <Trans key={getText('title')}/>
              </p>
              <p class="text-sm m-0">
                <Trans key={getText('description')}/>
              </p>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
              <div
                class={`${tempShop()?.isFirstFetchReady ? styles.progressBarDone : styles.progressBar} sm:w-1/4 flex items-center px-3`}>
                <span class="text-xs text-white z-10 font-semibold">
                  <Trans key="ls_shop_past_15_days"/>
                </span>
                <div class={styles.progressValue}></div>
              </div>
              <div
                class={`${tempShop()?.isFullyFetched ? styles.progressBarDone : styles.progressBar} sm:w-3/4 flex items-center px-3`}>
                <span class="text-xs text-white z-10 font-semibold">
                  <Trans key="ls_shop_complete_history"/>
                </span>
                <div class={styles.progressValue}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Show>
  )
}

export default FetchingShopDataBanner
