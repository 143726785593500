import { onMount, createSignal, onCleanup, type JSX } from 'solid-js'
import { render } from 'solid-js/web'
import { type Size } from '../../../shared/helpers/dom.functions'

export const useMeasureComponent = (element: JSX.Element): () => Size => {
  const [dimensions, setDimensions] = createSignal<Size>({ width: 0, height: 0 })
  let container: HTMLDivElement | undefined

  onMount(() => {
    container = document.createElement('div')
    container.style.position = 'absolute'
    container.style.visibility = 'hidden'
    container.style.pointerEvents = 'none'
    container.style.zIndex = '-1'
    document.body.appendChild(container)

    render(() => element, container)

    requestAnimationFrame(() => {
      if (container?.firstChild) {
        const rect = (container.firstChild as HTMLElement).getBoundingClientRect()
        setDimensions({ width: rect.width, height: rect.height })
        document.body.removeChild(container)
      }
      container = undefined
    })
  })

  onCleanup(() => {
    if (container) {
      document.body.removeChild(container)
    }
  })

  return dimensions
}
