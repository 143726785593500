import { type Component, Show, createEffect, createSignal, For, onCleanup } from 'solid-js'
import ProductFilters from '../shared/components/ProductFilters'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import { type ListingReport, ListingStatus } from '../../features/product/domain/models/listing'
import MultiSelect from '../shared/components/MultiSelect'
import ListingsTableRow from './ListingsTableRow'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { ProductSorting, ProductFilterSorting } from '../../features/shared/product-sorting'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { KPIType } from '../../features/analytics/domain/models/kpi'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'
import { MOCKED_LISTINGS } from './MockedListings'
import LockedListingsDialog from '../shared/components/LockedListingsDialog'

const ListingsLocked: Component = () => {
  const [statusFilter, setStatusFilter] = createSignal<ListingStatus[]>([])
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [listings, setListings] = createSignal<PaginationOffsetLimit<ListingReport>>()
  const [page, setPage] = createSignal(0)

  createEffect(() => {
    document.body.style.overflow = 'hidden'

    onCleanup(() => {
      document.body.style.overflow = ''
    })
  })

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'listing' },
    { id: 'status' },
    { id: KPIType.Orders, withHint: true, sortable: true },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true },
    { id: KPIType.BreakEvenRoas, withHint: true, sortable: true }
  ]

  const refreshTable = (_: number = 1): void => {
    setListings({
      offset: 0,
      limit: 15,
      size: 15,
      values: MOCKED_LISTINGS
    })
  }

  const showNoResults = (): boolean => {
    return listings()?.values.length === 0
  }

  const isFiltering = (): boolean => {
    return !!(statusFilter().length || searchTerm())
  }

  createEffect(() => {
    orderBy()
    statusFilter()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(false, showNoResults(), isFiltering(), listings()?.values)
  }

  return (
    <div class="relative">
      <LockedListingsDialog />
      <div class="flex flex-col gap-4">
        <h1 class="page-title"><RectangleStackSolid /><Trans key="ls_listings_title"/></h1>
        <div class="hidden sm:block">
          <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title" extraFilter={
            () => <MultiSelect setSelectedOptions={setStatusFilter} options={ListingStatus} placeholder="ls_generic_status" name="ls_listing_status" />
          }/>
        </div>
        <div class="block sm:hidden">
          <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title" />
          <div class="relative mt-2 rounded-md shadow-sm flex bg-white items-center py-1">
            <MultiSelect setSelectedOptions={setStatusFilter} options={ListingStatus} width="full" placeholder="ls_generic_status" name="ls_listing_status"/>
          </div>
        </div>
        <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={() => false}/>
        <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
          <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
              <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={getTableStatus} tableId="listings">
                <For each={listings()?.values}>{(listing) => (
                  <ListingsTableRow listing={listing} isLoading={() => false}/>
                )}</For>
              </Table>
            </div>
            <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
              <TablePagination totalCount={listings()?.size ?? 0} page={page} setPage={setPage} />
            </Show>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingsLocked
