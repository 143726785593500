export class SubscriptionChangeEntity {
  constructor(
    public readonly amount_due_today: number,
    public readonly credit_to_balance: number,
    public readonly currency: string,
    public readonly credit_used: number,
    public readonly prorated_cost: number
  ) {
  }
}
