import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type Product } from '../models/product'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetProductProductsQuery } from '../../data/queries/get-product-products.query'

export class GetProductProductsInteractor {
  constructor(
    private readonly getProductProducts: GetInteractor<PaginationOffsetLimit<Product>>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number,
    offset: number,
    limit: number
  ): Promise<PaginationOffsetLimit<Product>> {
    return await this.getProductProducts.execute(
      new GetProductProductsQuery(shopId, productId, offset, limit)
    )
  }
}
