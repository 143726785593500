import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'

export enum ButtonStyle {
  Primary,
  Secondary,
  White,
  Etsy,
  Red
}

export interface ButtonProps {
  readonly style: ButtonStyle
  readonly key: string
  readonly action: () => void
  readonly disabled?: boolean
}

const Button: Component<ButtonProps> = (props) => {
  let btnClass = 'btn--primary'

  switch (props.style) {
    case ButtonStyle.Secondary:
      btnClass = 'btn--secondary'
      break
    case ButtonStyle.White:
      btnClass = 'btn--white'
      break
    case ButtonStyle.Etsy:
      btnClass = 'btn--etsy'
      break
    case ButtonStyle.Red:
      btnClass = 'btn--red'
      break
  }

  return (
    <button onClick={props.action} class={`btn ${btnClass} min-w-fit`} disabled={props.disabled}>
      <Trans key={props.key} />
    </button>
  )
}

export default Button
