import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import NoResults from '../../../assets/images/no-results.png'

export interface TableNoResultsProps {
  readonly type: 'no_search_results' | 'no_data'
  readonly id?: string
}

const TableNoResults: Component<TableNoResultsProps> = (props) => {
  const TranslationPrefix = props.type === 'no_search_results'
    ? 'ls_shared_no_results_search'
    : 'ls_shared_no_data'

  const descriptionKey = props.id
    ? `${TranslationPrefix}_${props.id}_description`
    : `${TranslationPrefix}_description`

  return (
    <div class="flex flex-col items-center justify-center gap-1 p-10">
      <img class="h-28" src={NoResults} alt="no-results" />
      <p class="text-base font-semibold"><Trans key={TranslationPrefix + '_title'} /></p>
      <p class="text-sm"><Trans key={descriptionKey} /></p>
    </div>
  )
}

export default TableNoResults
