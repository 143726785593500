import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Alarm from '../../assets/images/alarm.svg'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import { type IntegrationType } from '../../features/shop/domain/models/shop'

export interface WrongStoreDialogProps {
  readonly cancel: () => void
  readonly confirm: () => void
  readonly integration: IntegrationType
}

const WrongStoreDialog: Component<WrongStoreDialogProps> = (props) => {
  const integrationName = props.integration[0].toUpperCase() + props.integration.slice(1).toLowerCase()
  return (
    <Dialog maxWidth={'434px'}>
      <div class="flex gap-4 p-6 mx-auto">
        <img src={Alarm} class="w-10 h-10" alt="question"/>
        <div class="text-black flex gap-2 flex-col">
          <span class="text-base text-gray-900 font-bold"><Trans key="ls_integrations_disconnect"/></span>
          <span class="text-sm text-gray-500"><Trans key="ls_integrations_disconnect_description" options={{ integration: integrationName }}/></span>
        </div>
      </div>

      <DialogActions>
        <button onClick={props.cancel} class="btn btn--white">
          <Trans key="ls_generic_cancel"/>
        </button>
        <button onClick={props.confirm} class="btn btn--primary">
          <Trans key="ls_generic_disconnect"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default WrongStoreDialog
