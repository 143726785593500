import { type Component } from 'solid-js'

export enum KPIGroupId {
  Main = 'main',
  Performance = 'performance',
  Orders = 'orders',
  ListingOrders = 'listing_orders',
  Shipping = 'shipping',
  Advertisement = 'advertisement',
  ListingAdvertisement = 'listing_advertisement',
  FinancialChart = 'financial_chart',
  FinancialDashboard = 'financial_dashboard',
  NetSales = 'net_sales',
  Cogs = 'cogs',
  OperatingExpenses = 'operating_expenses'
}

export interface KPIGroupConfig {
  readonly id: string
  readonly icon: Component
}

export type KPIGroupsConfig = Partial<Record<KPIGroupId, KPIGroupConfig>>
