import type { Component } from 'solid-js'
import { type IconProps } from './icon'

const MiniEtsy: Component<IconProps> = (props) => {
  return (
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class={`w-${props.size ?? 4} h-${props.size ?? 4}`}>
          <path d="M15.4 1H4.6C2.62 1 1 2.62 1 4.6V15.4C1 17.38 2.62 19 4.6 19H15.4C17.38 19 19 17.38 19 15.4V4.6C19 2.62 17.38 1 15.4 1ZM14.23 15.4C14.23 15.4 12.16 15.31 11.26 15.31H7.3L4.96 15.4V14.77L5.77 14.59C6.31 14.5 6.4 14.41 6.4 13.96C6.4 13.96 6.49 12.52 6.49 10.09C6.49 7.66 6.4 6.13 6.4 6.13C6.4 5.68 6.22 5.59 5.68 5.5L4.96 5.32V4.69L7.3 4.78H11.71C12.61 4.78 14.05 4.6 14.05 4.6C14.05 4.6 13.96 5.5 13.96 7.66H13.33L13.15 6.94C12.88 5.95 12.52 5.5 11.89 5.5H8.47C8.2 5.5 8.2 5.5 8.2 5.68V9.64C8.2 9.64 9.64 9.64 10.36 9.55C10.72 9.64 11.17 9.28 11.17 8.92V8.83L11.35 7.93H11.98L11.89 9.82L11.98 11.8H11.35L11.17 11.08C11.08 10.63 10.72 10.36 10.36 10.36C9.82 10.27 8.2 10.27 8.2 10.27V13.6C8.2 14.23 8.56 14.5 9.28 14.5H11.62C12.43 14.59 13.24 14.14 13.51 13.42L14.14 12.16H14.68C14.59 12.52 14.32 14.86 14.23 15.4Z" fill="currentColor"/>
      </svg>
  )
}

export default MiniEtsy
