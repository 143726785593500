import type { Component } from 'solid-js'

export interface DateCellProps {
  readonly date: Date
}

const DateCell: Component<DateCellProps> = (props) => {
  return (
    <span class="text-sm text-gray-900">{
      new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      }).format(props.date)
    }</span>
  )
}

export default DateCell
