import type { Component } from 'solid-js'
import type { IconProps } from './icon'

const PlusSM: Component<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class={`w-${props.size ?? 5} h-${props.size ?? 5}`} viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"/>
    </svg>
  )
}

export default PlusSM
