import { type Component, Show, createEffect, createSignal, For } from 'solid-js'
import ProductFilters from '../shared/components/ProductFilters'
import { MainModule } from '../../features/main-module'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import { type ListingReport, ListingStatus } from '../../features/product/domain/models/listing'
import MultiSelect from '../shared/components/MultiSelect'
import ListingsTableRow from './ListingsTableRow'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { ProductSorting, ProductFilterSorting } from '../../features/shared/product-sorting'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { KPIType } from '../../features/analytics/domain/models/kpi'
import { useError } from '../../shared/providers/error.provider'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { useLocation } from '@solidjs/router'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'

const Listings: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [statusFilter, setStatusFilter] = createSignal<ListingStatus[]>([])
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [listings, setListings] = createSignal<PaginationOffsetLimit<ListingReport>>()
  const [page, setPage] = createSignal(0)

  const { selectedShop } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()
  const location = useLocation()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'listing' },
    { id: 'status' },
    { id: KPIType.Orders, withHint: true, sortable: true },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true },
    { id: KPIType.BreakEvenRoas, withHint: true, sortable: true }
  ]

  const getListings = MainModule.getProductComponent().provideGetListingReports()

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getListings.execute(
        shop.id,
        periods(),
        offset,
        DEFAULT_PAGE_SIZE,
        statusFilter(),
        orderBy(),
        searchTerm()
      ), location
    )
      .then((listings) => {
        setListings(listings)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showNoResults = (): boolean => {
    return listings()?.values.length === 0
  }

  const isFiltering = (): boolean => {
    return !!(statusFilter().length || searchTerm())
  }

  createEffect(() => {
    orderBy()
    statusFilter()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), isFiltering(), listings()?.values)
  }

  return (
    <div class="flex flex-col gap-4">
      <h1 class="page-title"><RectangleStackSolid /><Trans key="ls_listings_title"/></h1>
      <div class="hidden sm:block">
        <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title" extraFilter={
          () => <MultiSelect setSelectedOptions={setStatusFilter} options={ListingStatus} placeholder="ls_generic_status" name="ls_listing_status" />
        }/>
      </div>
      <div class="block sm:hidden">
        <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title" />
        <div class="relative mt-2 rounded-md shadow-sm flex bg-white items-center py-1">
          <MultiSelect setSelectedOptions={setStatusFilter} options={ListingStatus} width="full" placeholder="ls_generic_status" name="ls_listing_status"/>
        </div>
      </div>
      <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={isLoading}/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={getTableStatus} tableId="listings">
              <For each={listings()?.values}>{(listing) => (
                <ListingsTableRow listing={listing} isLoading={isLoading}/>
              )}</For>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={listings()?.size ?? 0} page={page} setPage={setPage} />
          </Show>
        </div>
      </div>
    </div>
  )
}

export default Listings
