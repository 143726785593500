import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type UserEntity } from '../../data/entities/user.entity'
import { User } from '../models/user'

export class UserEntityToUserMapper implements Mapper<UserEntity, User> {
  public map(from: UserEntity): User {
    return new User(
      from.id,
      from.email,
      from.email_verified,
      from.name,
      from.picture_url,
      from.customer_id,
      from.intercom_hash
    )
  }
}
