import { type DeleteInteractor } from '@coris-ts/repository/interactor/delete.interactor'
import { DisconnectIntegrationQuery } from '../../data/queries/disconnect-integration.query'
import { type IntegrationType } from '../models/shop'

export class DisconnectIntegrationInteractor {
  constructor (
    private readonly disconnectIntegration: DeleteInteractor
  ) {}

  public async execute(shopId: number, integration: IntegrationType): Promise<void> {
    await this.disconnectIntegration.execute(new DisconnectIntegrationQuery(shopId, integration))
  }
}
