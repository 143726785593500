import { type Component } from 'solid-js'
import { useError } from '../../../shared/providers/error.provider'
import { MainModule } from '../../../features/main-module'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import { Trans } from '@mbarzda/solid-i18next'

const NoEtsyShopError: Component = () => {
  const { setError } = useError()

  const getEtsyAuthUrl = MainModule.getShopComponent().provideGetEtsyAuthUrl()
  const onActionAddNewShop = async (): Promise<void> => {
    try {
      window.location.href = await getEtsyAuthUrl.execute()
    } catch (error) {
      console.error(error)
      setError(true)
    }
  }

  return (
    <button type="button"
            onClick={onActionAddNewShop}
            class="flex items-center justify-center gap-2 w-full rounded-md !bg-etsy-500 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:!bg-etsy-600 transition duration-200">
      <MiniEtsy/><Trans key="ls_no_shop_connect"/>
    </button>
  )
}

export default NoEtsyShopError
