import { PutNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpBody, type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type Cogs } from '../../domain/models/cogs'

export class UpdateCogsHistoricQuery extends PutNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly productId: number,
    private readonly historic: Cogs[]
  ) {
    super(ApiEndpoint.ProductCogsHistory)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      productId: this.productId
    }
  }

  public get body(): HttpBody {
    return this.historic.map((cogs) => ({
      from_date: Math.floor(cogs.fromDate.getTime() / 1000),
      amount_per_unit: cogs.amount,
      currency: cogs.currency
    }))
  }
}
