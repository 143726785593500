import { type CogsStatus } from './product-cost'
import { type Product } from './product'
import { type Cogs } from './cogs'

export class ProductCogs {
  constructor (
    public readonly sku: string | undefined,
    public readonly cogsStatus: CogsStatus,
    public readonly product: Product,
    public readonly productCount: number,
    public readonly cogsHistory: Cogs[],
    public readonly firstOrderDate?: Date
  ) {
  }
}
