import { type FetchStatus, type IntegrationType } from '../../domain/models/shop'

export class IntegrationEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly fetch_status: FetchStatus,
    public readonly invalid_token: boolean
  ) {
  }
}

export class ShopEntity {
  constructor (
    public readonly shop_id: number,
    public readonly name: string,
    public readonly url: string,
    public readonly image_url: string,
    public readonly first_fetch: FetchStatus,
    public readonly full_fetch: FetchStatus,
    public readonly invalid_token: boolean,
    public readonly integrations: Record<IntegrationType, IntegrationEntity | undefined>,
    public readonly currency_code: string,
    public readonly last_listing_fetch_timestamp?: number,
    public readonly last_report_fetch_timestamp?: number
  ) {}
}
