import { type Cogs } from '../models/cogs'
import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type CogsEntity } from '../../data/entities/cogs.entity'
import { ProductCogs } from '../models/product-cogs'
import { type ProductCogsEntity } from '../../data/entities/product-cogs.entity'
import { type ProductEntity } from '../../data/entities/product.entity'
import { type Product } from '../models/product'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'

export class ProductCogsEntityToProductCogsMapper implements Mapper<ProductCogsEntity, ProductCogs> {
  constructor(
    private readonly productEntityToProductMapper: Mapper<ProductEntity, Product>,
    private readonly cogsEntityToCogsMapper: Mapper<CogsEntity, Cogs>
  ) {
  }

  public map(from: ProductCogsEntity): ProductCogs {
    return new ProductCogs(
      from.sku,
      from.cogs_status,
      this.productEntityToProductMapper.map(from.product),
      from.number_of_products,
      from.cogs_historic ? from.cogs_historic.map(cogs => this.cogsEntityToCogsMapper.map(cogs)) : [],
      mapTimestampToDate(from.first_order_timestamp)
    )
  }
}
