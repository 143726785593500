import { TableStatus } from '../components/Table'

export const getGenericTableStatus = <T>(
  isLoading: boolean,
  noResults: boolean,
  isFiltering: boolean,
  data?: T[]
): TableStatus => {
  if (isLoading) {
    if (data?.length) {
      return TableStatus.Loading
    }

    return TableStatus.Init
  }

  if (noResults) {
    if (isFiltering) {
      return TableStatus.NoSearchResults
    }

    return TableStatus.NoData
  }

  return TableStatus.Loaded
}
