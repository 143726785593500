import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { KPIType } from '../models/kpi'
import { type AnalyticsEntity } from '../../data/entities/analytics.entity'
import { KPIEntityToKPIMapper } from './kpi.mappers'
import {
  getNetSalesBreakdownKpis,
  getOpExpensesBreakdownKpis
} from '../../../../private/shared/functions/kpi.functions'
import { Analytics } from '../models/analytics'

export class AnalyticsEntityToAnalyticsMapper implements Mapper<AnalyticsEntity, Analytics> {
  public map(from: AnalyticsEntity): Analytics {
    const KPIS = from.data_points
    const UnitsSold = KPIEntityToKPIMapper(KPIS[KPIType.UnitsSold], KPIType.UnitsSold)

    const kpis = Object.keys(from.data_points).map(
      (key) => {
        const breakdown = key === KPIType.NetSales
          ? getNetSalesBreakdownKpis(KPIS)
          : key === KPIType.OperatingExpenses
            ? getOpExpensesBreakdownKpis(KPIS)
            : undefined

        return KPIEntityToKPIMapper(KPIS[key as KPIType], key as KPIType, breakdown, UnitsSold)
      }
    )

    return new Analytics(kpis, from.missing_cogs)
  }
}
