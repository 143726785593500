import { type Component, createSignal } from 'solid-js'
import Dialog from '../shared/components/Dialog/Dialog'
import { Trans } from '@mbarzda/solid-i18next'
import DialogActions from '../shared/components/Dialog/DialogActions'
import Button, { ButtonStyle } from '../shared/components/Button'
import InformationCircle from '../../assets/heroicons/InformationCircle'
import { useUser } from '../../public/auth/user-provider'
import { MainModule } from '../../features/main-module'

export interface CannotChangePlanDialogProps {
  readonly close: () => void
  readonly subscriptionReactivated: () => void
}

const CannotChangePlanDialog: Component<CannotChangePlanDialogProps> = (props) => {
  const [isLoading, setIsLoading] = createSignal<boolean>(false)
  const { currentSubscription } = useUser()

  const reactivateSubscription = MainModule.getPaymentComponent().provideReactivateSubscription()

  const onActionReactivateSubscription = async (): Promise<void> => {
    setIsLoading(true)
    await reactivateSubscription.execute(currentSubscription()!.id)
    props.subscriptionReactivated()
    props.close()
  }

  return (
    <Dialog maxWidth={'606px'}>
      <div class="flex gap-4 p-6">
        <span class="text-blue-700 flex items-center justify-center min-w-[40px] h-10 rounded-full bg-blue-100">
          <InformationCircle />
        </span>
        <div class="flex flex-col gap-2">
          <span class="text-gray-900 font-bold">
            <Trans key="ls_subscription_cannot_change_title"/>
          </span>
          <span class="text-sm text-gray-500">
            <Trans key="ls_subscription_cannot_change_description"/>
          </span>
        </div>
      </div>
      <DialogActions>
        <Button action={props.close} key="ls_generic_cancel" style={ButtonStyle.White}/>
        <Button disabled={isLoading()} action={onActionReactivateSubscription} key="ls_generic_reactivate" style={ButtonStyle.Primary}/>
      </DialogActions>
    </Dialog>
  )
}

export default CannotChangePlanDialog
