import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Happy from '../../../assets/images/happy.svg'

const NoMissingCOGS: Component = () => {
  return (
    <div class="flex flex-col items-center justify-center gap-1 p-10">
      <img class="h-28" src={Happy} alt="well-done" />
      <p class="font-semibold"><Trans key="ls_cogs_issues_well_done" /></p>
    </div>
  )
}

export default NoMissingCOGS
