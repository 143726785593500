import type { Component } from 'solid-js'
import { createEffect, createSignal, For, Show } from 'solid-js'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { MainModule } from '../../features/main-module'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import { type SKUProductReport } from '../../features/product/domain/models/product'
import ProductsTableRow from './ProductsTableRow'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import ProductFilters from '../shared/components/ProductFilters'
import { KPIType } from '../../features/analytics/domain/models/kpi'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { useError } from '../../shared/providers/error.provider'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { ProductSorting, ProductFilterSorting } from '../../features/shared/product-sorting'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { useLocation } from '@solidjs/router'
import ShoppingBagSolid from '../../assets/heroicons/ShoppingBagSolid'

const Products: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [products, setProducts] = createSignal<PaginationOffsetLimit<SKUProductReport>>()
  const [page, setPage] = createSignal(0)

  const { selectedShop } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()
  const location = useLocation()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'sku' },
    { id: 'listing' },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true }
  ]

  const getProducts = MainModule.getProductComponent().provideGetSkuProductReports()

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE
    executeWithUrlScope(
      async () => await getProducts.execute(
        shop.id,
        periods(),
        offset,
        DEFAULT_PAGE_SIZE,
        orderBy(),
        searchTerm()
      ), location
    )
      .then((products) => {
        setProducts(products)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showNoResults = (): boolean => {
    return products()?.values.length === 0
  }

  createEffect(() => {
    orderBy()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), !!searchTerm(), products()?.values)
  }

  return (
    <div class="flex flex-col gap-4">
      <h1 class="page-title"><ShoppingBagSolid /><Trans key="ls_products_title"/></h1>
      <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_products_search_title" />
      <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={isLoading}/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-scroll w-full">
            <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={getTableStatus} tableId="products">
              <For each={products()?.values}>{(product) => (
                <ProductsTableRow product={product}/>
              )}</For>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={products()?.size ?? 0} page={page} setPage={setPage}/>
          </Show>
        </div>
      </div>
    </div>
  )
}

export default Products
