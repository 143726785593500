import type { Timeseries } from '../../../features/analytics/domain/models/timeseries'
import {
  TimeseriesEntityToTimeseriesMapper
} from '../../../features/analytics/domain/mappers/timeseries.mappers'
import { type TimeseriesEntity } from '../../../features/analytics/data/entities/timeseries.entity'

export class MockedTimeSeriesInteractor {
  public async execute(): Promise<Timeseries> {
    const mapper = new TimeseriesEntityToTimeseriesMapper()
    const mockedEntities: TimeseriesEntity = {
      resolution: 'day',
      date_interval: {
        from_date: 1720648800,
        to_date: 1721253600
      },
      points: [
        {
          date: 1720648800,
          date_label: '2024-07-11',
          // @ts-expect-error
          data_points: {
            net_sales: 25,
            cogs: 0,
            operating_expenses: 0,
            operating_profit: 0,
            profit_margin: 0,
            roi: 0,
            units_sold: 0,
            orders: 0,
            break_even_roas: 0
          }
        },
        {
          date: 1720735200,
          date_label: '2024-07-12',
          // @ts-expect-error
          data_points: {
            net_sales: 0,
            cogs: 0,
            operating_expenses: 0,
            operating_profit: 0,
            profit_margin: 0,
            roi: 0,
            units_sold: 0,
            orders: 0,
            break_even_roas: 0
          }
        },
        {
          date: 1720821600,
          date_label: '2024-07-13',
          // @ts-expect-error
          data_points: {
            net_sales: 38.65,
            cogs: 0,
            operating_expenses: -35.95,
            operating_profit: 2.7,
            profit_margin: 0.07,
            roi: 0.08,
            units_sold: 6,
            orders: 1,
            break_even_roas: 14.31
          }
        },
        {
          date: 1720908000,
          date_label: '2024-07-14',
          // @ts-expect-error
          data_points: {
            net_sales: 0,
            cogs: 0,
            operating_expenses: 0,
            operating_profit: 0,
            profit_margin: 0,
            roi: 0,
            units_sold: 0,
            orders: 0,
            break_even_roas: 0
          }
        },
        {
          date: 1720994400,
          date_label: '2024-07-15',
          // @ts-expect-error
          data_points: {
            net_sales: 0,
            cogs: 0,
            operating_expenses: 0,
            operating_profit: 0,
            profit_margin: 0,
            roi: 0,
            units_sold: 0,
            orders: 0,
            break_even_roas: 0
          }
        },
        {
          date: 1721080800,
          date_label: '2024-07-16',
          // @ts-expect-error
          data_points: {
            net_sales: 0,
            cogs: 0,
            operating_expenses: 0,
            operating_profit: 0,
            profit_margin: 0,
            roi: 0,
            units_sold: 0,
            orders: 0,
            break_even_roas: 0
          }
        },
        {
          date: 1721167200,
          date_label: '2024-07-17',
          // @ts-expect-error
          data_points: {
            net_sales: 14.33,
            cogs: 0,
            operating_expenses: -11.98,
            operating_profit: 2.35,
            profit_margin: 0.16,
            roi: 0.2,
            units_sold: 2,
            orders: 1,
            break_even_roas: 6.1
          }
        }
      ]
    }
    return mapper.map(mockedEntities)
  }
}
