import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type SortDirection } from '../../../../shared/models/sort-direction'

export class GetSkuProductReportsQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly startTime: number,
    private readonly endTime: number,
    private readonly prevStartTime: number,
    private readonly offset: number,
    private readonly limit: number,
    private readonly searchTerm?: string,
    private readonly sortBy?: string,
    private readonly sortDirection?: SortDirection
  ) {
    super(ApiEndpoint.Products)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.startTime,
      to_date: this.endTime,
      prev_from_date: this.prevStartTime,
      offset: this.offset,
      limit: this.limit,
      ...this.searchTerm && { search: this.searchTerm },
      ...this.sortBy && { sort_by: this.sortBy },
      ...this.sortDirection && { sort_order: this.sortDirection }
    }
  }
}
