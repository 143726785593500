import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type SubscriptionEntity } from '../../data/entities/subscription.entity'
import { Subscription, SubscriptionStatus } from '../models/subscription'
import { mapTimestampToDate } from '../../../shared/map-timestamp-to-date'
import { type OfferingProductEntityToOfferingProductMapper } from './offering.mappers'

export class SubscriptionEntityToSubscriptionMapper implements Mapper<SubscriptionEntity, Subscription> {
  constructor(
    public readonly offeringProductMapper: OfferingProductEntityToOfferingProductMapper
  ) {}

  private mapSubscriptionStatus(from: SubscriptionEntity): SubscriptionStatus {
    if ((from.status === SubscriptionStatus.Active || from.status === SubscriptionStatus.Trialing) && !from.next_payment_timestamp) {
      return SubscriptionStatus.Canceled
    }
    if (from.status === SubscriptionStatus.Canceled) {
      return SubscriptionStatus.Inactive
    }
    return from.status
  }

  public map(from: SubscriptionEntity): Subscription {
    return new Subscription(
      from.id,
      this.mapSubscriptionStatus(from),
      this.offeringProductMapper.map(from.product_price),
      mapTimestampToDate(from.next_payment_timestamp),
      mapTimestampToDate(from.end_billing_period_timestamp),
      mapTimestampToDate(from.trial_ends_timestamp),
      from.manage_url
    )
  }
}
