import { createContext, type JSX, useContext } from 'solid-js'
import {
  createAuth0Client,
  type Auth0Client,
  type Auth0ClientOptions, type RedirectLoginResult
} from '@auth0/auth0-spa-js'

export interface AuthContextType {
  readonly auth: Auth0Client
}

export const auth0Config = (queryString?: string): Auth0ClientOptions => ({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}${queryString ?? ''}`,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE
  }
})

export const auth0Client: Auth0Client = await createAuth0Client(auth0Config())

const defaultAuthContext: AuthContextType = {
  auth: auth0Client
}

const AuthContext = createContext<AuthContextType>(defaultAuthContext)

export const AuthProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <AuthContext.Provider value={defaultAuthContext}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}

export type HandlerAuthentication = () => Promise<RedirectLoginResult<unknown> | undefined>
export const useHandleAuthentication = (): HandlerAuthentication => {
  const { auth } = useAuth()

  return async () => {
    const query = window.location.search
    if (query.includes('code=') && query.includes('state=')) {
      return await auth.handleRedirectCallback()
    }
  }
}
