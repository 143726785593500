import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type HttpParameters } from '@coris-ts/data/parameter-type'

export class EtsyAuthUrlQuery extends PostNetworkQuery {
  constructor (private readonly redirectUrl: string) {
    super(ApiEndpoint.EtsyConnect)
  }

  public get queryParameters(): HttpParameters {
    return {
      redirect_url: this.redirectUrl
    }
  }
}
