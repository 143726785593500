import { type Subscription } from '../models/subscription'
import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { UpdateSubscriptionQuery } from '../../data/queries/update-subscription.query'

export class UpdateSubscriptionInteractor {
  constructor(
    private readonly updateSubscription: PutInteractor<Subscription>
  ) {
  }

  public async execute(subscriptionId: string, priceId: string): Promise<Subscription> {
    return await this.updateSubscription.execute(undefined, new UpdateSubscriptionQuery(subscriptionId, priceId))
  }
}
