import { type ApexOptions } from 'apexcharts'
import { type KPI } from '../../features/analytics/domain/models/kpi'
import { t } from 'i18next'
import { KpiColor } from './FinancialBreakdownChart'
import colors from 'tailwindcss/colors'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export const getFinancialBreakdownChartVM = (costs: KPI[], total: KPI, totalValueLabel: string = ''): ApexOptions => {
  const { currencyFormatter, percentageFormatter } = useNumberFormatter()

  return {
    series: costs.map(cost => cost.value ? Math.abs(cost.value) : 0),
    colors: Object.values(KpiColor).map(color => {
      const colorParts = color.split('-')
      // @ts-expect-error we must be using tailwind colors
      return colors[colorParts[0]][colorParts[1]]
    }),
    chart: {
      type: 'donut',
      width: '100%'
    },
    dataLabels: {
      enabled: false
    },
    labels: costs.map(kpi => {
      return t(`ls_fin_break_${kpi.id}`)
    }),
    legend: {
      show: false
    },
    responsive: [{
      breakpoint: 640,
      options: {
        chart: {
          width: '100%'
        },
        legend: {
          position: 'bottom'
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  fontSize: '12px'
                },
                value: {
                  fontSize: '16px'
                }
              }
            }
          }
        },
        grid: {
          padding: {
            top: 40,
            bottom: 40
          }
        }
      }
    },
    {
      breakpoint: 1240,
      options: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  fontSize: '16px'
                },
                value: {
                  fontSize: '20px'
                }
              }
            }
          }
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: t('Net Sales'),
              fontSize: '16px',
              fontFamily: 'Inter, Helvetica',
              fontWeight: 300,
              color: '#6B7280',
              formatter: (): string => totalValueLabel
            },
            value: {
              fontSize: '24px',
              color: '#111827',
              fontWeight: 500,
              fontFamily: 'Poppins, Helvetica'
            }
          }
        },
        expandOnClick: false
      }
    },
    tooltip: {
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: true
      },
      custom: function ({ seriesIndex, w }): string {
        const kpi = costs[seriesIndex]
        const percentage = (total.value === 0 || !kpi.value) ? 0 : Math.abs(kpi.value) / total.value!
        const kpiName = t(`ls_fin_break_${kpi.id}`)
        return `<div style="padding: 8px; background-color: white; font-size: 16px; color: black; font-weight: 300" class="arrow_box">
                  <div style="display: flex; gap: 8px; align-items: center">
                    <div style="width: 12px; height: 12px; background-color: ${w.config.colors[seriesIndex]}; border-radius: 50%;"></div>
                    <span style="font-weight: 600">${kpiName}</span>
                    <span>${percentageFormatter(percentage, 2)}</span>
                    <span>${currencyFormatter(kpi.value)}</span>
                  </div>
                </div>`
      }
    }
  }
}
