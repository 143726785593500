import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type ProductSorting } from '../../../features/shared/product-sorting'
import { type OrderSorting } from '../../orders/order-sorting'

export interface TableSortingPresetConfig {
  readonly presetId: ProductSorting | OrderSorting
  readonly icon: Component
  readonly iconColor: string
}

export interface TableSortingPresetProps {
  readonly config: TableSortingPresetConfig
  readonly isSelected: boolean
}

const TableSortingPreset: Component<TableSortingPresetProps> = (props) => {
  return (
    <p class="text-current text-xs flex gap-2">
      <span class={`${props.config.iconColor}`}>{ props.config.icon({ size: 4 }) }</span> <Trans key={`ls_table_preset_${props.config.presetId}`} />
    </p>
  )
}

export default TableSortingPreset
