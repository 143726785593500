import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Squares2x2Solid from '../../assets/heroicons/Squares2x2Solid'
import { useError } from '../../shared/providers/error.provider'
import { MainModule } from '../../features/main-module'
import { useShop } from '../../shared/providers/shop.provider'
import { useLocation } from '@solidjs/router'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import IntegrationCard from './IntegrationCard'
import { IntegrationType } from '../../features/shop/domain/models/shop'
import PrintifyIntegrationCard from './PrintifyIntegrationCard'

export const PRINTIFY_TRY_LATER_ERROR = 10024

export enum IntegrationStatus {
  Success = 'success',
  Reconnected = 'reconnected',
  RedundantConnection = 'redundant_connection',
  ShopNotMatched = 'shop_not_matched',
  ShopNotFound = 'shop_not_found',
  SystemBusy = 'system_busy',
  Failed = 'failed'
}

const Integrations: Component = () => {
  const location = useLocation()
  const { setError } = useError()
  const { selectedShopId, setSelectedShop } = useShop()

  const getShop = MainModule.getShopComponent().provideGetShop()

  const updateShop = async (): Promise<void> => {
    executeWithUrlScope(
      async () => await getShop.execute(selectedShopId()!), location
    )
      .then((shop) => {
        setSelectedShop(shop)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
  }

  return (
    <div class="flex flex-col gap-4">
      <h1 class="page-title"><Squares2x2Solid size={6} /><Trans key="ls_integrations_title"/></h1>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-white p-6 flex flex-col gap-6">
          <div class="flex flex-col gap-1">
            <span class="text-2xl font-bold"><Trans key="ls_integrations_pod"/></span>
            <span class="text-sm text-gray-500"><Trans key="ls_integrations_obtain_products"/></span>
          </div>
          <div class="h-[1px] bg-gray-200 w-full"></div>
          <div class="flex gap-6 flex-wrap">
            <IntegrationCard onShopUpdated={updateShop} type={IntegrationType.Printful}/>
            <PrintifyIntegrationCard onShopUpdated={updateShop} type={IntegrationType.Printify}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Integrations
