import { type Component } from 'solid-js'
import { t } from 'i18next'

export interface TranslateWithActionProps {
  readonly key: string
  readonly options?: Record<string, unknown>
  readonly action: () => void
}

const TranslateWithAction: Component<TranslateWithActionProps> = (props) => {
  const fullText = t(props.key, props.options ?? {})
  const parts = fullText.split(/##(.*?)##/)

  return (
    <div>
      {parts[0]}
      <button class="link" onClick={props.action}>{parts[1]}</button>
      {parts[2]}
    </div>
  )
}

export default TranslateWithAction
