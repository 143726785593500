import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { CreateCogsPeriodQuery } from '../../data/queries/create-cogs-period.query'

export class CreateCogsPeriodInteractor {
  constructor(
    private readonly putProduct: PutInteractor<void>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number,
    amount: number,
    timestamp: number
  ): Promise<void> {
    await this.putProduct.execute(undefined, new CreateCogsPeriodQuery(shopId, productId, amount, Math.floor(timestamp / 1000)))
  }
}
