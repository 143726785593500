import { type Component, createEffect, createSignal, For, onCleanup } from 'solid-js'
import ProductFilters from '../shared/components/ProductFilters'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { ProductSorting, ProductFilterSorting } from '../../features/shared/product-sorting'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { KPIType } from '../../features/analytics/domain/models/kpi'
import ShoppingBagSolid from '../../assets/heroicons/ShoppingBagSolid'
import ProductsTableRow from './ProductsTableRow'
import { type SKUProductReport } from '../../features/product/domain/models/product'
import { MOCKED_SKU_PRODUCTS_REPORTS } from './MockedSkuProductReports'
import LockedProductsDialog from '../shared/components/LockedProductsDialog'

const ProductsLocked: Component = () => {
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [products, setProducts] = createSignal<PaginationOffsetLimit<SKUProductReport>>()
  const [page, setPage] = createSignal(0)

  createEffect(() => {
    document.body.style.overflow = 'hidden'

    onCleanup(() => {
      document.body.style.overflow = ''
    })
  })

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'sku' },
    { id: 'listing' },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true }
  ]

  const refreshTable = (_: number = 1): void => {
    setProducts({
      offset: 0,
      limit: 15,
      size: 15,
      values: MOCKED_SKU_PRODUCTS_REPORTS
    })
  }

  createEffect(() => {
    refreshTable(1)
  })

  return (
    <div class="relative">
      <LockedProductsDialog />
      <div class="flex flex-col gap-4">
        <h1 class="page-title"><ShoppingBagSolid /><Trans key="ls_products_title"/></h1>
        <SearchBar setSearchTerm={() => {}} placeholder="ls_products_search_title" />
        <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={() => false}/>
        <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
          <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <div class="table-scroll relative overflow-hidden overflow-x-scroll w-full">
              <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={() => TableStatus.Loaded} tableId="products">
                <For each={products()?.values}>{(product) => (
                  <ProductsTableRow product={product}/>
                )}</For>
              </Table>
            </div>
            <TablePagination totalCount={products()?.size ?? 0} page={page} setPage={setPage}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsLocked
