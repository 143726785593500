import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type Shop } from '../models/shop'
import { GetShopsQuery } from '../../data/queries/get-shops.query'

export class GetShopsInteractor {
  constructor(
    private readonly getShops: GetInteractor<Shop[]>
  ) {}

  public async execute(): Promise<Shop[]> {
    return await this.getShops.execute(new GetShopsQuery())
  }
}
