import { DeleteNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class DeleteCogsStatusQuery extends DeleteNetworkQuery {
  constructor (
    protected readonly shopId: number,
    protected readonly statusId: string
  ) {
    super(ApiEndpoint.ProductCogsBulkUpdateStatus)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      statusId: this.statusId
    }
  }
}
