import { type Component, Show, createEffect, createSignal, For } from 'solid-js'
import { MainModule } from '../../features/main-module'
import TablePagination from '../shared/components/TablePagination'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import ShoppingCartSolid from '../../assets/heroicons/ShoppingCartSolid'
import MultiSelect from '../shared/components/MultiSelect'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { useError } from '../../shared/providers/error.provider'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { useLocation } from '@solidjs/router'
import { type Order, OrderStatus } from '../../features/product/domain/models/order'
import OrdersTableRow from './OrdersTableRow'
import OrderFilters from './OrderFilters'
import { ProductFilter } from '../../features/product/domain/models/product-cost'
import { OrderFilterSorting, OrderSorting } from './order-sorting'
import NoMissingCogs from '../shared/components/NoMissingCogs'
import LockedPeriodDialog from '../shared/components/LockedPeriodDialog'
import { useUser } from '../../public/auth/user-provider'

const Orders: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [statusFilter, setStatusFilter] = createSignal<OrderStatus[]>([])
  const [selectedFilter, setSelectedFilter] = createSignal<ProductFilter>()
  const [orderBy, setOrderBy] = createSignal<string | undefined>(OrderFilterSorting[OrderSorting.MostRecent])
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [orders, setOrders] = createSignal<PaginationOffsetLimit<Order>>()
  const [page, setPage] = createSignal(0)
  const [missingCogsCounter, setMissingCogsCounter] = createSignal(0)

  const { selectedShop } = useShop()
  const { periods, isProPeriod } = usePeriods()
  const { setError } = useError()
  const { userPurchases } = useUser()
  const location = useLocation()

  const columns: TableColumn[] = [
    { id: 'created_timestamp', sortable: true },
    { id: 'receipt_id', withHint: true, sortable: true },
    { id: 'status' },
    { id: 'item_count', withHint: true, sortable: true },
    { id: 'paid', withHint: true, sortable: true },
    { id: 'sales_tax', withHint: true, sortable: true },
    { id: 'total_price', withHint: true, sortable: true },
    { id: 'shipping', withHint: true, sortable: true },
    { id: 'refunds', withHint: true, sortable: true },
    { id: 'discounts', withHint: true, sortable: true },
    { id: 'cogs', withHint: true },
    { id: 'gross_profit', withHint: true, sortable: true }
  ]

  const getOrders = MainModule.getProductComponent().provideGetOrders()

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getOrders.execute(
        shop.id,
        periods(),
        offset,
        DEFAULT_PAGE_SIZE,
        statusFilter(),
        orderBy(),
        searchTerm(),
        selectedFilter()
      ), location
    )
      .then((orders) => {
        setOrders(orders)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const isFiltering = (): boolean => {
    return !!(statusFilter().length || searchTerm())
  }

  createEffect(() => {
    orderBy()
    statusFilter()
    selectedFilter()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    statusFilter()
    searchTerm()
    executeWithUrlScope(
      async () => await getOrders.execute(
        shop.id,
        periods(),
        0,
        1,
        statusFilter(),
        orderBy(),
        searchTerm(),
        ProductFilter.WithoutCOGS
      ), location
    )
      .then((missingCogs) => {
        setMissingCogsCounter(missingCogs.size)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
  })

  createEffect(() => {
    refreshTable(page())
  })

  const showNoMissingCogs = (): boolean => {
    return selectedFilter() === ProductFilter.WithoutCOGS && missingCogsCounter() === 0
  }

  const showNoResults = (): boolean => {
    return !showNoMissingCogs() && orders()?.values.length === 0
  }

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), isFiltering(), orders()?.values)
  }

  return (
    <div class="flex flex-col gap-4 relative">
      <Show when={userPurchases()}>{(purchases) => (
        <Show when={isProPeriod() && !purchases().isPro}>
          <LockedPeriodDialog />
        </Show>
      )}</Show>
      <h1 class="page-title"><ShoppingCartSolid /><Trans key="ls_order_title"/></h1>
      <div class="hidden sm:block">
        <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_order_id" extraFilter={
          () => <MultiSelect setSelectedOptions={setStatusFilter} options={OrderStatus} placeholder="ls_generic_status" name="ls_order_status" />
        }/>
      </div>
      <div class="block sm:hidden">
        <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_order_id" />
        <div class="relative mt-2 rounded-md shadow-sm flex bg-white items-center py-1">
          <MultiSelect setSelectedOptions={setStatusFilter} options={OrderStatus} width="full" placeholder="ls_generic_status" name="ls_order_status"/>
        </div>
      </div>
      <OrderFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={isLoading} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} missingCogsCounter={missingCogsCounter()}/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns} status={getTableStatus} tableId="orders">
              <For each={orders()?.values}>{(order) => (
                <OrdersTableRow order={order} isLoading={isLoading}/>
              )}</For>
              <Show when={showNoMissingCogs()}>
                <td class="h-60">
                  <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                    <NoMissingCogs />
                  </div>
                </td>
              </Show>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={orders()?.size ?? 0} page={page} setPage={setPage} />
          </Show>
        </div>
      </div>
    </div>
  )
}

export default Orders
