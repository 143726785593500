import type { Component } from 'solid-js'
import ImageCell from '../../shared/components/ImageCell'
import ValueProgressCell from '../../shared/components/ValueProgressCell'
import { type ProductReport } from '../../../features/product/domain/models/product'
import ViewListingProductOnEtsyCell from './ViewListingProductOnEtsyCell'
import SkuCell from './SkuCell'
import NoListing from '../../../assets/images/no-listing-sm.svg'

export interface ListingProductTableRowProps {
  readonly product: ProductReport
  readonly isLoading: () => boolean
}

const ListingProductTableRow: Component<ListingProductTableRowProps> = (props) => {
  const product = props.product
  const listingImage = product.listing.isDeleted() ? NoListing : product.listing.imgUrls.sm

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 text-sm font-medium sm:pl-6 min-w-[88px]">
        <ImageCell imgUrl={listingImage} integrationType={product.cogsType} />
      </td>
      <td class="tableCell min-w-[240px]">
        <ViewListingProductOnEtsyCell product={product}/>
      </td>
      <td class="tableCell min-w-[240px]">
        <SkuCell sku={product.sku}/>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.orders}/>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.unitsSold}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueProgressCell kpi={product.netSales}/>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.cogsKpi} warning={product.missingCogs}/>
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueProgressCell kpi={product.opExpenses}/>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={product.opProfit}/>
      </td>
    </tr>
  )
}

export default ListingProductTableRow
