import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import { GetAnalyticsInteractor } from './domain/interactors/get-analytics.interactor'
import {
  GetAnalyticsTimeseriesInteractor
} from './domain/interactors/get-analytics-timeseries.interactor'
import { Cached } from '@coris-ts/helpers/utilities'
import { GetRepositoryMapper } from '@coris-ts/repository/repository-mapper'
import { provideDefaultNetworkDataSource } from '@coris-ts/repository/data-source/network.data-source'
import { SingleGetDataSourceRepository } from '@coris-ts/repository/single-data-source.repository'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { AnalyticsEntity } from './data/entities/analytics.entity'
import { AnalyticsEntityToAnalyticsMapper } from './domain/mappers/analytics.mappers'
import { TimeseriesEntityToTimeseriesMapper } from './domain/mappers/timeseries.mappers'
import { TimeseriesEntity } from './data/entities/timeseries.entity'
import { type Timeseries } from './domain/models/timeseries'
import {
  type ProductReportEntity,
  SKUProductReportEntity
} from '../product/data/entities/product.entity'
import { type ProductReport, type SKUProductReport } from '../product/domain/models/product'
import { PaginationOffsetLimitMapper } from '@coris-ts/repository/mapper/mapper'
import { ProductEntityToProductMapper } from '../product/domain/mappers/product.mappers'
import { ListingEntityToListingMapper } from '../product/domain/mappers/listing.mappers'
import {
  CogsEntityToCogsMapper,
  CogsTypeEntityToCogsTypeMapper
} from '../product/domain/mappers/cogs.mappers'
import {
  GetProductAnalyticsTimeseriesInteractor
} from './domain/interactors/get-product-analytics-timeseries.interactor'
import { GetProductReportInteractor } from './domain/interactors/get-product-report.interactor'
import { GetProductListingReportsInteractor } from './domain/interactors/get-product-listing-reports.interactor'
import { type ListingReport } from '../product/domain/models/listing'
import { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { ListingReportEntity } from '../product/data/entities/listing.entity'
import {
  ListingReportEntityToListingReportMapper
} from '../product/domain/mappers/listing-reports.mappers'
import { GetListingReportInteractor } from './domain/interactors/get-listing-report.interactor'
import {
  GetListingProductReportsInteractor
} from './domain/interactors/get-listing-product-reports.interactor'
import {
  GetListingAnalyticsTimeseriesInteractor
} from './domain/interactors/get-listing-analytics-timeseries.interactor'
import {
  SKUProductReportEntityToSKUProductReportMapper
} from '../product/domain/mappers/sku-product-reports.mappers'
import {
  ProductReportEntityToProductReportMapper
} from '../product/domain/mappers/product-report.mappers'
import { type Analytics } from './domain/models/analytics'

export interface AnalyticsComponent {
  provideGetAnalytics: () => GetAnalyticsInteractor
  provideGetAnalyticsTimeseries: () => GetAnalyticsTimeseriesInteractor
  provideGetProductAnalyticsTimeseries: () => GetProductAnalyticsTimeseriesInteractor
  provideGetListingAnalyticsTimeseries: () => GetListingAnalyticsTimeseriesInteractor
  provideGetProductReport: () => GetProductReportInteractor
  provideGetListingReport: () => GetListingReportInteractor
  provideGetProductListingReports: () => GetProductListingReportsInteractor
  provideGetListingProductReports: () => GetListingProductReportsInteractor
}

export class AnalyticsDefaultModule implements AnalyticsComponent {
  private readonly productMapper = new ProductEntityToProductMapper(
    new ListingEntityToListingMapper(),
    new CogsEntityToCogsMapper(),
    new CogsTypeEntityToCogsTypeMapper()
  )

  constructor (private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getKPIsRepository(): GetRepositoryMapper<AnalyticsEntity, Analytics> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      AnalyticsEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new AnalyticsEntityToAnalyticsMapper()
    )
  }

  @Cached()
  private getProductReportRepository(): GetRepositoryMapper<SKUProductReportEntity, SKUProductReport> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      SKUProductReportEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new SKUProductReportEntityToSKUProductReportMapper(
        this.productMapper
      )
    )
  }

  @Cached()
  private getListingReportRepository(): GetRepositoryMapper<ListingReportEntity, ListingReport> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      ListingReportEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ListingReportEntityToListingReportMapper()
    )
  }

  @Cached()
  private getTimeSeriesRepository(): GetRepositoryMapper<TimeseriesEntity, Timeseries> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      TimeseriesEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new TimeseriesEntityToTimeseriesMapper()
    )
  }

  @Cached()
  private getListingReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ListingReportEntity>, PaginationOffsetLimit<ListingReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ListingReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ListingReportEntityToListingReportMapper()
      )
    )
  }

  @Cached()
  private getProductReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductReportEntity>, PaginationOffsetLimit<ProductReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ProductReportEntityToProductReportMapper(
          new ListingEntityToListingMapper(),
          new CogsEntityToCogsMapper(),
          new CogsTypeEntityToCogsTypeMapper()
        )
      )
    )
  }

  public provideGetAnalytics (): GetAnalyticsInteractor {
    return new GetAnalyticsInteractor(
      new GetInteractor<Analytics>(this.getKPIsRepository())
    )
  }

  public provideGetAnalyticsTimeseries (): GetAnalyticsTimeseriesInteractor {
    return new GetAnalyticsTimeseriesInteractor(
      new GetInteractor<Timeseries>(this.getTimeSeriesRepository())
    )
  }

  public provideGetProductReport (): GetProductReportInteractor {
    return new GetProductReportInteractor(
      new GetInteractor<SKUProductReport>(this.getProductReportRepository())
    )
  }

  public provideGetListingReport (): GetListingReportInteractor {
    return new GetListingReportInteractor(
      new GetInteractor<ListingReport>(this.getListingReportRepository())
    )
  }

  public provideGetProductAnalyticsTimeseries (): GetProductAnalyticsTimeseriesInteractor {
    return new GetProductAnalyticsTimeseriesInteractor(
      new GetInteractor<Timeseries>(this.getTimeSeriesRepository())
    )
  }

  public provideGetProductListingReports (): GetProductListingReportsInteractor {
    return new GetProductListingReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ListingReport>>(this.getListingReportsRepository())
    )
  }

  public provideGetListingProductReports (): GetListingProductReportsInteractor {
    return new GetListingProductReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductReport>>(this.getProductReportsRepository())
    )
  }

  public provideGetListingAnalyticsTimeseries (): GetListingAnalyticsTimeseriesInteractor {
    return new GetListingAnalyticsTimeseriesInteractor(
      new GetInteractor<Timeseries>(this.getTimeSeriesRepository())
    )
  }
}
