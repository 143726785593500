import { EtsyAuthUrlQuery } from '../../data/queries/etsy-auth-url.query'
import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'

export class GetEtsyAuthUrlInteractor {
  constructor (
    private readonly getEtsyAuthUrl: PutInteractor<string>
  ) {}

  public async execute (): Promise<string> {
    return await this.getEtsyAuthUrl.execute(undefined, new EtsyAuthUrlQuery(window.location.href))
  }
}
