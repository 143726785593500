import { PutNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type HttpParameters } from '@coris-ts/data/parameter-type'

export class PurchaseProductQuery extends PutNetworkQuery {
  constructor (
    private readonly subscriptionId: string,
    private readonly priceId: string
  ) {
    super(ApiEndpoint.Purchase)
  }

  public get urlParameters(): HttpParameters {
    return {
      subscriptionId: this.subscriptionId,
      priceId: this.priceId
    }
  }
}
