import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type Product } from '../models/product'
import { type ProductCostsEntity } from '../../data/entities/product-costs.entity'
import { type CogsStatus, ProductCost } from '../models/product-cost'
import { type ProductEntity } from '../../data/entities/product.entity'
import { CogsType } from '../models/cogs'
import { CogsTypeEntity } from '../../data/entities/cogs.entity'

export class ProductCostsEntityToProductCostsMapper implements Mapper<ProductCostsEntity, ProductCost> {
  constructor(
    private readonly productMapper: Mapper<ProductEntity, Product>
  ) {
  }

  public map(from: ProductCostsEntity): ProductCost {
    const isPrintful = from.product.cogs_type === CogsTypeEntity.Printful
    const isPrintify = from.product.cogs_type === CogsTypeEntity.Printify
    const cogsType = isPrintful
      ? CogsType.Printful
      : isPrintify
        ? CogsType.Printify
        : CogsType.None

    return new ProductCost(
      from.sku,
      from.cogs_status as CogsStatus,
      from.multiple_cogs,
      from.number_of_products,
      from.min_price,
      from.max_price,
      cogsType,
      this.productMapper.map(from.product)
    )
  }
}
