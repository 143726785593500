import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type PeriodTime } from '../../../shared/period-state'
import { type Timeseries } from '../models/timeseries'
import { type TimeseriesResolution } from '../../../../private/shared/analytics.vm'
import {
  GetProductAnalyticsTimeseriesQuery
} from '../../data/queries/get-product-analytics-timeseries.query'

export class GetProductAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution, productId?: number): Promise<Timeseries> {
    if (!productId) {
      throw new Error('productId is required')
    }
    return await this.getAnalytics.execute(new GetProductAnalyticsTimeseriesQuery(shopId, productId, period.from, period.to, resolution))
  }
}
