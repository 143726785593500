import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import {
  type OfferingEntity,
  type OfferingProductEntity
} from '../../data/entities/offering.entity'
import {
  Offering, OfferingItem,
  OfferingProduct
} from '../models/offering'

export class OfferingProductEntityToOfferingProductMapper implements Mapper<OfferingProductEntity, OfferingProduct> {
  public map(from: OfferingProductEntity): OfferingProduct {
    return new OfferingProduct(
      from.id,
      from.price,
      from.currency_code,
      from.offering,
      from.name,
      from.billing_interval,
      from.trial_period
    )
  }
}

export class OfferingEntityToOfferingMapper implements Mapper<OfferingEntity, Offering> {
  public map(from: OfferingEntity): Offering {
    const productMapper = new OfferingProductEntityToOfferingProductMapper()

    return new Offering(
      from.id,
      from.name,
      from.type,
      from.offerings.map(offer => new OfferingItem(
        offer.offering,
        offer.prices.map(product => productMapper.map(product))
      ))
    )
  }
}
