import {DeleteRepository} from "@coris-ts/repository/repository";
import {Query} from "@coris-ts/repository/query/query";
import {DefaultOperation, Operation} from "@coris-ts/repository/operation/operation";

export class DeleteInteractor {
    constructor(private readonly repository: DeleteRepository) {}

    public execute(query: Query, operation: Operation = new DefaultOperation()): Promise<void> {
        return this.repository.delete(query, operation);
    }
}
