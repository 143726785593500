import { ArrayMapper, type Mapper } from '@coris-ts/repository/mapper/mapper'
import {
  type SubscriptionEntity
} from '../../data/entities/subscription.entity'
import {
  type Subscription
} from '../models/subscription'
import { type PurchasesEntity } from '../../data/entities/purchases.entity'
import { Purchases } from '../models/purchases'
import { type OneTimePurchaseEntity } from '../../data/entities/one-time-purchase.entity'
import { type OneTimePurchase } from '../models/one-time-purchase'

export class PurchasesEntityToPurchasesMapper implements Mapper<PurchasesEntity, Purchases> {
  constructor (
    private readonly oneTimePurchaseMapper: Mapper<OneTimePurchaseEntity, OneTimePurchase>,
    private readonly subscriptionMapper: Mapper<SubscriptionEntity, Subscription>
  ) {}

  public map(from: PurchasesEntity): Purchases {
    return new Purchases(
      new ArrayMapper(this.oneTimePurchaseMapper).map(from.one_time_purchases),
      new ArrayMapper(this.subscriptionMapper).map(from.subscriptions)
    )
  }
}
