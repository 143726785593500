import { type Location } from '@solidjs/router'

export const executeWithUrlScope = async <T>(promiseFunc: () => Promise<T>, location: Location): Promise<T> => {
  const initialURL = location.pathname

  return await new Promise<T>((resolve, reject) => {
    promiseFunc()
      .then(data => {
        resolve(data)
      })
      .catch((error) => {
        if (location.pathname === initialURL) {
          reject(error)
        }
      })
  })
}
