import { type Component } from 'solid-js'
import { t } from 'i18next'
import { usePeriods } from '../../../shared/providers/period.provider'
import { type NumericalDataType } from '../../../shared/models/numerical-data-type'
import { type Position } from '../../../shared/helpers/dom.functions'
import { theDayBefore } from '../../../shared/helpers/date.functions'
import { mapTimestampToDate } from '../../../features/shared/map-timestamp-to-date'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'
import { useDateFormatter } from '../../../shared/helpers/date-formatter'

export interface PrevPeriodTooltipProps {
  readonly prevValue: number
  readonly type: NumericalDataType
  readonly position?: Position
}

const PrevPeriodTooltip: Component<PrevPeriodTooltipProps> = (props) => {
  const { periods } = usePeriods()
  const { formatNumericValue } = useNumberFormatter()
  const { shortDateFormatter } = useDateFormatter()

  const start = (): string => shortDateFormatter(mapTimestampToDate(periods().prev.from)!)
  const end = (): string => shortDateFormatter(theDayBefore(mapTimestampToDate(periods().prev.to)!))

  return (
    <div style={`
            ${props.position ? 'left: ' + props.position.left + 'px' : 'left: 0'};
            ${props.position ? ' top:' + props.position.top + 'px' : 'top: 24px'};
         `}
         class="flex flex-col border-[1px] border-gray-200 gap-2 min-w-[268px] text-sm absolute bg-white p-4 shadow-lg rounded-lg z-30">
      <span class="text-gray-900 font-semibold">
        { t('ls_shared_prev_period') }
      </span>
      <div class="flex gap-4 justify-between whitespace-nowrap">
        <span class="text-gray-500">
          { start() } - { end() }
        </span>
        <span class="text-gray-900 font-semibold">
          {formatNumericValue(props.type, props.prevValue)}
        </span>
      </div>
    </div>
  )
}

export default PrevPeriodTooltip
