import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { ReactivateSubscriptionQuery } from '../../data/queries/reactivate-subscription.query'
import { type Subscription } from '../models/subscription'

export class ReactivateSubscriptionInteractor {
  constructor(
    private readonly reactivateSubscription: PutInteractor<Subscription>
  ) {
  }

  public async execute(subscriptionId: string): Promise<Subscription> {
    return await this.reactivateSubscription.execute(undefined, new ReactivateSubscriptionQuery(subscriptionId))
  }
}
