import { from, type Observable } from 'rxjs'
import { type AxiosInstance } from 'axios'
import { type HttpRequestBuilder } from '../../../coris-ts/data/http-request.builder'
import { UrlBuilder } from '../../../coris-ts/helpers/url-builder'
import { type HttpBody, type HttpParameters } from '../../../coris-ts/data/parameter-type'

interface RequestOptions {
  headers: Record<string, string | string[]>
  observe: 'response'
  responseType: 'json'
}

export class AxiosHttpRequestBuilder<T = unknown> implements HttpRequestBuilder<T> {
  private readonly urlBuilder: UrlBuilder
  private body: string | FormData | null = null
  private customHeaders: Record<string, string> = {}

  constructor (
    protected readonly endpoint: string,
    private readonly axios: AxiosInstance
  ) {
    this.urlBuilder = new UrlBuilder(endpoint)
  }

  private get defaultHeaders (): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }

  public setUrlParameters (urlParameters: HttpParameters): this {
    this.urlBuilder.setUrlParameters(urlParameters)
    return this
  }

  public setQueryParameters (queryParameters: HttpParameters): this {
    this.urlBuilder.setQueryParameters(queryParameters)
    return this
  }

  public setCustomHeaders(headers: Record<string, string>): this {
    this.customHeaders = headers
    return this
  }

  public setBody(body: HttpBody | FormData): this {
    if (body instanceof FormData) {
      this.body = body
    } else {
      this.body = JSON.stringify(body)
    }
    return this
  }

  public get (): Observable<T | undefined> {
    const res = this.axios.get<T>(
      this.urlBuilder.getUrl(),
      this.createRequestOptions()
    ).then(response => response.data ?? undefined)

    return from(res)
  }

  public post (): Observable<T | undefined> {
    const res = this.axios.post<T>(
      this.urlBuilder.getUrl(),
      this.body,
      this.createRequestOptions()
    ).then(response => response.data ?? undefined)

    return from(res)
  }

  public put (): Observable<T | undefined> {
    const res = this.axios.put<T>(
      this.urlBuilder.getUrl(),
      this.body,
      this.createRequestOptions()
    ).then(response => response.data ?? undefined)

    return from(res)
  }

  public patch (): Observable<T | undefined> {
    const res = this.axios.patch<T>(
      this.urlBuilder.getUrl(),
      this.body,
      this.createRequestOptions()
    ).then(response => response.data ?? undefined)

    return from(res)
  }

  public delete (): Observable<void> {
    const res = this.axios.delete<T>(
      this.urlBuilder.getUrl(),
      this.createRequestOptions()
    ).then(_ => undefined)

    return from(res)
  }

  private createRequestOptions (): RequestOptions {
    const headers = {
      ...this.defaultHeaders,
      ...this.customHeaders
    }

    return {
      observe: 'response',
      responseType: 'json',
      headers
    }
  }
}
