import { createContext, createSignal, type JSX, useContext } from 'solid-js'
import { type User } from '../../features/auth/domain/models/user'
import { type Purchases } from '../../features/payment/domain/models/purchases'
import {
  type OneTimePurchase,
  OneTimePurchaseType
} from '../../features/payment/domain/models/one-time-purchase'
import { type Subscription } from '../../features/payment/domain/models/subscription'
import { OfferingType } from '../../features/payment/domain/models/offering'

export interface UserContextType {
  readonly currentUser: () => User | undefined
  readonly setCurrentUser: (user: User) => void
  readonly userPurchases: () => Purchases | undefined
  readonly setUserPurchases: (purchases: Purchases) => void
  readonly hasAccess: () => boolean
  readonly currentSubscription: () => Subscription | OneTimePurchase | undefined
}

const [currentUser, setCurrentUser] = createSignal<User>()
const [userPurchases, setUserPurchases] = createSignal<Purchases>()
const hasAccess = (): boolean => {
  if (userPurchases()?.isLifetime) {
    return true
  }

  const subscriptions = userPurchases()?.subscriptions ?? []

  for (const sub of subscriptions) {
    const type = sub.productPrice.offeringType
    if ((type === OfferingType.Pro || type === OfferingType.Starter) && sub.isValid) {
      return true
    }
  }

  return false
}

const currentSubscription = (): Subscription | OneTimePurchase | undefined => {
  const proSub = userPurchases()?.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.Pro)
  if (proSub?.isValid) return proSub

  const starterSub = userPurchases()?.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.Starter)
  if (starterSub?.isValid) return starterSub

  return userPurchases()?.oneTimePurchases.find(sub => sub.productPrice.offeringType === OneTimePurchaseType.Lifetime)
}

const defaultUserContext: UserContextType = {
  currentUser,
  setCurrentUser,
  userPurchases,
  setUserPurchases,
  hasAccess,
  currentSubscription
}

const UserContext = createContext<UserContextType>(defaultUserContext)

export const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <UserContext.Provider value={defaultUserContext}>
      {props.children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => {
  return useContext(UserContext)
}
