import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import ExclamationTriangle from '../../assets/heroicons/ExclamationTriangle'
import TranslateWithAction from '../shared/components/TranslateWithAction'

export interface PlanChangeDialogProps {
  readonly onActionClose: () => void
  readonly manageSubscriptionURL: string
}

const PlanChangeErrorDialog: Component<PlanChangeDialogProps> = (props) => {
  return (
    <Dialog maxWidth={'606px'}>
      <div class="flex gap-4 p-6">
        <span class="text-red-600 flex items-center justify-center min-w-[40px] h-10 rounded-full bg-red-100">
          <ExclamationTriangle/>
        </span>
        <div class="flex flex-col gap-2">
          <span class="font-bold">Transaction Failed</span>
          <span class="text-sm text-gray-500">We encountered an issue while processing your plan change. Please review your selected payment method and try again.</span>
          <span class="mt-3 text-sm text-gray-500">
            <TranslateWithAction key='ls_plan_change_error_text2'
                                 action={() => {
                                   window.Intercom('show')
                                 }}
            />
          </span>
        </div>
      </div>
      <DialogActions>
        <button
          onClick={() => {
            props.onActionClose()
          }}
          class="btn btn--white">
          <Trans key="ls_generic_cancel"/>
        </button>
        <a href={props.manageSubscriptionURL} target="_blank" onClick={props.onActionClose} class="btn btn--primary">
          <Trans key="ls_plan_change_update_payment_method"/>
        </a>
      </DialogActions>
    </Dialog>
  )
}

export default PlanChangeErrorDialog
