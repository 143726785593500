export class User {
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly emailVerified: boolean,
    public readonly name: string,
    public readonly pictureUrl: string,
    public readonly customerId: string,
    public readonly intercomHash: string
  ) {
  }
}
