import {
  type OfferingProductInterval,
  type OfferingType
} from '../../domain/models/offering'
import { type OneTimePurchaseType } from '../../domain/models/one-time-purchase'

export interface Interval {
  readonly interval: OfferingProductInterval
  readonly frequency: number
}

export class OfferingProductEntity {
  constructor(
    public readonly id: string,
    public readonly price: number,
    public readonly currency_code: string,
    public readonly offering: OfferingType | OneTimePurchaseType,
    public readonly name: string,
    public readonly billing_interval: Interval,
    public readonly trial_period: Interval
  ) {
  }
}

export class OfferingItemEntity {
  constructor(
    public readonly prices: OfferingProductEntity[],
    public readonly offering: OfferingType | OneTimePurchaseType
  ) {
  }
}

export class OfferingEntity {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly type: string,
    public readonly offerings: OfferingItemEntity[]
  ) {
  }
}
