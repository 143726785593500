import type { Component } from 'solid-js'
import { createEffect, createSignal, onCleanup } from 'solid-js'
import ApexCharts from 'apexcharts'
import { type KPI } from '../../../features/analytics/domain/models/kpi'
import { type Timeseries } from '../../../features/analytics/domain/models/timeseries'
import { useShop } from '../../../shared/providers/shop.provider'
import { usePeriods } from '../../../shared/providers/period.provider'
import {
  periodTypeToTimeSeriesPeriodType,
  timeseriesToLinearTimeseriesVmMapper
} from '../timeseries/timeseries-to-linear-timeseries-vm.mapper'
import { getPeriodsComparisonChartVM } from '../../dashboard/periods-comparison.chart-vm'
import { getPeriodsByType } from '../../../shared/helpers/date.functions'
import { type TimeseriesResolution, TimeseriesResolutionFromPeriod } from '../analytics.vm'
import { Trans } from '@mbarzda/solid-i18next'
import ProgressRatio from './ProgressRatio'
import ToggleButton from './ToggleButton'
import { type PeriodTime } from '../../../features/shared/period-state'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'
import { useDateFormatter } from '../../../shared/helpers/date-formatter'

export interface getTimeseriesInteractor {
  execute: (
    shopId: number,
    period: PeriodTime,
    resolution: TimeseriesResolution,
    entityId?: number
  ) => Promise<Timeseries>
}

export interface AnalyticsChartProps {
  readonly kpi: KPI
  readonly getTimeseriesAction: getTimeseriesInteractor
  readonly entityId?: number
}

interface TimeseriesPair {
  current: Timeseries
  prev: Timeseries
}

const AnalyticsChart: Component<AnalyticsChartProps> = (props) => {
  const [isLastPeriodVisible, setIsLastPeriodVisible] = createSignal(true)
  const [timeseries, setTimeseries] = createSignal<TimeseriesPair>()
  const { formatNumericValue } = useNumberFormatter()
  const { shortDateFormatter } = useDateFormatter()

  const { selectedShopId } = useShop()
  const { periods } = usePeriods()

  let chart: ApexCharts | undefined

  const renderChart = async (kpi: KPI, series: TimeseriesPair, isLastPeriodVisible: boolean): Promise<void> => {
    const timeseriesVM = timeseriesToLinearTimeseriesVmMapper(series.current, series.prev, kpi, periods().type, shortDateFormatter)

    const chartOptions = getPeriodsComparisonChartVM(timeseriesVM, isLastPeriodVisible, formatNumericValue)
    if (chart) {
      await chart.updateOptions(chartOptions)
    } else {
      chart = new ApexCharts(document.querySelector('#analyticsChart'), chartOptions)
      await chart.render()
    }
  }

  createEffect((): void => {
    const shopId = selectedShopId()
    if (!shopId) {
      return
    }

    const timeSeriesPeriodType = periodTypeToTimeSeriesPeriodType(periods().type)
    const periodsToShow = {
      type: timeSeriesPeriodType,
      ...getPeriodsByType(timeSeriesPeriodType)
    }

    const currentPeriod = periodsToShow.current
    const resolution = TimeseriesResolutionFromPeriod(currentPeriod.from, currentPeriod.to)

    void Promise.all([
      props.getTimeseriesAction.execute(shopId, periodsToShow.current, resolution, props.entityId),
      props.getTimeseriesAction.execute(shopId, periodsToShow.prev, resolution, props.entityId)
    ]).then(([timeseries, prevTimeseries]) => {
      setTimeseries({
        current: timeseries,
        prev: prevTimeseries
      })
    })
  })

  createEffect(async () => {
    const series = timeseries()
    if (!series || !props.kpi) { return }

    await renderChart(props.kpi, series, isLastPeriodVisible())
  })

  onCleanup(() => {
    if (chart) {
      chart.destroy()
    }
  })

  return (
    <div class="overflow-hidden rounded-lg bg-white shadow">
      <div class="flex justify-between p-4">
        <div class="flex items-center">
          <h2 class="mr-3 text-base font-medium text-gray-900"><Trans key={`ls_kpi_cat_${props.kpi.id}`}/></h2>
          <ProgressRatio value={props.kpi.progressRatio} kpi={props.kpi.id}/>
        </div>
        <div class="flex items-center">
          <h2 class="mr-2 text-xs text-black"><Trans key="ls_dashboard_compare_periods" /></h2>
          <ToggleButton onToggle={(isEnabled) => setIsLastPeriodVisible(isEnabled)} isEnabled={true}/>
        </div>
      </div>
      <div id="analyticsChart"/>
    </div>
  )
}

export default AnalyticsChart
