import { type Accessor, type Component, For } from 'solid-js'
import MiniFire from '../../../assets/heroicons/MiniFire'
import MiniArrowTrendingUp from '../../../assets/heroicons/MiniArrowTrendingUp'
import MiniStar from '../../../assets/heroicons/MiniStar'
import MiniFaceFrown from '../../../assets/heroicons/MiniFaceFrown'
import MiniArrowTrendingDown from '../../../assets/heroicons/MiniArrowTrendingDown'
import MiniSparkles from '../../../assets/heroicons/MiniSparkles'
import MiniClock from '../../../assets/heroicons/MiniClock'
import { ProductFilterSorting, ProductSorting } from '../../../features/shared/product-sorting'
import TableSortingPresetPair, { type TableSortingPresetPairConfig } from './TableSortingPresetPair'

export interface ProductFiltersProps {
  readonly orderBy: Accessor<string | undefined>
  readonly setOrderBy: (sorting: string | undefined) => void
  readonly isLoading: Accessor<boolean>
}

const ProductFilters: Component<ProductFiltersProps> = (props) => {
  const ProductPresets: TableSortingPresetPairConfig[] = [
    {
      desc: {
        presetId: ProductSorting.MostRecent,
        icon: MiniClock,
        iconColor: 'text-japanese-500'
      },
      asc: {
        presetId: ProductSorting.Oldest,
        icon: MiniClock,
        iconColor: 'text-japanese-500'
      }
    },
    {
      desc: {
        presetId: ProductSorting.BestSellers,
        icon: MiniFire,
        iconColor: 'text-orange-500'
      },
      asc: {
        presetId: ProductSorting.Underperformers,
        icon: MiniFaceFrown,
        iconColor: 'text-blue-500'
      }
    },
    {
      desc: {
        presetId: ProductSorting.MostProfitable,
        icon: MiniArrowTrendingUp,
        iconColor: 'text-green-500'
      },
      asc: {
        presetId: ProductSorting.LessProfitable,
        icon: MiniArrowTrendingDown,
        iconColor: 'text-red-500'
      }
    },
    {
      desc: {
        presetId: ProductSorting.TrendSetters,
        icon: MiniStar,
        iconColor: 'text-yellow-500'
      },
      asc: {
        presetId: ProductSorting.FallingStars,
        icon: MiniSparkles,
        iconColor: 'text-blue-500'
      }
    }
  ]

  return (
    <ul role="list" class="grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
      <For each={ProductPresets}>{(presets) => (
        <li class="col-span-1">
          <TableSortingPresetPair options={ProductFilterSorting} presets={presets} orderBy={props.orderBy} setOrderBy={props.setOrderBy}/>
        </li>
      )}</For>
    </ul>
  )
}

export default ProductFilters
