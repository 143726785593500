import { type Component, Show } from 'solid-js'
import DateCell from '../shared/components/DateCell'
import TextCell from '../shared/components/TextCell'
import CogsValueCell from '../shared/components/CogsValueCell'
import { type Product } from '../../features/product/domain/models/product'
import ImageViewOnEtsyCell from '../shared/components/ImageViewOnEtsyCell'
import { CogsStatus } from '../../features/product/domain/models/product-cost'

export interface CurrentPeriodCogsTableRowProps {
  readonly product: Product
  readonly cogsStatus: CogsStatus
}

const CurrentPeriodCogsTableRow: Component<CurrentPeriodCogsTableRowProps> = (props) => {
  const showWarning = (): boolean => {
    const cogsMissing = !props.product.cogs && !!props.product.firstOrderDate
    return cogsMissing || (props.cogsStatus === CogsStatus.CurrentPeriodMismatch)
  }

  return (
    <tr>
      <td class="px-3 py-3.5 text-xs text-gray-900 sm:pl-6 min-w-[152px] md:pl-3">
        <Show when={props.product.cogs} fallback={
          <span>-</span>
        }>{(cogs) => (
          <DateCell date={cogs().fromDate}/>
        )}
        </Show>
      </td>
      <td class="tableCell text-gray-500 min-w-[240px]">
        <ImageViewOnEtsyCell listing={props.product.listing}/>
      </td>
      <td class="tableCell text-gray-500 min-w-[240px]">
        <TextCell text={props.product.variantName}/>
      </td>
      <td class="tableCell min-w-[112px] max-w-[145px]">
        <CogsValueCell cogs={props.product.cogs} warning={showWarning()}/>
      </td>
    </tr>
  )
}

export default CurrentPeriodCogsTableRow
