import { AxiosHttpRequestBuilder } from './http-request.builder'
import { ApiRequestService } from '../../../coris-ts/repository/data-source/api-request.service'
import { type HttpRequestBuilder } from '../../../coris-ts/data/http-request.builder'
import { type AxiosInstance } from 'axios'

export class AxiosApiRequestService extends ApiRequestService {
  constructor (baseUrl: string, private readonly axios: AxiosInstance) {
    super(baseUrl)
  }

  protected create<T>(endpoint: string): HttpRequestBuilder<T> {
    return new AxiosHttpRequestBuilder<T>(endpoint, this.axios)
  }
}
