export enum CogsTypeEntity {
  None = 'none',
  UserDefined = 'user_defined',
  SkuMatched = 'sku_matched',
  Printful = 'printful',
  Printify = 'printify'
}

export class CogsEntity {
  constructor(
    public readonly from_date: number,
    public readonly amount_per_unit: number,
    public readonly currency: string
  ) {
  }
}
