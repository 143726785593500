import { type Component, Show } from 'solid-js'
import type { NumericalDataType } from '../../../shared/models/numerical-data-type'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { Trans } from '@mbarzda/solid-i18next'
import { getValueColor } from '../../../shared/helpers/get-value-color'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface ValueCellProps {
  readonly value?: number
  readonly type: NumericalDataType
  readonly warning?: boolean
  readonly colored?: true
}

const ValueCell: Component<ValueCellProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  return (
    <div class="flex items-center gap-2 text-sm">
      <Show when={props.warning}>
        <span class="text-red-500"><MiniExclamationTriangle/></span>
      </Show>
      <Show when={props.value !== undefined} fallback={
        <span class="text-gray-500"><Trans key="ls_generic_n_a"/></span>
      }>
        <span class={`${props.colored ? getValueColor(props.value) : 'text-gray-900'}`}>
          {formatNumericValue(props.type, props.value)}
        </span>
      </Show>
    </div>
  )
}

export default ValueCell
