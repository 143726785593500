export const getEnumKeyByValue = <T>(enumType: T, value: number): string | undefined => {
  for (const key in enumType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const enumValue = (enumType as any)[key]
    if (enumValue === value && isNaN(+key)) {
      return key
    }
  }
  return undefined
}
