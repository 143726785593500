import { type Component, type JSX, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import LottieAnimation from '../shared/components/LottieAnimation'
import logoAnimation from '../../assets/animations/logo-animated.json'

export interface SectionCardProps {
  readonly icon?: JSX.Element
  readonly title?: string
  readonly children?: JSX.Element | string
  readonly isLoading?: boolean
  readonly styleNumColumns?: 'half' | 'full'
}

const SectionCard: Component<SectionCardProps> = (props) => {
  return (
    <div class="p-6 h-fit rounded-xl drop-shadow bg-white gap-6 flex flex-col grow w-full sm:min-w-[520px]"
    classList={{ 'max-w-[600px]': props.styleNumColumns === 'half' }}>
      <Show when={props.title}>{(title) => (
        <>
          <div class="flex gap-2 font-semibold items-center">
            {props.icon} <Trans key={title()}/>
          </div>
          <div class="h-px w-full bg-gray-200"></div>
        </>
      )}</Show>
      <div>
        <Show when={!props.isLoading}>
          {props.children}
        </Show>
        <Show when={props.isLoading}>
          <div class="w-full h-[112px] flex items-center justify-center">
            <LottieAnimation animationData={logoAnimation} width="2rem"/>
          </div>
        </Show>
      </div>
    </div>
  )
}

export default SectionCard
