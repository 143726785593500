import { type Component, Show } from 'solid-js'
import ProgressRatio from './ProgressRatio'
import { NumericalDataType } from '../../../shared/models/numerical-data-type'
import { type KPIVM } from '../../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import TextPlaceholder from './TextPlaceholder'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface StatCustomStyles {
  readonly label?: string
  readonly value?: string
}

export interface PerUnitStatProps {
  readonly stat: KPIVM
  readonly customStyles?: StatCustomStyles
  readonly units?: number
  readonly missingCogs?: boolean
}

const PerUnitStat: Component<PerUnitStatProps> = (props) => {
  const { currencyFormatter, formatNumericValue } = useNumberFormatter()

  const stat = props.stat
  const dataType = stat.type ?? NumericalDataType.Units

  return (
    <div class={'flex flex-col w-full'}>
      <span class={`${props.customStyles?.label} text-xs text-gray-400 flex items-center gap-2 stat_label font-semibold`}>
        <Show when={props.missingCogs}>
          <span class="text-red-600"><MiniExclamationTriangle /></span>
        </Show>
        <Trans key={`ls_kpi_cat_${stat.id}`}/>
      </span>
      <Show when={stat.type !== undefined} fallback={
        <div class="mt-2">
          <TextPlaceholder widthCss="75%" height="md"/>
        </div>
      }>
        <Show when={stat.value !== undefined}>
          <div class="flex gap-1 items-center justify-between">
            <span class={`${props.customStyles?.value} text-lg font-semibold text-current stat_value`}>{formatNumericValue(dataType, stat.value)}</span>
            <span class="stat_progress"><ProgressRatio value={stat.progressRatio} kpi={stat.id}/></span>
          </div>
          <Show when={props.units! > 0}>
            <span class={`${props.customStyles?.label} text-xs text-gray-400 lowercase`}>{currencyFormatter(stat.value! / props.units!)} per unit</span>
          </Show>
        </Show>
        <Show when={stat.value === undefined}>
          <span class={`${props.customStyles?.value} text-lg font-semibold text-current stat_value lowercase`}><Trans key="ls_generic_n_a"/></span>
          <span class={`${props.customStyles?.label} text-xs text-gray-400 lowercase`}><Trans key="ls_generic_n_a"/></span>
        </Show>
      </Show>
    </div>
  )
}

export default PerUnitStat
