import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetProductQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly productId: number
  ) {
    super(ApiEndpoint.ProductCogs)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      productId: this.productId
    }
  }
}
