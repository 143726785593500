import { type Component, For, type JSX } from 'solid-js'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../../shared/app-routes'
import MiniStar from '../../../assets/heroicons/MiniStar'
import { Trans } from '@mbarzda/solid-i18next'

export interface LockedViewDialogFeature {
  icon: JSX.Element
  name: string
}

export interface LockedViewDialogProps {
  title: string
  image: string
  features: string[]
  extraFeatures: LockedViewDialogFeature[]
}

const LockedViewDialog: Component<LockedViewDialogProps> = (props) => {
  return (
    <>
      <div class="absolute z-20 top-0 bottom-0 left-0 right-0 backdrop-blur-sm bg-[#f1f5f9cc] -my-6 -ml-10 -mr-10"></div>
      <div class="absolute top-0 left-0 z-20 w-full min-h-[calc(100vh-200px)] flex items-center justify-center">
        <div class="flex flex-col-reverse md:flex-row lg:flex-col-reverse xl:flex-row bg-white max-w-[440px] md:max-w-[910px] lg:max-w-[440px] xl:max-w-[910px] rounded-2xl overflow-hidden shadow-xl">
          <div class="flex flex-col gap-8 text-sm text-gray-500 px-8 py-10">
            <div class="flex flex-col gap-4 text-sm text-black">
              <span class="text-2xl font-bold"><Trans key={props.title}/></span>
              <span><Trans key="ls_locked_intro"/></span>
              <ul class="flex flex-col gap-2">
                <For each={props.features}>{(feature) => (
                  <li class="flex gap-2 font-semibold">
                    <span class="text-yellow-500"><MiniStar/></span><Trans key={feature}/>
                  </li>
                )}</For>
              </ul>
              <span><Trans key="ls_locked_extra"/></span>
              <ul class="flex flex-col gap-2">
                <For each={props.extraFeatures}>{(feature) => (
                  <li class="flex items-center gap-2 font-semibold">
                    <span class="text-japanese-600 w-5 h-5 flex justify-center items-center">{feature.icon}</span>
                    <Trans key={feature.name}/>
                  </li>
                )}</For>
              </ul>
            </div>
            <A href={AppRoutes.Subscription()} class="flex h-9 items-center justify-center highlight-gradient rounded-md text-white">
              <Trans key="ls_locked_cta"/>
            </A>
          </div>
          <img class="object-cover w-full md:w-1/2 lg:w-full xl:w-1/2 h-52 md:h-auto lg:h-52 xl:h-auto" src={props.image} alt="pro features"></img>
        </div>
      </div>
    </>
  )
}

export default LockedViewDialog
