import { type Component, Show } from 'solid-js'
import ProgressRatio from './ProgressRatio'
import { NumericalDataType } from '../../../shared/models/numerical-data-type'
import { type KPIVM } from '../../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import TextPlaceholder from './TextPlaceholder'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface StatCustomStyles {
  readonly label?: string
  readonly value?: string
  readonly placeholderSize?: 'sm' | 'md'
  readonly valueContainer?: string
  readonly diff?: string
}

export interface StatProps {
  readonly stat: KPIVM
  readonly customStyles?: StatCustomStyles
}

const Stat: Component<StatProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  const stat = props.stat
  const dataType = stat.type ?? NumericalDataType.Units
  return (
    <div class={'flex flex-col w-full'}>
      <span class={`${props.customStyles?.label} text-xs text-gray-400 stat_label`}><Trans key={`ls_kpi_cat_${stat.id}`} /></span>
      <Show when={stat.value !== undefined} fallback={
        <div class="mt-2">
          <TextPlaceholder widthCss="75%" height={props.customStyles?.placeholderSize ?? 'md'}/>
        </div>
      }>
        <div class={`${props.customStyles?.valueContainer} flex gap-1 items-center justify-between`}>
          <span class={`${props.customStyles?.value} text-lg font-semibold text-current stat_value`}>{formatNumericValue(dataType, stat.value)}</span>
          <span class={`${props.customStyles?.diff} stat_progress`}><ProgressRatio value={stat.progressRatio} kpi={stat.id}/></span>
        </div>
      </Show>
    </div>
  )
}

export default Stat
