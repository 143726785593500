import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import issue from '../../assets/images/issue.png'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import Button, { ButtonStyle } from '../shared/components/Button'

export interface IntegrationTryAgainDialogProps {
  readonly cancel: () => void
  readonly retry: () => void
}

const IntegrationTryAgainDialog: Component<IntegrationTryAgainDialogProps> = (props) => {
  return (
    <Dialog maxWidth={'434px'}>
      <div class="py-6 px-8 mx-auto text-center text-black flex gap-2 flex-col items-center">
        <img src={issue} class="h-24" alt="error"/>
        <span class="text-base text-gray-900 font-bold">
          <Trans key="ls_integrations_retry_title"/>
        </span>
        <span class="text-sm text-gray-500">
          <Trans key="ls_integrations_retry_description"/>
        </span>
      </div>

      <DialogActions>
        <Button style={ButtonStyle.White} key="ls_generic_close" action={props.cancel} />
        <Button style={ButtonStyle.Primary} key="ls_generic_try_again" action={props.retry} />
      </DialogActions>
    </Dialog>
  )
}

export default IntegrationTryAgainDialog
