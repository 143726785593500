import { type Component, type JSX, type Accessor } from 'solid-js'
import MiniArrowSmallUp from '../../../assets/heroicons/MiniArrowSmallUp'
import MiniArrowSmallDown from '../../../assets/heroicons/MiniArrowSmallDown'
import MiniMinusSmall from '../../../assets/heroicons/MiniMinusSmall'
import { getValueColorByKPI } from '../../../shared/helpers/get-value-color'
import { type NumericalDataType } from '../../../shared/models/numerical-data-type'
import PrevPeriodTooltip from './PrevPeriodTooltip'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  type Size
} from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'
import { type DiffType, KPIDiffType, type KPIType } from '../../../features/analytics/domain/models/kpi'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface ProgressRatioProps {
  readonly value: number | undefined
  readonly prevValue?: number
  readonly type?: NumericalDataType
  readonly kpi?: KPIType
}

const ProgressRatio: Component<ProgressRatioProps> = (props) => {
  const { percentageFormatter } = useNumberFormatter()

  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  if (props.prevValue) {
    tooltipSize = useMeasureComponent(<PrevPeriodTooltip prevValue={props.prevValue} type={props.type!}/>)
  }

  const showTooltip = (event: MouseEvent): void => {
    const prev = props.prevValue
    if (!prev || !tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <PrevPeriodTooltip prevValue={prev} type={props.type!} position={position}/>
    )
  }

  const hideTooltip = (): void => {
    if (!props.prevValue) return
    removeTooltip()
  }

  const icon = (): JSX.Element => {
    const value = props.value
    if (value === undefined) {
      return <MiniMinusSmall />
    }

    return value > 0
      ? <MiniArrowSmallUp />
      : value === 0
        ? <MiniMinusSmall />
        : <MiniArrowSmallDown />
  }

  const symbol = (): string => props.value && props.value > 0 ? '+' : ''
  const kpiType = (): DiffType | undefined => props.kpi ? KPIDiffType[props.kpi] : undefined

  return (
    <div class="flex relative h-6 items-center min-w-[58px]">
      <span
        onMouseEnter={showTooltip} onMouseLeave={hideTooltip}
        class={`${getValueColorByKPI(props.value, kpiType())}` + ' font-semibold inline-flex items-center text-xs cursor-default'}
      >
        {icon()} {symbol()}{percentageFormatter(props.value)}
      </span>
    </div>
  )
}

export default ProgressRatio
