import { type Component, createSignal, onMount, Show } from 'solid-js'
import MiniCalendarDays from '../../assets/heroicons/MiniCalendarDays'
import {
  eqDate,
  getDateFormatString,
  gtDate,
  ltDate,
  parseLocalDateString
} from '../../shared/helpers/date.functions'
import { Trans } from '@mbarzda/solid-i18next'
import { clickOutsideCalendar } from '../../shared/directives/click-outside-calendar'
// @ts-expect-error Flowbite does not provide types
import Datepicker from 'flowbite-datepicker/Datepicker'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import { type Cogs } from '../../features/product/domain/models/cogs'
import { useDateFormatter } from '../../shared/helpers/date-formatter'

export interface CogsFormData {
  readonly date: Date
  readonly amount: number
}

export interface CogsFormDialogProps {
  readonly type: 'reconcile' | 'create' | 'update'
  readonly firstAllowedDate?: Date
  readonly lastAllowedDate?: Date
  readonly defaultDate?: Date
  readonly defaultAmount?: number
  readonly isDateDisabled?: boolean
  readonly canBeDeleted?: boolean
  readonly otherPeriods: Cogs[]
  readonly delete?: (date: Date) => void
  readonly cancel: () => void
  readonly submit: (data: CogsFormData) => void
}

const CogsFormDialog: Component<CogsFormDialogProps> = (props) => {
  const [amount, setAmount] = createSignal<number | undefined>(props.defaultAmount)
  const [amountError, setAmountError] = createSignal<string>('')
  const [dateError, setDateError] = createSignal<string>('')
  const { shortDateFormatter } = useDateFormatter()

  let dateInput: HTMLInputElement | null
  let calendar: Datepicker | null

  const defaultDate = props.defaultDate ?? new Date()
  const defaultAmount = props.defaultAmount

  onMount(() => {
    dateInput = document.getElementById('dateInput') as HTMLInputElement
    calendar = new Datepicker(dateInput, {
      format: getDateFormatString(),
      orientation: 'bottom',
      autohide: true,
      ...(props.firstAllowedDate ? { minDate: shortDateFormatter(props.firstAllowedDate) } : {}),
      ...(props.lastAllowedDate ? { maxDate: shortDateFormatter(props.lastAllowedDate) } : {})
    })
  })

  const validateDate = (value?: string): boolean => {
    if (!value) {
      setDateError('empty')
      return false
    }

    const date = parseLocalDateString(value)

    if (isNaN(date.getTime())) {
      setDateError('invalid_date')
      return false
    }

    if (!props.isDateDisabled && props.otherPeriods.some(period => eqDate(period.fromDate, date))) {
      setDateError('period_exists')
      return false
    }

    if (
      !props.isDateDisabled &&
      (!!(props.lastAllowedDate && gtDate(date, props.lastAllowedDate)) ||
      !!(props.firstAllowedDate && ltDate(date, props.firstAllowedDate)))
    ) {
      setDateError('invalid_date')
      return false
    }

    setDateError('')
    return true
  }

  const validateCogs = (value?: number): boolean => {
    if (value === undefined) {
      setAmountError('empty')
      return false
    }

    if (isNaN(value) || value < 0) {
      setAmountError('invalid_cogs_number')
      return false
    }

    setAmountError('')
    return true
  }

  const handleSubmit = (event: Event): void => {
    event.preventDefault()

    const value = amount()
    validateCogs(value)

    const date = dateInput?.value
    validateDate(date)

    if (amountError() || dateError()) {
      return
    }

    props.submit({
      date: parseLocalDateString(date!),
      amount: value!
    })
  }

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  const hideCalendar = (): void => {
    calendar?.hide()
  }

  return (
    <Dialog maxWidth={'564px'}>
      <div class="p-6 text-gray-800 flex flex-col gap-6">
        <span class="text-base font-bold"><Trans key="ls_cogs_update_costs" /></span>
        <form class="flex flex-col gap-6" onKeyDown={handleKeyDown}>
          <div class="col-span-full">
            <label for="valid-since" class="block text-sm font-medium leading-6 text-gray-900">
              <Trans key="ls_cogs_valid_since" />
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div class={`${props.isDateDisabled ? 'text-gray-400' : 'text-gray-900'} pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4`}>
                <MiniCalendarDays/>
              </div>
              <input id="dateInput"
                     ref={el => { clickOutsideCalendar(el, hideCalendar) }}
                     min={props.firstAllowedDate ? shortDateFormatter(props.firstAllowedDate) : undefined}
                     max={props.lastAllowedDate ? shortDateFormatter(props.lastAllowedDate) : undefined}
                     type="text" name="valid-since" disabled={props.isDateDisabled}
                     value={shortDateFormatter(defaultDate)}
                     onChange={(e) => {
                       const value = (e.target as HTMLInputElement).value
                       validateDate(value)
                     }}
                     class="block w-full !text-sm !rounded-lg !border-gray-300 py-1.5 !pl-12 text-gray-900 placeholder:text-gray-400 shadow-sm focus:!ring-inset focus:!ring-japanese-700 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:border-none"/>
            </div>
            {dateError() && <span class="text-red-600 text-xs"><Trans key={`ls_input_error_${dateError()}`}/></span>}
            <Show when={props.type === 'reconcile'}>
              <p class="mt-2 text-sm leading-6 text-gray-500">
                <Trans key="ls_cogs_date_automatically_selected" />
              </p>
            </Show>
          </div>

          <div>
            <label for="price" class="block text-sm font-medium leading-6 text-gray-900">
              <Trans key="ls_cogs_costs" />
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <input type="number" name="price"
                     value={defaultAmount}
                     min={0}
                     onInput={(e) => {
                       const value = Number((e.target as HTMLInputElement).value)
                       validateCogs(value)
                       setAmount(value)
                     }}
                     class="block w-full !text-sm !rounded-lg !border-gray-300 py-1.5 !pr-7 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:!ring-inset focus:!ring-japanese-700"
                     placeholder="0.00" aria-describedby="price-currency"/>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-700 sm:text-base">$</span>
              </div>
            </div>
            {amountError() && <span class="text-red-600 text-xs"><Trans key={`ls_input_error_${amountError()}`}/></span>}
          </div>
        </form>

      </div>
      <DialogActions>
        <div class="flex justify-between items-center w-full">
          <Show when={props.canBeDeleted && props.delete && props.defaultDate}>{(date) => (
            <button
              onClick={() => { props.delete!(date()) }}
              class="w-fit bg-transparent text-sm text-red-500 hover:text-red-800 duration-200">
              <Trans key="ls_generic_delete"/>
            </button>
          )}</Show>
          <div class="flex gap-4 ml-auto">
            <button
              onClick={props.cancel}
              class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-white text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 duration-200">
              <Trans key="ls_generic_cancel" />
            </button>
            <button
              onClick={(e) => { handleSubmit(e) }}
              class="w-fit flex items-center px-3 py-2 justify-center rounded-md bg-japanese-600 text-sm font-semibold text-white shadow-sm hover:bg-japanese-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-japanese-600 duration-200">
              <Trans key="ls_generic_add" />
            </button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CogsFormDialog
