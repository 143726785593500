import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import { GetEtsyAuthUrlInteractor } from './domain/interactors/get-etsy-auth-url.interactor'
import { GetShopsInteractor } from './domain/interactors/get-shops.interactor'
import { Cached } from '@coris-ts/helpers/utilities'
import { GetRepositoryMapper, PutRepositoryMapper } from '@coris-ts/repository/repository-mapper'
import { AuthUrlEntity } from './data/entities/auth-url.entity'
import {
  provideDefaultArrayNetworkDataSource,
  provideDefaultNetworkDataSource
} from '@coris-ts/repository/data-source/network.data-source'
import {
  SingleDataSourceRepository,
  SingleGetDataSourceRepository
} from '@coris-ts/repository/single-data-source.repository'
import { ShopEntity } from './data/entities/shop.entity'
import { type Shop } from './domain/models/shop'
import { ShopEntityToShopMapper } from './domain/mappers/shop.mappers'
import { ArrayMapper, VoidMapper } from '@coris-ts/repository/mapper/mapper'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { AuthUrlEntityToStringMapper } from './domain/mappers/shop-auth-url.mappers'
import { GetIntegrationAuthUrlInteractor } from './domain/interactors/get-integration-auth-url.interactor'
import { DeleteInteractor } from '@coris-ts/repository/interactor/delete.interactor'
import { DisconnectIntegrationInteractor } from './domain/interactors/disconnect-integration.interactor'
import { GetShopInteractor } from './domain/interactors/get-shop.interactor'
import { GetPrintifyShopsInteractor } from './domain/interactors/get-printify-shops.interactor'
import { type IntegrationShop } from './domain/models/integration-shop'
import { IntegrationShopEntity } from './data/entities/integration-shop.entity'
import {
  IntegrationShopEntityToIntegrationShopMapper
} from './domain/mappers/integration-shop.mappers'
import { SetPrintifyShopInteractor } from './domain/interactors/set-printify-shop.interactor'
import { StartShopConnectionInteractor } from './domain/interactors/start-shop-connection.interactor'
import {
  provideVoidNetworkDataSourceRepository
} from '@coris-ts/repository/single-void-data-source.repository'
import { type Repository } from '@coris-ts/repository/repository'

export interface ShopComponent {
  provideGetEtsyAuthUrl: () => GetEtsyAuthUrlInteractor
  provideGetIntegrationAuthUrl: () => GetIntegrationAuthUrlInteractor
  provideStartShopConnection: () => StartShopConnectionInteractor
  provideGetShops: () => GetShopsInteractor
  provideGetShop: () => GetShopInteractor
  provideDisconnectIntegration: () => DisconnectIntegrationInteractor
  provideGetPrintifyShops: () => GetPrintifyShopsInteractor
  provideSetPrintifyShop: () => SetPrintifyShopInteractor
}

export class ShopDefaultModule implements ShopComponent {
  constructor(private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getAuthUrlRepository(): PutRepositoryMapper<AuthUrlEntity, string> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      AuthUrlEntity
    )
    const repository = new SingleDataSourceRepository(
      dataSource,
      dataSource,
      dataSource
    )

    return new PutRepositoryMapper(
      repository,
      new AuthUrlEntityToStringMapper(),
      new VoidMapper()
    )
  }

  @Cached()
  private getShopRepository(): GetRepositoryMapper<ShopEntity, Shop> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      ShopEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ShopEntityToShopMapper()
    )
  }

  @Cached()
  private getShopArrayRepository(): GetRepositoryMapper<ShopEntity[], Shop[]> {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      ShopEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ArrayMapper(new ShopEntityToShopMapper())
    )
  }

  @Cached()
  private getIntegrationShopArrayRepository(): GetRepositoryMapper<IntegrationShopEntity[], IntegrationShop[]> {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      IntegrationShopEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ArrayMapper(new IntegrationShopEntityToIntegrationShopMapper())
    )
  }

  @Cached()
  public getVoidRepository(): Repository<void> {
    return provideVoidNetworkDataSourceRepository(this.apiRequestService)
  }

  public provideGetEtsyAuthUrl(): GetEtsyAuthUrlInteractor {
    return new GetEtsyAuthUrlInteractor(
      new PutInteractor<string>(this.getAuthUrlRepository())
    )
  }

  public provideGetIntegrationAuthUrl(): GetIntegrationAuthUrlInteractor {
    return new GetIntegrationAuthUrlInteractor(
      new PutInteractor<string>(this.getAuthUrlRepository())
    )
  }

  public provideGetShop(): GetShopInteractor {
    return new GetShopInteractor(
      new GetInteractor<Shop>(this.getShopRepository())
    )
  }

  public provideGetShops(): GetShopsInteractor {
    return new GetShopsInteractor(
      new GetInteractor<Shop[]>(this.getShopArrayRepository())
    )
  }

  public provideDisconnectIntegration(): DisconnectIntegrationInteractor {
    return new DisconnectIntegrationInteractor(
      new DeleteInteractor(this.getVoidRepository())
    )
  }

  public provideGetPrintifyShops(): GetPrintifyShopsInteractor {
    return new GetPrintifyShopsInteractor(
      new GetInteractor<IntegrationShop[]>(this.getIntegrationShopArrayRepository())
    )
  }

  public provideSetPrintifyShop(): SetPrintifyShopInteractor {
    return new SetPrintifyShopInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideStartShopConnection(): StartShopConnectionInteractor {
    return new StartShopConnectionInteractor(
      this.provideGetEtsyAuthUrl(),
      this.provideGetIntegrationAuthUrl()
    )
  }
}
