import type { Component } from 'solid-js'
import { KPIType, type KPIVM } from '../../features/analytics/domain/models/kpi'
import { getKPIVMsInGroup } from '../shared/functions/kpi.functions'
import { KPIGroupId } from '../../shared/models/kpi-group'
import StatsTable from '../shared/components/StatsTable'
import ProgressRatio from '../shared/components/ProgressRatio'
import { Show, createEffect, createSignal } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import TextPlaceholder from '../shared/components/TextPlaceholder'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export interface FinancialBreakdownTableProps {
  kpis: KPIVM[]
}

const FinancialBreakdownTable: Component<FinancialBreakdownTableProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()
  const [totals, setTotals] = createSignal<KPIVM>()

  createEffect(() => {
    if (!(props.kpis?.length > 0)) {
      return
    }

    const total = props.kpis.find(kpi => kpi.id === KPIType.OperatingProfit)!
    setTotals(total)
  })

  return (
    <div class="overflow-hidden flex flex-col items-center justify-center">
      <div class="w-full p-px sm:w-2/3 max-w-2xl flex flex-col gap-4 divide-y">
        <div>
          <StatsTable kpis={getKPIVMsInGroup(props.kpis, KPIGroupId.NetSales)} kpiGroupId={KPIGroupId.NetSales} />
          <StatsTable kpis={getKPIVMsInGroup(props.kpis, KPIGroupId.Cogs)} kpiGroupId={KPIGroupId.Cogs} />
          <StatsTable kpis={getKPIVMsInGroup(props.kpis, KPIGroupId.OperatingExpenses)} kpiGroupId={KPIGroupId.OperatingExpenses} />
        </div>
        <div class="flex justify-between p-4">
          <h1 class="font-semibold text-base sm:text-xl"><Trans key="ls_fin_break_operating_profit" /></h1>
          <Show when={totals()?.value !== undefined} fallback={
            <div class="pr-20">
              <TextPlaceholder widthCss="141px" height="lg" />
            </div>
          }>
            <div class="flex items-center gap-2">
              <span class="font-semibold text-base sm:text-xl">{currencyFormatter(totals()!.value)}</span>
              <ProgressRatio value={totals()?.progressRatio} kpi={totals()!.id}/>
            </div>
          </Show>
        </div>
      </div>
    </div>
  )
}

export default FinancialBreakdownTable
