import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type Product } from '../models/product'
import { type CogsStatus } from '../models/product-cost'
import { type ProductEntity } from '../../data/entities/product.entity'
import { CogsType } from '../models/cogs'
import { CogsTypeEntity } from '../../data/entities/cogs.entity'
import { type ProductCostsWithUnitsEntity } from '../../data/entities/product-costs-with-units.entity'
import { ProductCostsWithUnits } from '../models/product-costs-with-units'

export class ProductCostsWithUnitsEntityToProductCostsWithUnitsMapper implements Mapper<ProductCostsWithUnitsEntity, ProductCostsWithUnits> {
  constructor(
    private readonly productMapper: Mapper<ProductEntity, Product>
  ) {
  }

  public map(from: ProductCostsWithUnitsEntity): ProductCostsWithUnits {
    const isPrintful = from.product.cogs_type === CogsTypeEntity.Printful
    const isPrintify = from.product.cogs_type === CogsTypeEntity.Printify
    const cogsType = isPrintful
      ? CogsType.Printful
      : isPrintify
        ? CogsType.Printify
        : CogsType.None

    return new ProductCostsWithUnits(
      from.sku,
      from.cogs_status as CogsStatus,
      from.multiple_cogs,
      from.number_of_products,
      from.min_price,
      from.max_price,
      cogsType,
      this.productMapper.map(from.product),
      from.units_sold
    )
  }
}
