import type { Component } from 'solid-js'
import { type IconProps } from './icon'

const MiniArrowRight: Component<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class={`w-${props.size ?? 6} h-${props.size ?? 6}`}>
      <path fill-rule="evenodd"
            d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
            clip-rule="evenodd"/>
    </svg>
  )
}

export default MiniArrowRight
