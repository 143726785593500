import type { Component } from 'solid-js'
import { createEffect, createSignal, For, Show } from 'solid-js'
import { MainModule } from '../../features/main-module'
import TablePagination from '../shared/components/TablePagination'
import CogsIssuesTableRow from './CogsIssuesTableRow'
import SearchBar from '../shared/components/SearchBar'
import { Trans } from '@mbarzda/solid-i18next'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import Table, { type TableColumn, TableStatus } from '../shared/components/Table'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { useShop } from '../../shared/providers/shop.provider'
import { usePeriods } from '../../shared/providers/period.provider'
import { useError } from '../../shared/providers/error.provider'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { useLocation } from '@solidjs/router'
import CloudArrowUp from '../../assets/heroicons/CloudArrowUp'
import CogsBulkUploadDialog from '../cogs/CogsBulkUploadDialog'
import ProcessingCogsBanner from '../cogs/ProcessingCogsBanner'
import NoMissingCogs from '../shared/components/NoMissingCogs'
import SolidExclamationTriangle from '../../assets/heroicons/SolidExclamationTriangle'
import { type ProductCostsWithUnits } from '../../features/product/domain/models/product-costs-with-units'
import LockedPeriodDialog from '../shared/components/LockedPeriodDialog'
import { useUser } from '../../public/auth/user-provider'

const CogsIssues: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [isUploadingCogs, setIsUploadingCogs] = createSignal<boolean>(false)
  const [products, setProducts] = createSignal<PaginationOffsetLimit<ProductCostsWithUnits>>()
  const [page, setPage] = createSignal(0)

  const location = useLocation()

  const { selectedShop } = useShop()
  const { periods, isProPeriod } = usePeriods()
  const { setError } = useError()
  const { userPurchases } = useUser()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'sku' },
    { id: 'listing_title' },
    { id: 'variant' },
    { id: 'price', withHint: true },
    { id: 'units_sold', withHint: true },
    { id: 'cogs', withHint: true }
  ]

  const getCogsIssues = MainModule.getProductComponent().provideGetCogsIssues()

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getCogsIssues.execute(
        shop.id,
        periods(),
        offset,
        DEFAULT_PAGE_SIZE,
        searchTerm()
      ), location
    )
      .then((products) => {
        setProducts(products)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showNoResults = (): boolean => {
    return products()?.values.length === 0
  }

  createEffect(() => {
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), false, false, products()?.values)
  }

  let cogsStatusPullingAction = (): void => undefined
  const cogsUpdatedAction = (action: () => void): void => { cogsStatusPullingAction = action }

  const cogsUploaded = (): void => {
    setIsUploadingCogs(false)
    cogsStatusPullingAction()
    refreshTable(page())
  }

  return (
    <div class="flex flex-col gap-4 relative">
      <Show when={userPurchases()}>{(purchases) => (
        <Show when={isProPeriod() && !purchases().isPro}>
          <LockedPeriodDialog />
        </Show>
      )}</Show>
      <ProcessingCogsBanner refresh={cogsUpdatedAction} />
      <div class="flex justify-between">
        <h1 class="page-title">
          <SolidExclamationTriangle/><Trans key="ls_cogs_issues_title"/>
        </h1>
        <button onClick={() => { setIsUploadingCogs(true) }}
                class="flex items-center gap-2 justify-center w-fit rounded-md !bg-japanese-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:!bg-japanese-800 transition duration-200">
          <CloudArrowUp size={5}/>
          <Trans key="Import COGS"/>
        </button>
      </div>
      <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title_sku"/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table columns={columns} status={getTableStatus} tableId="cogs_issues">
              <For each={products()?.values}>{(product) => (
                <CogsIssuesTableRow product={product}/>
              )}</For>
              <Show when={showNoResults()}>
                <td class="h-60">
                  <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                    <NoMissingCogs />
                  </div>
                </td>
              </Show>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus()) && !showNoResults()}>
            <TablePagination totalCount={products()?.size ?? 0} page={page} setPage={setPage}/>
          </Show>
        </div>
      </div>
      <Show when={isUploadingCogs()}>
        <CogsBulkUploadDialog cancel={() => setIsUploadingCogs(false)} uploaded={cogsUploaded} />
      </Show>
    </div>
  )
}

export default CogsIssues
