import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type ProductReport } from '../models/product'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { GetProductReportsQuery } from '../../data/queries/get-product-reports.query'
import { type SortDirection } from '../../../../shared/models/sort-direction'

export class GetProductReportsInteractor {
  constructor(
    private readonly getProductReports: GetInteractor<PaginationOffsetLimit<ProductReport>>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    orderBy?: string,
    searchTerm?: string
  ): Promise<PaginationOffsetLimit<ProductReport>> {
    const sortBy = orderBy?.split(':')[0] as keyof ProductReport
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.getProductReports.execute(
      new GetProductReportsQuery(shopId, periods.current.from, periods.current.to, periods.prev.from, offset, limit, searchTerm, sortBy, sortDirection)
    )
  }
}
