import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type Credit } from '../models/credit'
import { GetAvailableCreditQuery } from '../../data/queries/get-available-credit.query'

export class GetAvailableCreditInteractor {
  constructor(
    private readonly getCredit: GetInteractor<Credit[]>
  ) {
  }

  public async execute(): Promise<Credit[]> {
    return await this.getCredit.execute(new GetAvailableCreditQuery())
  }
}
