import {
  type Accessor,
  createSignal,
  onMount,
  Show,
  type Component,
  onCleanup
} from 'solid-js'
import { clickOutside } from '../../shared/directives/click-outside'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import { Trans } from '@mbarzda/solid-i18next'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import { type SKUProductReport } from '../../features/product/domain/models/product'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  findScrollableParent,
  type Size
} from '../../shared/helpers/dom.functions'
import ListingListTooltip from './ListingListTooltip'
import { useMeasureComponent } from '../shared/functions/use-measure'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'

export interface ProductSkuCellProps {
  readonly product: SKUProductReport
}

const ProductSkuCell: Component<ProductSkuCellProps> = (props) => {
  const [isTooltipOpen, setIsTooltipOpen] = createSignal(false)
  const product = props.product
  const numListings = 1

  let buttonRef: HTMLButtonElement | null = null
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  onMount(() => {
    if (buttonRef) {
      tooltipSize = useMeasureComponent(<ListingListTooltip listing={product.product.listing} numListings={numListings} />)

      const scrollParent = findScrollableParent(buttonRef)
      if (scrollParent) {
        scrollParent.addEventListener('scroll', hideTooltip)
        window.addEventListener('resize', hideTooltip)

        onCleanup(() => {
          scrollParent.removeEventListener('scroll', hideTooltip)
          window.removeEventListener('resize', hideTooltip)
        })
      }
    }
  })

  const showTooltip = (): void => {
    if (!buttonRef || !tooltipSize?.()) return

    const elementRect = buttonRef.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <ListingListTooltip listing={product.product.listing} numListings={numListings} position={position}/>
    )
    setIsTooltipOpen(true)
  }

  const hideTooltip = (): void => {
    if (!isTooltipOpen()) return
    removeTooltip()
    removeTooltip = () => {}
    setIsTooltipOpen(false)
  }

  const onListingsClick = (): void => {
    if (isTooltipOpen()) {
      hideTooltip()
    } else {
      showTooltip()
    }
  }

  return (
    <>
      <A href={AppRoutes.Product(product.product.id)}>
        <div class="mb-1">{product.sku}</div>
      </A>
      <Show when={!product.sku}>
        <div class="mb-1 flex items-center gap-1">
          <span class="text-red-500"><MiniExclamationCircle /></span><Trans key="ls_shared_wo_sku" />
        </div>
      </Show>
      <div class="text-xs text-blue-500 relative" ref={el => { clickOutside(el, hideTooltip) }}>
        <Show when={numListings === 1}>
          <a href={product.product.listing.url} target="_blank" class="text-gray-500 flex items-center gap-1">
            <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy" />
          </a>
        </Show>
        <Show when={numListings > 1}>
          <button
            ref={el => (buttonRef = el)}
            onClick={ onListingsClick }
            class="flex items-center gap-1">
            <MiniChevronDown size={3} />{numListings} <Trans key="ls_catalog_listings" />
          </button>
        </Show>
      </div>
    </>
  )
}

export default ProductSkuCell
