import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { UploadCogsInBulkQuery } from '../../data/queries/upload-cogs-in-bulk.query'

export class UploadCogsInBulkInteractor {
  constructor(
    private readonly uploadCogsInBulk: PutInteractor<string>
  ) {
  }

  public async execute (
    shopId: number,
    file: File
  ): Promise<string> {
    return await this.uploadCogsInBulk.execute(undefined, new UploadCogsInBulkQuery(shopId, file))
  }
}
