import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import GirlQuestion from '../../assets/images/girl_question_mark.png'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import { type IntegrationType } from '../../features/shop/domain/models/shop'

export interface WrongStoreDialogProps {
  readonly cancel: () => void
  readonly retry: () => void
  readonly integration: IntegrationType
}

const WrongStoreDialog: Component<WrongStoreDialogProps> = (props) => {
  return (
    <Dialog maxWidth={'434px'}>
      <div class="py-6 px-8 mx-auto text-center text-black flex gap-2 flex-col items-center">
        <img src={GirlQuestion} class="h-24" alt="question"/>
        <span class="text-base text-gray-900 font-bold"><Trans key="ls_integrations_right_store"/></span>
        <span class="text-sm text-gray-500"><Trans key={`ls_integrations_right_store_${props.integration}_description`}/></span>
      </div>

      <DialogActions>
        <button onClick={props.cancel} class="btn btn--white">
          <Trans key="ls_generic_cancel"/>
        </button>
        <button onClick={props.retry} class="btn btn--primary">
          <Trans key="ls_generic_try_again"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default WrongStoreDialog
