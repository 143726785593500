import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { ListingReport } from '../models/listing'
import { type ListingReportEntity } from '../../data/entities/listing.entity'
import { KPIType } from '../../../analytics/domain/models/kpi'
import { KPIEntityToKPIMapper } from '../../../analytics/domain/mappers/kpi.mappers'
import {
  getNetSalesBreakdownKpis,
  getOpExpensesBreakdownKpis
} from '../../../../private/shared/functions/kpi.functions'

export class ListingReportEntityToListingReportMapper implements Mapper<ListingReportEntity, ListingReport> {
  public map(from: ListingReportEntity): ListingReport {
    const KPIS = from.data_points
    const UnitsSold = KPIEntityToKPIMapper(KPIS[KPIType.UnitsSold], KPIType.UnitsSold)

    return new ListingReport(
      from.listing.listing_id,
      from.listing.url,
      {
        sm: from.listing.image_urls.small,
        md: from.listing.image_urls.medium,
        lg: from.listing.image_urls.large
      },
      from.listing.title,
      from.listing.state,
      from.missing_cogs,
      KPIEntityToKPIMapper(KPIS[KPIType.Orders], KPIType.Orders),
      UnitsSold,
      KPIEntityToKPIMapper(KPIS[KPIType.NetSales], KPIType.NetSales, getNetSalesBreakdownKpis(KPIS), UnitsSold),
      KPIEntityToKPIMapper(KPIS[KPIType.Cogs], KPIType.Cogs),
      KPIEntityToKPIMapper(KPIS[KPIType.OperatingExpenses], KPIType.OperatingExpenses, getOpExpensesBreakdownKpis(KPIS), UnitsSold),
      KPIEntityToKPIMapper(KPIS[KPIType.OperatingProfit], KPIType.OperatingProfit),
      KPIEntityToKPIMapper(KPIS[KPIType.Profit], KPIType.Profit),
      KPIEntityToKPIMapper(KPIS[KPIType.Roi], KPIType.Roi),
      KPIEntityToKPIMapper(KPIS[KPIType.BreakEvenRoas], KPIType.BreakEvenRoas)
    )
  }
}
