export class SubscriptionChange {
  constructor(
    public readonly amountDueToday: number,
    public readonly creditToBalance: number,
    public readonly currency: string,
    public readonly creditUsed: number,
    public readonly proratedCost: number
  ) {
  }
}
