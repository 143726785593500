import { createSignal, For, onMount, Show } from 'solid-js'
import type { JSX } from 'solid-js'
import { clickOutside } from '../../../shared/directives/click-outside'
import MiniChevronDown from '../../../assets/heroicons/MiniChevronDown'
import { Trans } from '@mbarzda/solid-i18next'

export interface SelectOption {
  id: string | number
  value: string | number
}

export interface SingleSelectProps {
  readonly options: SelectOption[]
  readonly selectOption: (option: string | number | undefined) => void
  readonly placeholder?: string
}

const SingleSelect = (props: SingleSelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = createSignal<boolean>(false)
  const [selectedOption, setSelectedOption] = createSignal<SelectOption | undefined>()

  onMount(() => {
    const startingValue = props.placeholder ? undefined : (props.options && props.options.length > 0) ? props.options[0] : undefined
    setSelectedOption(startingValue)
    props.selectOption(startingValue?.id)
  })

  const optionSelected = (option: SelectOption): void => {
    setSelectedOption(option)
    props.selectOption(option.id)
    setIsOpen(false)
  }

  const toggleOpen = (): boolean => setIsOpen(!isOpen())

  return (
    <div class="relative w-full pl-4 pr-2 ring-1 ring-inset ring-gray-300 rounded-md h-9 flex items-center">
      <button onClick={toggleOpen} type="button" class="cursor-pointer relative w-full bg-white py-1.5 pr-8 text-left text-gray-900 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
        <Show when={props.placeholder && !selectedOption()}>
          <span class="block truncate text-sm"><Trans key={props.placeholder!}/></span>
        </Show>
        <Show when={selectedOption()}>{(option) => (
          <span class="block truncate text-sm"><Trans key={`${option().value}`}/></span>
        )}</Show>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
          <MiniChevronDown />
        </span>
      </button>
      <Show when={isOpen()}>
        <ul ref={el => { clickOutside(el, () => setIsOpen(false)) }}
            class="right-0 left-0 top-10 absolute flex flex-col gap-4 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option">
          <For each={props.options}>{(option) => (
            <li onClick={() => { optionSelected(option) }} class="text-gray-900 cursor-pointer text-sm relative select-none px-4" role="option">
              <span class="font-normal block truncate"><Trans key={`${option.value}`} /></span>
            </li>
          )}</For>
        </ul>
      </Show>
    </div>
  )
}

export default SingleSelect
