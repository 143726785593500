import type { Component } from 'solid-js'
import type { IconProps } from './icon'

export interface ChevronUpDownProps extends IconProps {
  direction?: 'up' | 'down'
}

const ChevronUpDown: Component<ChevronUpDownProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class={`w-${props.size ?? 5} h-${props.size ?? 5}`}>
      <path fill-rule="evenodd"
            d="M10.53 3.47a.75.75 0 0 0-1.06 0L6.22 6.72a.75.75 0 0 0 1.06 1.06L10 5.06l2.72 2.72a.75.75 0 1 0 1.06-1.06l-3.25-3.25Z"
            fill={`${props.direction === 'up' ? 'white' : 'currentColor'}`} clip-rule="evenodd"/>
      <path fill-rule="evenodd"
            d="M6.22 13.28l3.25 3.25a.75.75 0 0 0 1.06 0l3.25-3.25a.75.75 0 1 0-1.06-1.06L10 14.94l-2.72-2.72a.75.75 0 0 0-1.06 1.06Z"
            fill={`${props.direction === 'down' ? 'white' : 'currentColor'}`} clip-rule="evenodd"/>
    </svg>
  )
}

export default ChevronUpDown
