import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { type Subscription } from '../models/subscription'
import { CancelSubscriptionQuery } from '../../data/queries/cancel-subscription.query'

export class CancelSubscriptionInteractor {
  constructor(
    private readonly cancelSubscription: PutInteractor<Subscription>
  ) {
  }

  public async execute(subscriptionId: string): Promise<Subscription> {
    return await this.cancelSubscription.execute(undefined, new CancelSubscriptionQuery(subscriptionId))
  }
}
