import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpBody, type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class UploadCogsInBulkQuery extends PostNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly file: File
  ) {
    super(ApiEndpoint.ProductCogsBulkUpdate)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId,
      'Content-Type': 'multipart/form-data'
    }
  }

  public get body(): HttpBody {
    const formData = new FormData()
    formData.append('file', this.file)
    return formData
  }
}
