import { type KPI } from '../../../analytics/domain/models/kpi'

export class Breakdown {
  constructor (
    public readonly KPIS: KPI[],
    public readonly total: KPI,
    public readonly unitsKPI: KPI
  ) {
  }
}
