import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { ReconcileQuery } from '../../data/queries/reconcile.query'

export class ReconcileCogsInteractor {
  constructor(
    private readonly putProduct: PutInteractor<void>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number,
    amount: number
  ): Promise<void> {
    await this.putProduct.execute(undefined, new ReconcileQuery(shopId, productId, amount))
  }
}
