import { type Component, onCleanup, onMount } from 'solid-js'
import { MainModule } from '../features/main-module'
import LottieAnimation from '../private/shared/components/LottieAnimation'
import logoAnimation from '../assets/animations/logo-animated.json'
import logoLetters from '../assets/images/profit-tree-logo-letters.svg'
import { useNavigate } from '@solidjs/router'
import { AppRoutes } from '../shared/app-routes'
import { Trans } from '@mbarzda/solid-i18next'
import { useUser } from './auth/user-provider'

const SubscriptionLoadingScreen: Component = () => {
  const navigate = useNavigate()
  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()
  const getCurrentUser = MainModule.getAuthComponent().provideGetCurrentUser()
  const { setCurrentUser, setUserPurchases } = useUser()
  let timerHandle: NodeJS.Timeout | undefined
  let intervalHandle: NodeJS.Timeout | undefined

  onMount(() => {
    timerHandle = setTimeout(() => {
      intervalHandle = setInterval(async () => {
        try {
          const purchases = await getPurchases.execute()
          if (purchases.subscriptions.length) {
            const user = await getCurrentUser.execute()
            setCurrentUser(user)
            setUserPurchases(purchases)
            clearResources()
            navigate(AppRoutes.Dashboard())
          }
        } catch (_) {}
      }, 1000)
    }, 3000)

    onCleanup(() => {
      clearResources()
    })

    const clearResources = (): void => {
      clearTimeout(timerHandle)
      clearInterval(intervalHandle)
    }
  })

  return (
    <div class="w-full bg-blue_gray-100 flex flex-col justify-center gap-6 items-center h-full">
      <div class="w-full bg-blue_gray-100 flex items-center gap-4 justify-center">
        <LottieAnimation animationData={logoAnimation} width="35px"/>
        <img src={logoLetters} alt="Profit Tree" class="2-36"/>
      </div>
      <span class="text-sm text-gray-500"><Trans key="ls_dashboard_preparing_account"/></span>
    </div>
  )
}

export default SubscriptionLoadingScreen
