import type { Component } from 'solid-js'
import { type ListingReport } from '../../../features/product/domain/models/listing'
import ImageCell from '../../shared/components/ImageCell'
import ValueProgressCell from '../../shared/components/ValueProgressCell'
import { type Product } from '../../../features/product/domain/models/product'
import ViewProductListingOnEtsyCell from './ViewProductListingOnEtsyCell'
import NoListing from '../../../assets/images/no-listing-sm.svg'

export interface ListingTableRowProps {
  readonly listing: ListingReport
  readonly product: Product
  readonly isLoading: () => boolean
}

const ProductListingTableRow: Component<ListingTableRowProps> = (props) => {
  const listing = props.listing
  const listingImage = props.listing.isDeleted() ? NoListing : listing.imgUrls.sm

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 text-sm font-medium sm:pl-6 min-w-[88px]">
        <ImageCell imgUrl={listingImage} />
      </td>
      <td class="tableCell min-w-[240px]">
        <ViewProductListingOnEtsyCell listing={listing} variantName={props.product.variantName}/>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.unitsSold} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.netSales} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.cogs} warning={listing.missingCogs} />
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueProgressCell kpi={listing.opExpenses} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.opProfit} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.profitMargin} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.roi} />
      </td>
    </tr>
  )
}

export default ProductListingTableRow
