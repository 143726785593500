import { type Component } from 'solid-js'
import SectionCard from './SectionCard'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import MiniCube from '../../assets/heroicons/MiniCube'
import { Trans } from '@mbarzda/solid-i18next'

const UnsubscribedSectionCard: Component = () => {
  return (
    <SectionCard icon={<MiniCube/>} title={'ls_generic_plan'} styleNumColumns='half'>
      <div class="flex flex-col gap-6">
        <div class="flex justify-between items-center">
          <div class="flex flex-col gap-2">
            <span class="font-semibold"><Trans key="ls_generic_unsubscribed"/></span>
            <span class="text-sm"><Trans key="ls_subscription_select_to_start"/></span>
          </div>
          <A href={AppRoutes.Payment()} class="text-white bg-japanese-600 flex items-center justify-center h-9 px-3 rounded-md">
            <Trans key="ls_subscription_select_plan"/>
          </A>
        </div>
      </div>
    </SectionCard>
  )
}

export default UnsubscribedSectionCard
