import { type KPI } from '../../../analytics/domain/models/kpi'
import { type Listing } from './listing'
import { type Cogs, type CogsType } from './cogs'

export class Product {
  constructor (
    public readonly id: number,
    public readonly sku: string | undefined,
    public readonly variantName: string,
    public readonly listing: Listing,
    public readonly cogsType: CogsType,
    public readonly price?: number,
    public readonly quantity?: number,
    public readonly isAvailable?: boolean,
    public readonly isDeleted?: boolean,
    public readonly cogs?: Cogs,
    public readonly firstOrderDate?: Date
  ) {
  }

  public isListingDeleted (): boolean {
    return this.listing.isDeleted()
  }
}

export class ProductReport extends Product {
  constructor (
    id: number,
    sku: string | undefined,
    variantName: string,
    listing: Listing,
    cogsType: CogsType,
    price: number | undefined,
    quantity: number | undefined,
    isAvailable: boolean | undefined,
    isDeleted: boolean | undefined,
    cogs: Cogs | undefined,
    firstOrderDate: Date | undefined,
    public readonly missingCogs: boolean,
    public readonly cogsKpi: KPI,
    public readonly discounts: KPI,
    public readonly grossSales: KPI,
    public readonly listingFees: KPI,
    public readonly netSales: KPI,
    public readonly opExpenses: KPI,
    public readonly opProfit: KPI,
    public readonly orders: KPI,
    public readonly processingFees: KPI,
    public readonly profitMargin: KPI,
    public readonly refunds: KPI,
    public readonly roi: KPI,
    public readonly shippingCollected: KPI,
    public readonly shippingCosts: KPI,
    public readonly transactionFees: KPI,
    public readonly unitsSold: KPI
  ) {
    super(id, sku, variantName, listing, cogsType, price, quantity, isAvailable, isDeleted, cogs, firstOrderDate)
  }
}

export class SKUProductReport {
  constructor (
    public readonly sku: string | undefined,
    public readonly product: Product,
    public readonly cogsType: CogsType,
    public readonly missingCogs: boolean,
    public readonly unitsSold: KPI,
    public readonly netSales: KPI,
    public readonly cogsKPI: KPI,
    public readonly opExpenses: KPI,
    public readonly opProfit: KPI,
    public readonly profitRatio: KPI,
    public readonly roi: KPI
  ) {
  }
}
