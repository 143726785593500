import {
  createContext,
  useContext,
  type JSX,
  createSignal,
  type Accessor,
  type Setter
} from 'solid-js'

interface CogsCounterContextType {
  readonly cogsCounter: Accessor<number | undefined>
  readonly setCogsCounter: Setter<number | undefined>
}

const [cogsCounter, setCogsCounter] = createSignal<number | undefined>()

const defaultContextValue: CogsCounterContextType = {
  cogsCounter,
  setCogsCounter
}

const CogsCounterContext = createContext<CogsCounterContextType>(defaultContextValue)

export const CogsCounterProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <CogsCounterContext.Provider value={defaultContextValue}>
      {props.children}
    </CogsCounterContext.Provider>
  )
}

export const useCogsCounter = (): CogsCounterContextType => {
  return useContext(CogsCounterContext)
}
