import { type Component, createEffect, createSignal, Show } from 'solid-js'
import { For } from 'solid-js'
import { type KPIVM } from '../../../features/analytics/domain/models/kpi'
import { type KPIGroupId } from '../../../shared/models/kpi-group'
import StatRow from './StatRow'
import ProgressRatio from './ProgressRatio'
import { Trans } from '@mbarzda/solid-i18next'
import TextPlaceholder from './TextPlaceholder'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface StatsTableProps {
  readonly kpiGroupId: KPIGroupId
  readonly kpis: KPIVM[]
}

const StatsTable: Component<StatsTableProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  const [totals, setTotals] = createSignal<KPIVM>()
  const [kpis, setKpis] = createSignal<KPIVM[]>()

  createEffect(() => {
    if (!(props.kpis?.length > 0)) {
      return
    }

    setTotals(props.kpis[props.kpis.length - 1])
    setKpis(props.kpis.slice(0, -1))
  })

  return (
    <div class="w-full">
      <h2 class="text-base mb-4 font-semibold text-gray-900"><Trans key={`ls_fin_break_title_${props.kpiGroupId}`} /></h2>
      <ul role="list" class="text-sm divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl">
        <For each={kpis()}>{(kpi) => (
          <StatRow kpi={kpi} />
        )}</For>
      </ul>
      <div class="text-sm flex justify-end gap-2 items-center p-4">
        <span class="text-sm text-gray-500 mr-4"><Trans key="ls_generic_total" /></span>
        <Show when={totals()?.value !== undefined} fallback={
          <div class="pr-20">
            <TextPlaceholder widthCss="100px"/>
          </div>
        }>
          <>
            <span class="font-bold">{formatNumericValue(totals()!.type!, totals()!.value)}</span>
            <ProgressRatio value={totals()!.progressRatio} kpi={totals()!.id}/>
          </>
        </Show>
      </div>
    </div>
  )
}

export default StatsTable
