import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { type ListingReport } from '../../../product/domain/models/listing'
import { GetListingReportQuery } from '../../data/queries/get-listing-report.query'

export class GetListingReportInteractor {
  constructor(
    private readonly getListingReport: GetInteractor<ListingReport>
  ) {
  }

  public async execute (
    shopId: number,
    listingId: number,
    periods: PeriodsState
  ): Promise<ListingReport> {
    return await this.getListingReport.execute(
      new GetListingReportQuery(shopId, listingId, periods.current.from, periods.current.to, periods.prev.from)
    )
  }
}
