import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type PeriodTime } from '../../../shared/period-state'
import { type Timeseries } from '../models/timeseries'
import { type TimeseriesResolution } from '../../../../private/shared/analytics.vm'
import {
  GetListingAnalyticsTimeseriesQuery
} from '../../data/queries/get-listing-analytics-timeseries.query'

export class GetListingAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution, listingId?: number): Promise<Timeseries> {
    if (!listingId) {
      throw new Error('listingId is required')
    }
    return await this.getAnalytics.execute(new GetListingAnalyticsTimeseriesQuery(shopId, listingId, period.from, period.to, resolution))
  }
}
