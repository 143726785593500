import {
  type Accessor,
  type Component,
  createEffect,
  createSignal,
  For,
  type Setter,
  Show
} from 'solid-js'
import { type StatCustomStyles } from './Stat'
import styles from './PerUnitMainStats.module.scss'
import { KPIType, type KPIVM } from '../../../features/analytics/domain/models/kpi'
import type { Breakdown } from '../../../features/product/domain/models/breakdown'
import BreakdownBox from './BreakdownBox'
import PerUnitStat from './PerUnitStat'

export interface PerUnitMainKPIsProps {
  readonly kpis: KPIVM[]
  readonly selectedKPI: Accessor<KPIType>
  readonly setSelectedKPI: Setter<KPIType>
  readonly units?: number
  readonly missingCogs?: boolean
}

const PerUnitMainKPIs: Component<PerUnitMainKPIsProps> = (props) => {
  const { selectedKPI, setSelectedKPI } = props
  const [hovered, setHovered] = createSignal<KPIType>()

  const isKPISelected = (kpi: KPIVM): boolean => selectedKPI() === kpi.id
  const isLastElement = (kpi: KPIVM): boolean => kpi === props.kpis[props.kpis.length - 1]

  const customKPIStyles: StatCustomStyles = {
    label: styles.customResponsiveSize__label,
    value: styles.customResponsiveSize__value
  }

  createEffect(() => {
    void props.kpis
    setHovered(undefined)
  })

  createEffect(() => {
    const kpiType = hovered()
    if (!kpiType) return

    const kpiContainer = document.getElementById(kpiType)
    const kpiBreakdown = document.getElementById(`${kpiType}_breakdown`)

    if (kpiContainer && kpiBreakdown) {
      const { bottom, left } = kpiContainer.getBoundingClientRect()

      kpiBreakdown.style.left = `${left + window.scrollX}px`
      kpiBreakdown.style.top = `${bottom - 8 + window.scrollY}px`
    }
  })

  return (
    <div>
      <dl class="grid rounded-lg overflow-hidden bg-white shadow grid-cols-1 sm:grid-cols-4">
        <For each={props.kpis}>{(kpi) => (
          <>
            <div
              onClick={() => { setSelectedKPI(kpi.id) }}
              onMouseEnter={() => setHovered(kpi.id)}
              onMouseLeave={() => { setHovered(undefined) }}
              class={`${styles.stepContainer} cursor-pointer`}
              id={kpi.id}
            >
              <div class={`${isKPISelected(kpi) ? 'bg-gray-900 text-white' : 'bg-white hover:bg-gray-100 transition duration-300'} ${styles.step} justify-center relative`}>
                <div class={`${isLastElement(kpi) ? (kpi.value?.isPositive() ? 'text-green-500' : 'text-red-500') : ''} max-w-xxs w-full uppercase`}>
                  <PerUnitStat customStyles={customKPIStyles} stat={kpi} units={props.units} missingCogs={kpi.id === KPIType.Cogs && props.missingCogs}/>
                </div>
              </div>
            </div>
            <Show when={hovered() === kpi.id && kpi.breakdown}>{(breakdown: Accessor<Breakdown>) => (
              <div id={`${kpi.id}_breakdown`} class="absolute">
                <BreakdownBox breakdown={breakdown()} />
              </div>
            )}</Show>
          </>
        )}</For>
      </dl>
    </div>
  )
}

export default PerUnitMainKPIs
