import type { Component } from 'solid-js'

export interface TablePaginationCellProps {
  readonly pageNum?: number
  readonly onClick: (page: number) => void
  readonly isSelected?: boolean
  readonly onlyInXL?: true
}

const TablePaginationCell: Component<TablePaginationCellProps> = (props) => {
  const handleClick = (): void => {
    if (props.pageNum) {
      props.onClick(props.pageNum)
    }
  }

  return (
    <button onClick={handleClick}
            class="relative w-[3.25em] justify-center items-center px-4 py-2 text-sm font-semibold md:inline-flex"
            classList={{
              'hidden md:inline-flex': props.onlyInXL,
              'z-10 bg-japanese-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600': props.isSelected,
              'text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ring-1 ring-inset ring-gray-300': !props.isSelected
            }}
    >
      {props.pageNum ?? '...'}
    </button>
  )
}

export default TablePaginationCell
