import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class ReconcileQuery extends PostNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly productId: number,
    private readonly amount: number
  ) {
    super(ApiEndpoint.ReconcileCogs)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      productId: this.productId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      amount_per_unit: this.amount
    }
  }
}
