import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import { Cached } from '@coris-ts/helpers/utilities'
import { GetRepositoryMapper } from '@coris-ts/repository/repository-mapper'
import { provideDefaultNetworkDataSource } from '@coris-ts/repository/data-source/network.data-source'
import { SingleGetDataSourceRepository } from '@coris-ts/repository/single-data-source.repository'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { TaskStatusEntity } from './data/entities/task-status.entity'
import { type TaskStatus } from './domain/models/task-status'
import { TaskStatusEntityToTaskStatusMapper } from './domain/mappers/task-status.mappers'
import { GetBulkUpdateStatusInteractor } from './domain/interactors/get-bulk-update-status.interactor'

// it's 2025 and we're not using this? remove this module
export interface SystemComponent {
  provideGetBulkUpdateStatusStatus: () => GetBulkUpdateStatusInteractor
}

export class SystemDefaultModule implements SystemComponent {
  constructor(private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getTaskStatusRepository(): GetRepositoryMapper<TaskStatusEntity, TaskStatus> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      TaskStatusEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new TaskStatusEntityToTaskStatusMapper()
    )
  }

  public provideGetBulkUpdateStatusStatus(): GetBulkUpdateStatusInteractor {
    return new GetBulkUpdateStatusInteractor(
      new GetInteractor<TaskStatus>(this.getTaskStatusRepository())
    )
  }
}
