import { type Component, createSignal, For } from 'solid-js'
import { KPIType } from '../../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import { getKPIVMsInGroup } from '../../shared/functions/kpi.functions'
import { KPIGroupId } from '../../../shared/models/kpi-group'
import StatsGroup from '../../shared/components/StatsGroup'
import { AnalyticsConfig } from '../../shared/analytics.vm'
import AnalyticsChart from '../../shared/components/AnalyticsChart'
import PerUnitMainStats from '../../shared/components/PerUnitMainStats'
import ListingSummaryBox from './ListingSummaryBox'
import LockedListingsDialog from '../../shared/components/LockedListingsDialog'
import { getRandListing } from '../MockedListings'
import { MockedTimeSeriesInteractor } from '../../shared/functions/mocked-timeseries.interactor'
import ShoppingBagSolid from '../../../assets/heroicons/ShoppingBagSolid'
import SearchBar from '../../shared/components/SearchBar'
import ProductFilters from '../../shared/components/ProductFilters'
import Table, { type TableColumn, TableStatus } from '../../shared/components/Table'
import ListingProductTableRow from './ListingProductTableRow'
import TablePagination from '../../shared/components/TablePagination'
import { ProductFilterSorting, ProductSorting } from '../../../features/shared/product-sorting'
import { PRODUCTS_REPORTS } from '../MockedProductReports'

const ListingLocked: Component = () => {
  const listing = getRandListing()
  const kpis = [
    listing.cogs,
    listing.unitsSold,
    listing.roi,
    listing.opExpenses,
    listing.opProfit,
    listing.profitMargin,
    listing.breakEvenRoas,
    listing.orders,
    listing.netSales
  ]
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [selectedKpiType, setSelectedKpiType] = createSignal(KPIType.OperatingProfit)
  const getTimeseries = new MockedTimeSeriesInteractor()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'variation' },
    { id: 'sku' },
    { id: KPIType.Orders, withHint: true, sortable: true },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true }
  ]

  const products = PRODUCTS_REPORTS

  return (
    <div class="relative">
      <LockedListingsDialog />
      <h1 class="mb-4 page-title"><Trans key="ls_listing_title"/></h1>
      <div class="flex flex-col gap-9">
        <ListingSummaryBox listing={listing}/>
        <div class="grid gap-9 content-center">
          <PerUnitMainStats kpis={getKPIVMsInGroup(kpis, KPIGroupId.Main)}
                            selectedKPI={selectedKpiType}
                            setSelectedKPI={setSelectedKpiType} units={listing?.unitsSold.value}
                            missingCogs={listing?.missingCogs}/>
          <AnalyticsChart kpi={listing.netSales} getTimeseriesAction={getTimeseries}
                          entityId={listing?.id}/>
          <div class="sm:hidden flex flex-col gap-6">
            <StatsGroup config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <StatsGroup config={AnalyticsConfig.listing_orders!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.ListingOrders)} color="blue"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <StatsGroup config={AnalyticsConfig.listing_advertisement!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.ListingAdvertisement)}
                        color="purple"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
          <div class="hidden sm:flex flex-col gap-6">
            <StatsGroup style={'row'} config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis, KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <div class="flex gap-6">
              <StatsGroup style={'row'} config={AnalyticsConfig.listing_orders!}
                          kpis={getKPIVMsInGroup(kpis, KPIGroupId.ListingOrders)} color="blue"
                          selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

              <StatsGroup style={'row'} config={AnalyticsConfig.listing_advertisement!}
                          kpis={getKPIVMsInGroup(kpis, KPIGroupId.ListingAdvertisement)}
                          color="purple"
                          selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <h2 class="page-title"><ShoppingBagSolid size={6}/><Trans
              key="ls_listing_products"/></h2>
            <span class="text-sm"><Trans key="ls_listing_products_description"/></span>
          </div>
          <SearchBar setSearchTerm={() => {}} placeholder="ls_search_title_or_variation"/>
          <ProductFilters orderBy={() => ProductSorting.MostRecent} setOrderBy={() => {}} isLoading={() => false}/>
          <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
            <div
              class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
                <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns}
                       status={() => TableStatus.Loaded} tableId="product_listings">
                  <For each={products}>{(product) => (
                    <ListingProductTableRow product={product} isLoading={() => false}/>
                  )}</For>
                </Table>
              </div>
              <TablePagination totalCount={products.length} page={() => 1} setPage={() => {}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingLocked
