import { type Component, type JSX } from 'solid-js'

export interface DialogProps {
  readonly maxWidth?: string
  readonly overflow?: true
  readonly children: JSX.Element
  readonly withoutBackdrop?: true
  readonly rounded?: true
  readonly contentFit?: true
}

const Dialog: Component<DialogProps> = (props) => {
  return (
    <div class="relative z-[50]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
           classList={{ 'bg-gray-500': !props.withoutBackdrop, 'bg-opacity-75': !props.withoutBackdrop }}
      ></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
          <div class={`${props.overflow ? 'overflow-visible' : 'overflow-hidden'} relative transform bg-white text-left shadow-xl transition-all sm:my-8 m-3`}
               style={props.maxWidth ? `max-width : ${props.maxWidth};` : ''}
               classList={{
                 'rounded-3xl': props.rounded,
                 'rounded-md': !props.rounded,
                 'lg:w-full': !props.contentFit
               }}
          >
            { props.children }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog
