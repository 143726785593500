import { type Accessor, type Component, Show } from 'solid-js'
import { type KPI } from '../../../features/analytics/domain/models/kpi'
import ProgressRatio from './ProgressRatio'
import BreakdownBox from './BreakdownBox'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  type Size
} from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { Trans } from '@mbarzda/solid-i18next'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../../shared/app-routes'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface ValueProgressCellProps {
  readonly kpi: KPI
  readonly warning?: boolean
  readonly productId?: number
}

const ValueProgressCell: Component<ValueProgressCellProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  if (props.kpi.breakdown) {
    tooltipSize = useMeasureComponent(<BreakdownBox breakdown={props.kpi.breakdown}/>)
  }

  const showTooltip = (event: MouseEvent): void => {
    const breakdown = props.kpi.breakdown
    if (!breakdown || !tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <BreakdownBox breakdown={breakdown} position={position}/>
    )
  }

  const hideTooltip = (): void => {
    if (!props.kpi.breakdown) return
    removeTooltip()
  }

  return (
    <div class="relative">
      <div class="flex gap-2 items-center">
        <Show when={props.warning && props.productId}>{(productId) => (
          <A href={AppRoutes.ProductCogs(productId())} class="cursor-pointer text-red-500">
            <MiniExclamationTriangle/>
          </A>
        )}
        </Show>
        <Show when={props.kpi.value !== undefined} fallback={
          <span class="text-gray-500"><Trans key="ls_generic_n_a" /></span>
        }>
          <span onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {formatNumericValue(props.kpi.type, props.kpi.value)}
          </span>
        </Show>
      </div>
      <Show when={props.kpi.value !== undefined}>
        <ProgressRatio value={props.kpi.progressRatio} prevValue={props.kpi.prevValue} type={props.kpi.type} kpi={props.kpi.id}/>
      </Show>
    </div>
  )
}

export default ValueProgressCell
