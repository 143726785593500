import { onCleanup } from 'solid-js'

export const clickOutside = (el: HTMLElement, accessor?: () => unknown): void => {
  const onClick = (e: Event): void => {
    !el.contains(e.target as Node) && accessor?.()
  }
  document.body.addEventListener('click', onClick)

  onCleanup(() => {
    document.body.removeEventListener('click', onClick)
  })
}
