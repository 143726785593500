import LockedViewDialog, { type LockedViewDialogProps } from '../../shared/components/LockedViewDialog'
import PeriodsUpgrade from '../../../assets/images/periods-upgrade.png'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import RectangleStackSolid from '../../../assets/heroicons/RectangleStackSolid'
import { type Component } from 'solid-js'
import ShoppingBagSolid from '../../../assets/heroicons/ShoppingBagSolid'

const dialogConfig: LockedViewDialogProps = {
  title: 'ls_locked_period_title',
  image: PeriodsUpgrade,
  features: [
    'ls_locked_period_feature_1',
    'ls_locked_period_feature_2',
    'ls_locked_period_feature_3',
    'ls_locked_period_feature_4',
    'ls_locked_period_feature_5'
  ],
  extraFeatures: [
    { icon: <MiniEtsy />, name: 'ls_locked_period_extra_feature_1' },
    { icon: <ShoppingBagSolid size={5} />, name: 'ls_locked_period_extra_feature_2' },
    { icon: <RectangleStackSolid />, name: 'ls_locked_period_extra_feature_3' }
  ]
}

const LockedPeriodDialog: Component = () => {
  return (
    <LockedViewDialog {...dialogConfig} />
  )
}

export default LockedPeriodDialog
