import { KPI, KPIType } from '../../features/analytics/domain/models/kpi'
import { NumericalDataType } from '../../shared/models/numerical-data-type'
import { Breakdown } from '../../features/product/domain/models/breakdown'
import { Product, SKUProductReport } from '../../features/product/domain/models/product'
import { Listing, ListingStatus } from '../../features/product/domain/models/listing'
import { CogsType } from '../../features/product/domain/models/cogs'
import HIDDEN1 from '../../assets/images/hidden/hidden1.jpg'
import HIDDEN2 from '../../assets/images/hidden/hidden2.jpg'
import HIDDEN3 from '../../assets/images/hidden/hidden3.jpg'
import HIDDEN4 from '../../assets/images/hidden/hidden4.jpg'
import HIDDEN5 from '../../assets/images/hidden/hidden5.jpg'
import HIDDEN6 from '../../assets/images/hidden/hidden6.jpg'
import { theDayBefore } from '../../shared/helpers/date.functions'

const DEMO_IMAGES = [
  HIDDEN1,
  HIDDEN2,
  HIDDEN3,
  HIDDEN4,
  HIDDEN5,
  HIDDEN6
]

const lastWeek = theDayBefore(new Date())
lastWeek.setDate(lastWeek.getDate() - 7)

const MockedBreakdown = new Breakdown(
  [
    new KPI(KPIType.GrossSales, 14976.45, NumericalDataType.Currency, 3, 14527.16),
    new KPI(KPIType.ShippingCollected, 4253.50, NumericalDataType.Currency, -2, 4338.57),
    new KPI(KPIType.Discounts, 4253.50, NumericalDataType.Currency, -2, -4338.57),
    new KPI(KPIType.Refunds, 722.95, NumericalDataType.Currency, 1, -765.22)
  ],
  new KPI(KPIType.UnitsSold, 300.00, NumericalDataType.Units, 3, 200.00),
  new KPI(KPIType.NetSales, 10000.00, NumericalDataType.Currency, 2, 9800.00)
)

const getRandomSKU = (): string => {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'

  const pattern = 'XXXNN-XX-NNNNN-NNNX'

  return pattern.split('').map(char => {
    if (char === 'X') {
      return uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length))
    } else if (char === 'N') {
      return numbers.charAt(Math.floor(Math.random() * numbers.length))
    }
    return char
  }).join('')
}

const getBigNum = (): number => Math.random() * 8000 - 4000
const getSmallPosNum = (): number => Math.floor(Math.random() * 100)
const getPercentage = (): number => Math.floor(Math.random() * 100) - 50
const getSmallPosNumWithDecimals = (): number => Math.random() * 100

const getRandUnitKPI = (): KPI => new KPI(KPIType.NetSales, getSmallPosNum(), NumericalDataType.Units, getPercentage(), getSmallPosNum())
const getRandCurrencyKPI = (): KPI => new KPI(KPIType.NetSales, getBigNum(), NumericalDataType.Currency, getPercentage())
const getRandPercentage = (): KPI => new KPI(KPIType.NetSales, getSmallPosNumWithDecimals(), NumericalDataType.Percentage, getPercentage())

const NetSales = new KPI(KPIType.NetSales, getBigNum(), NumericalDataType.Currency, getPercentage(), getSmallPosNum(), MockedBreakdown)
const OpExpenses = new KPI(KPIType.OperatingExpenses, getBigNum(), NumericalDataType.Currency, getPercentage(), getSmallPosNum(), MockedBreakdown)
const randImage = (): string => DEMO_IMAGES[Math.floor(Math.random() * DEMO_IMAGES.length)]
const OneListing = (): Listing => new Listing(1, 'www.siroclabs.com', {
  sm: randImage(),
  md: randImage(),
  lg: randImage()
}, 'Neutral Boho Pillow Set | Beige Sofa Pillow Set | White Mud Cloth | Decor Textured Pillow Cover Set | Lumbar Throw Pillow | Small Lumbar', ListingStatus.Active)

const MockedProduct = (): Product => new Product(
  getBigNum(),
  getRandomSKU(),
  'Neutral Boho Pillow Set | Beige Sofa Pillow Set | White Mud Cloth | Decor Textured Pillow Cover Set | Lumbar Throw Pillow | Small Lumbar',
  OneListing(),
  CogsType.None,
  20,
  100,
  true,
  false,
  { fromDate: lastWeek, amount: getBigNum(), currency: 'USD' },
  lastWeek
)

const getRandProduct = (): SKUProductReport => new SKUProductReport(
  getRandomSKU(),
  MockedProduct(),
  CogsType.None,
  false,
  getRandUnitKPI(),
  NetSales,
  getRandCurrencyKPI(),
  OpExpenses,
  getRandCurrencyKPI(),
  getRandPercentage(),
  getRandPercentage()
)

export const MOCKED_SKU_PRODUCTS_REPORTS = Array.from({ length: 15 }, getRandProduct)
