import { type Component, createEffect, createSignal, For, onMount, Show } from 'solid-js'
import { clickOutside } from '../../shared/directives/click-outside'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import { PeriodType, SelectablePeriods } from '../../features/shared/period-type'
import { Trans } from '@mbarzda/solid-i18next'
import { usePeriods } from '../../shared/providers/period.provider'
import { getPeriodsByType, theDayBefore } from '../../shared/helpers/date.functions'
import { mapTimestampToDate } from '../../features/shared/map-timestamp-to-date'
import { useDateFormatter } from '../../shared/helpers/date-formatter'
import { useTracking } from '../../shared/providers/tracking.provider'

enum SelectState {
  Opening,
  Open,
  Closing,
  Closed
}

const PeriodSelector: Component = () => {
  const [selectState, setSelectState] = createSignal(SelectState.Closed)
  const [selectedPeriod, setSelectedPeriod] = createSignal<PeriodType>(PeriodType.Last7Days)
  const { periods, setPeriods } = usePeriods()
  const { shortDateFormatter } = useDateFormatter()
  const { trackEvent } = useTracking()

  onMount(() => {
    setSelectedPeriod(periods().type)
  })

  const openSelector = (): void => {
    if (selectState() !== SelectState.Open && selectState() !== SelectState.Opening) {
      setSelectState(SelectState.Opening)
      setTimeout(() => {
        setSelectState(SelectState.Open)
      }, 10)
    }
  }

  const closeSelector = (): void => {
    if (selectState() !== SelectState.Closed && selectState() !== SelectState.Closing) {
      setSelectState(SelectState.Closing)
      setTimeout(() => {
        setSelectState(SelectState.Closed)
      }, 300)
    }
  }

  const onActionOptionSelected = (period: PeriodType): void => {
    trackEvent('Time Period', { period })
    setSelectedPeriod(period)
    closeSelector()
  }

  const toggleMenu = (): void => {
    if (selectState() === SelectState.Open) {
      closeSelector()
    }
    if (selectState() === SelectState.Closed) {
      openSelector()
    }
  }

  createEffect(() => {
    setPeriods({
      type: selectedPeriod(),
      ...getPeriodsByType(selectedPeriod())
    })
  })

  const isOneDayPeriod = (): boolean => {
    return selectedPeriod() === PeriodType.Today || selectedPeriod() === PeriodType.Yesterday
  }

  return (
    <div ref={el => { clickOutside(el, closeSelector) }}>
      <div class="relative w-56 pl-3 py-1.5">
        <button onClick={toggleMenu} type="button" class="w-fit relative cursor-pointer rounded-md bg-white pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
          <span class="block truncate text-lg font-semibold"><Trans key={`ls_period_${selectedPeriod()}`} /></span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <MiniChevronDown />
          </span>
        </button>

        <div class="text-sm text-gray-500 font-light">
          <span>{shortDateFormatter(mapTimestampToDate(periods().current.from)!)}</span>
          <Show when={!isOneDayPeriod()}>
            <span> - {shortDateFormatter(theDayBefore(mapTimestampToDate(periods().current.to)!))}</span>
          </Show>
        </div>

        <ul
          class={`${selectState() === SelectState.Closed ? 'hidden' : 'visible'} ${selectState() === SelectState.Open ? ' opacity-100 ' : ' opacity-0'}` + ' absolute w-64 z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'} tabindex="-1" role="listbox" aria-labelledby="listbox-label">
          <For each={SelectablePeriods}>{(period, index) => (
            <>
              <li
                  onClick={() => { onActionOptionSelected(period) }}
                  class="bg-white hover:bg-gray-100 transition duration-300 text-gray-900 h-9 relative select-none py-2 pl-3 pr-9 cursor-pointer" id="listbox-option-0" role="option">
                <span class="block truncate" classList={{ 'font-bold': selectedPeriod() === period }}>
                  <Trans key={`ls_period_${period}`} />
                </span>
              </li>
              <Show when={index() % 2 === 1 && index() !== SelectablePeriods.length - 1}>
                <div class="w-full h-px bg-gray-200 my-1"></div>
              </Show>
            </>
          )}</For>
        </ul>
      </div>
    </div>
  )
}

export default PeriodSelector
