import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetProductProductsQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly productId: number,
    private readonly offset: number,
    private readonly limit: number
  ) {
    super(ApiEndpoint.ProductProducts)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get urlParameters(): HttpParameters {
    return {
      productId: this.productId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      offset: this.offset,
      limit: this.limit
    }
  }
}
