import { KPI, type KPIType } from '../models/kpi'
import { type KpiEntity } from '../../data/entities/kpi.entity'
import { Breakdown } from '../../../product/domain/models/breakdown'

export const KPIEntityToKPIMapper = (from: KpiEntity, id: KPIType, breakdownKpis?: KPI[], unitsKPI?: KPI): KPI => new KPI(
  id,
  from.value,
  from.type,
  from.diff ?? undefined,
  from.prev ?? undefined,
  breakdownKpis && unitsKPI
    ? new Breakdown(
      breakdownKpis,
      new KPI(id, from.value, from.type, from.diff ?? undefined, from.prev ?? undefined),
      unitsKPI
    )
    : undefined
)
