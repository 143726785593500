import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import bust from '../../../assets/images/bust.svg'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../../shared/app-routes'

export interface ProductMissingCogsBannerProps {
  readonly productId: number
}

const ProductMissingCogsBanner: Component<ProductMissingCogsBannerProps> = (props) => {
  return (
    <div class="text-white relative flex gap-4 items-center bg-red-600 justify-between min-h-[80px] flex-col xl:flex-row rounded-lg mb-6 px-8 xl:px-4 py-4 xl:py-0">
      <img src={bust} class="absolute hidden sm:block sm:w-36 xl:w-[104px] sm:top-4 sm:left-2 xl:top-auto xl:bottom-0 xl:left-4"/>
      <div class="flex flex-col xl:flex-row xl:items-center sm:pl-24 xl:pl-32 gap-4 w-full">
        <div class="flex flex-col gap-1 justify-center w-full">
          <p class="text-xl m-0"><Trans key="ls_cogs_product_missing"/></p>
          <p class="text-sm m-0"><Trans key="ls_cogs_we_can"/></p>
        </div>
        <A href={AppRoutes.ProductCogs(props.productId)}
           class="w-full xl:w-min whitespace-nowrap flex items-center justify-center rounded-md !border-gray-300 cursor-pointer !bg-white px-3.5 py-2 text-sm font-semibold text-gray-800 h-fit shadow-sm hover:!bg-gray-200 transition duration-200">
          <Trans key="ls_cogs_fix_now"/>
        </A>
      </div>
    </div>
  )
}

export default ProductMissingCogsBanner
