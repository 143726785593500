import type { Component } from 'solid-js'

const MiniMinusSmall: Component = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
          <path d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
      </svg>
  )
}

export default MiniMinusSmall
