import { type IntegrationType, ShopType } from '../models/shop'
import { type GetEtsyAuthUrlInteractor } from './get-etsy-auth-url.interactor'
import { type GetIntegrationAuthUrlInteractor } from './get-integration-auth-url.interactor'

export class StartShopConnectionInteractor {
  constructor(
    private readonly getEtsyAuthUrl: GetEtsyAuthUrlInteractor,
    private readonly getIntegrationAuthUrl: GetIntegrationAuthUrlInteractor
  ) {

  }

  public async execute(type?: ShopType, shopId?: number, trackEvent?: (event: string, properties?: object) => void): Promise<void> {
    if (!shopId || !type) return

    const url = type === ShopType.Etsy
      ? await this.getEtsyAuthUrl.execute()
      : await this.getIntegrationAuthUrl.execute(shopId, type as unknown as IntegrationType)

    if (trackEvent) {
      trackEvent(type.toUpperCase() + ' Connection', { status: 'Start' })
    }
    window.location.href = url
  }
}
