import { type PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { UpdateCheckoutStatusQuery } from '../../data/queries/update-checkout-status.query'

export class UpdateCheckoutStatusInteractor {
  constructor(
    private readonly updateCheckoutStatus: PutInteractor<void>
  ) {
  }

  public async execute(status: 'completed' | 'abandoned'): Promise<void> {
    await this.updateCheckoutStatus.execute(undefined, new UpdateCheckoutStatusQuery(status))
  }
}
