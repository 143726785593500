import { type Component, Show } from 'solid-js'
import { A, Outlet } from '@solidjs/router'
import Topbar from './topbar/Topbar'
import Logo from '../assets/images/profittree-logo.svg'
import { AppRoutes } from '../shared/app-routes'
import { useUser } from '../public/auth/user-provider'

const SimpleLayout: Component = () => {
  const { hasAccess } = useUser()

  return (
    <div class="h-[100svh]">
      <div class="flex flex-col w-full h-full">
        <div class="border-b border-gray-200 flex items-center justify-between">
          <div class="ml-8 flex items-center h-full min-w-[110px]">
            <Show when={hasAccess()} fallback={<img src={Logo} alt="logo"/>}>
              <A href={AppRoutes.Dashboard()}>
                <img src={Logo} alt="logo"/>
              </A>
            </Show>
          </div>
          <div class="grow h-full"></div>
          <Topbar hidden={true}/>
        </div>
        <main class="w-full px-6 pt-8 pb-16 flex-1 bg-blue_gray-100 flex justify-center">
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default SimpleLayout
