import { type Component, Show } from 'solid-js'
import ProgressRatio from './ProgressRatio'
import { type KPIVM } from '../../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import TextPlaceholder from './TextPlaceholder'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface StatRowProps {
  readonly kpi: KPIVM
}

const StatRow: Component<StatRowProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  return (
    <li class="relative text-sm flex justify-between gap-x-6 p-4">
      <div class="mt-1 flex leading-5 text-gray-500 font-light">
        <p><Trans key={`ls_fin_break_${props.kpi?.id}`} /></p>
      </div>

      <Show when={props.kpi.value !== undefined} fallback={
        <div class="pr-20">
          <TextPlaceholder widthCss="80px" />
        </div>
      }>
        <div class="flex items-center leading-5 gap-2">
          <span class="text-gray-900 font-light">{formatNumericValue(props.kpi.type!, props.kpi.value)}</span>
          <ProgressRatio value={props.kpi?.progressRatio} kpi={props.kpi.id}/>
        </div>
      </Show>
    </li>
  )
}

export default StatRow
