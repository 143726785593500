import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { GetProductReportQuery } from '../../data/queries/get-product-report.query'
import { type SKUProductReport } from '../../../product/domain/models/product'

export class GetProductReportInteractor {
  constructor(
    private readonly getProductReport: GetInteractor<SKUProductReport>
  ) {
  }

  public async execute (
    shopId: number,
    productId: number,
    periods: PeriodsState
  ): Promise<SKUProductReport> {
    return await this.getProductReport.execute(
      new GetProductReportQuery(shopId, productId, periods.current.from, periods.current.to, periods.prev.from)
    )
  }
}
