import { type Component, Switch, Match, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { type NumericalDataType } from '../../../shared/models/numerical-data-type'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'

export interface MinMaxTableRowProps {
  readonly min: number
  readonly max: number
  readonly type: NumericalDataType
  readonly highlightColors?: true
}

const MinMaxTableCell: Component<MinMaxTableRowProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  const valueColor = props.highlightColors ? 'text-green-500' : 'text-gray-900'

  return (
    <>
      <Show when={isNaN(props.min) || isNaN(props.max)}>
        -
      </Show>
      <Show when={!isNaN(props.min) && !isNaN(props.max)}>
        <div class="flex flex-col justify-center">
          <Switch>
            <Match when={props.min !== props.max}>
              <span class="text-xs text-gray-500"><Trans key="ls_generic_min" /> / <Trans key="ls_generic_max" /></span>
              <span class={valueColor}>
                {formatNumericValue(props.type, props.min, 2)} / {formatNumericValue(props.type, props.max, 2)}
              </span>
            </Match>
            <Match when={props.min === props.max}>
              <span class={valueColor}>{formatNumericValue(props.type, props.min, 2)}</span>
            </Match>
          </Switch>
        </div>
      </Show>
    </>
  )
}

export default MinMaxTableCell
