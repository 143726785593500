import { type Component, createSignal, onMount, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import { type IntegrationType } from '../../features/shop/domain/models/shop'
import MiniLoading from '../shared/components/MiniLoading'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import { MainModule } from '../../features/main-module'
import { type IntegrationShop } from '../../features/shop/domain/models/integration-shop'
import { useLocation } from '@solidjs/router'
import SingleSelect from '../shared/components/SingleSelect'
import { useShop } from '../../shared/providers/shop.provider'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'

export interface IntegrationShopNotMatchedDialogProps {
  readonly cancel: () => void
  readonly shopUpdated: () => void
  readonly integration: IntegrationType
}

const IntegrationShopNotMatchedDialog: Component<IntegrationShopNotMatchedDialogProps> = (props) => {
  const integrationName = props.integration[0].toUpperCase() + props.integration.slice(1).toLowerCase()
  const [isLoading, setIsLoading] = createSignal(true)
  const [isSaving, setIsSaving] = createSignal(false)
  const [error, setError] = createSignal(false)
  const [integrationShopId, setIntegrationShopId] = createSignal<number | undefined>()
  const [shops, setShops] = createSignal<IntegrationShop[] | undefined>()
  const location = useLocation()
  const { selectedShopId } = useShop()

  const getShops = MainModule.getShopComponent().provideGetPrintifyShops()
  const setPrintifyShop = MainModule.getShopComponent().provideSetPrintifyShop()

  onMount(() => {
    getShopList()
  })

  const getShopList = (): void => {
    setError(false)
    setIsLoading(true)
    executeWithUrlScope(
      async () => await getShops.execute(selectedShopId()!), location
    )
      .then((shops) => {
        setShops(shops)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async (): Promise<void> => {
    const printifyShopId = integrationShopId()
    const shopId = selectedShopId()
    if (shopId && printifyShopId) {
      setIsSaving(true)
      await setPrintifyShop.execute(shopId, printifyShopId)
      setIsSaving(false)
      props.shopUpdated()
    }
  }

  return (
    <Dialog maxWidth={'434px'} overflow>
      <div class="py-6 px-8 mx-auto text-black flex gap-2 flex-col w-full">
        <span class="text-base text-gray-900 font-bold">
          <Trans key="Choose your Printify Store" options={{ integration: integrationName }}/>
        </span>
        <span class="text-sm text-gray-500">
          <Trans key="Select the Printify store you want to use" options={{ integration: integrationName }}/>
        </span>
        <Show when={!error()} fallback={
          <div class="flex items-center gap-2 justify-between h-9 mt-2">
            <span class="flex items-center text-sm text-red-500 gap-2">
              <MiniExclamationCircle size={5}/><Trans key="We couldn’t load your Printify stores" options={{ integration: integrationName }}/>
            </span>
            <button onClick={getShopList} class="text-sm text-red-500 underline">
              <Trans key="ls_generic_retry"/>
            </button>
          </div>
        }>
          <Show when={isLoading()}>
            <div class="flex items-center gap-2 text-sm h-9 mt-2">
              <MiniLoading/><Trans key="Loading your Printify stores, please wait."/>
            </div>
          </Show>
          <Show when={shops()}>{(shops) => (
            <div class="text-sm h-9 mt-2">
              <SingleSelect options={shops().map(shop => ({ id: shop.id, value: shop.title }))} selectOption={setIntegrationShopId}/>
            </div>
          )}</Show>
        </Show>
      </div>

      <DialogActions>
        <button onClick={props.cancel} class="btn btn--white">
          <Trans key="ls_generic_close"/>
        </button>
        <Show when={shops() && shops()!.length > 0}>
          <button disabled={isSaving() || error()} onClick={save} class="btn btn--primary">
            <Trans key="ls_generic_save"/>
          </button>
        </Show>
      </DialogActions>
    </Dialog>
  )
}

export default IntegrationShopNotMatchedDialog
