import type { Component } from 'solid-js'
import { type IconProps } from './icon'

const MagnifyingGlass: Component<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class={`w-${props.size ?? 6} h-${props.size ?? 6}`}>
      <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
    </svg>
  )
}

export default MagnifyingGlass
