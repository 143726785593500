import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { Listing } from '../models/listing'
import { type ListingEntity } from '../../data/entities/listing.entity'

export class ListingEntityToListingMapper implements Mapper<ListingEntity, Listing> {
  public map(from: ListingEntity): Listing {
    return new Listing(
      from.listing_id,
      from.url,
      {
        sm: from.image_urls.small,
        md: from.image_urls.medium,
        lg: from.image_urls.large
      },
      from.title,
      from.state
    )
  }
}
