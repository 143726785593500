import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class SetPrintifyShopQuery extends PostNetworkQuery {
  constructor (private readonly shopId: number, private readonly printifyShopId: number) {
    super(ApiEndpoint.Printify)
  }

  public get urlParameters(): HttpParameters {
    return {
      shopId: this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      printify_shop_id: this.printifyShopId
    }
  }
}
