import { PostNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import { type HttpParameters } from '@coris-ts/data/parameter-type'

export class UpdateCheckoutStatusQuery extends PostNetworkQuery {
  constructor (
    private readonly status: string
  ) {
    super(ApiEndpoint.CheckoutStatus)
  }

  public get queryParameters(): HttpParameters {
    return {
      status: this.status
    }
  }
}
