import { type Component, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Cog6ToothSolid from '../../assets/heroicons/Cog6ToothSolid'
import SettingsShopsCard from './SettingsShopsCard'
import StarterPlanWarning from './StarterPlanWarning'
import { useUser } from '../../public/auth/user-provider'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'
import UnlimitedShopsCard from './UnlimitedShopsCard'

const Settings: Component = () => {
  const { userPurchases } = useUser()

  return (
    <div class="flex flex-col gap-8">
      <h1 class="page-title">
        <Cog6ToothSolid /><Trans key="ls_settings_title"/>
      </h1>
      <Show when={!userPurchases()?.isPro}>
        <div class="flex flex-col gap-4">
          <Show when={!userPurchases()?.canHaveMultipleShops}>
            <span class="flex items-center gap-2 font-medium">
              <MiniExclamationCircle size={5}/><Trans key="ls_settings_reached_maximum_shops"/>
            </span>
          </Show>
          <div class="flex gap-6 flex-col sm:flex-row lg:flex-col xl:flex-row">
            <StarterPlanWarning/>
            <Show when={!userPurchases()?.canHaveMultipleShops}>
              <UnlimitedShopsCard />
            </Show>
          </div>
        </div>
      </Show>
      <SettingsShopsCard/>
    </div>
  )
}

export default Settings
