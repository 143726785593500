import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'

export interface MismatchCogsBannerProps {
  readonly openCogsEditForm: (open: boolean) => void
  readonly period: 'current' | 'prev'
}

const MismatchCogsBanner: Component<MismatchCogsBannerProps> = (props) => {
  return (
    <div class="rounded-lg bg-orange-500 text-white flex flex-col sm:flex-row justify-between gap-4 sm:items-center p-4">
      <div class="flex flex-col gap-1">
        <p class="text-xl m-0" innerHTML={t(`ls_cogs_banner_mismatch_${props.period}_line1`)}></p>
        <p class="text-sm m-0"><Trans key={`ls_cogs_banner_mismatch_${props.period}_line2`}/></p>
      </div>
      <button
        onClick={() => { props.openCogsEditForm(true) }}
        class="rounded-md bg-white border-gray-200 text-gray-800 text-sm px-3 py-2 hover:bg-gray-200 duration-200 w-full sm:w-fit"
      >
        <Trans key="ls_cogs_fix_now"/>
      </button>
    </div>
  )
}

export default MismatchCogsBanner
