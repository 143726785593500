import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCogsUpdateStatusQuery } from '../../data/queries/get-cogs-update-status.query'
import { type CogsUpdateStatus } from '../models/cogs-update-status'

export class GetCogsUpdateStatusInteractor {
  constructor(
    private readonly getUpdateStatus: GetInteractor<CogsUpdateStatus[]>
  ) {
  }

  public async execute (
    shopId: number
  ): Promise<CogsUpdateStatus[]> {
    return await this.getUpdateStatus.execute(
      new GetCogsUpdateStatusQuery(shopId)
    )
  }
}
