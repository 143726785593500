import { type Component, onMount, Show } from 'solid-js'
import LoadingScreen from './loading-screen'
import { useNavigate, useSearchParams } from '@solidjs/router'
import { AppRoutes } from '../shared/app-routes'
import { useUser } from './auth/user-provider'

export interface SubscribedGuardProps {
  readonly component: Component
}

const SubscribedGuard: Component<SubscribedGuardProps> = (props) => {
  const { hasAccess } = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  onMount(async () => {
    if (!hasAccess()) {
      console.info("SubscribedGuard: User doesn't have access to the requested page. Redirecting to payment page.")
      const paddleCheckoutToken = searchParams._ptxn
      if (paddleCheckoutToken) {
        navigate(`${AppRoutes.UnsubscribedAccountSettings()}?_ptxn=${encodeURIComponent(paddleCheckoutToken)}`, { replace: true })
        return
      }

      const offer = searchParams.offer
      const queryString = offer ? `?offer=${encodeURIComponent(offer)}` : ''

      navigate(`${AppRoutes.Payment()}${queryString}`, { replace: true })
    }
  })

  return (
    <Show when={hasAccess()} fallback={<LoadingScreen />}>
      <props.component />
    </Show>
  )
}

export default SubscribedGuard
