import {
  createContext,
  useContext,
  type JSX,
  createSignal,
  type Accessor,
  type Setter
} from 'solid-js'
import { type Paddle } from '@paddle/paddle-js'

interface PaddleContextType {
  readonly paddle: Accessor<Paddle | undefined>
  readonly setPaddle: Setter<Paddle | undefined>
}

const [paddle, setPaddle] = createSignal<Paddle | undefined>()

const defaultContextValue: PaddleContextType = {
  paddle,
  setPaddle
}

const PaddleContext = createContext<PaddleContextType>(defaultContextValue)

export const PaddleProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <PaddleContext.Provider value={defaultContextValue}>
      {props.children}
    </PaddleContext.Provider>
  )
}

export const usePaddle = (): PaddleContextType => {
  return useContext(PaddleContext)
}
