import { type Component, Show, createEffect, createSignal, For } from 'solid-js'
import { useLocation } from '@solidjs/router'
import { useShop } from '../../../shared/providers/shop.provider'
import { usePeriods } from '../../../shared/providers/period.provider'
import { useError } from '../../../shared/providers/error.provider'
import { MainModule } from '../../../features/main-module'
import { executeWithUrlScope } from '../../shared/functions/url-scoped-promise'
import SearchBar from '../../shared/components/SearchBar'
import Table, { type TableColumn, TableStatus } from '../../shared/components/Table'
import TablePagination from '../../shared/components/TablePagination'
import { ProductFilterSorting, ProductSorting } from '../../../features/shared/product-sorting'
import { getGenericTableStatus } from '../../shared/functions/table.functions'
import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type ListingReport } from '../../../features/product/domain/models/listing'
import { KPIType } from '../../../features/analytics/domain/models/kpi'
import ProductListingTableRow from './ProductListingsTableRow'
import { type SKUProductReport } from '../../../features/product/domain/models/product'
import { Trans } from '@mbarzda/solid-i18next'
import RectangleStackSolid from '../../../assets/heroicons/RectangleStackSolid'
import ProductFilters from '../../shared/components/ProductFilters'

export interface ProductListingsTableProps {
  readonly product?: SKUProductReport
}

const ProductListingsTable: Component<ProductListingsTableProps> = (props) => {
  const DEFAULT_PAGE_SIZE = 15
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [listings, setListings] = createSignal<PaginationOffsetLimit<ListingReport>>()
  const [orderBy, setOrderBy] = createSignal<string | undefined>(ProductFilterSorting[ProductSorting.MostRecent])
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [page, setPage] = createSignal(0)

  const { selectedShopId } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'listing' },
    { id: KPIType.UnitsSold, withHint: true, sortable: true },
    { id: KPIType.NetSales, withHint: true, sortable: true },
    { id: KPIType.Cogs, withHint: true, sortable: true },
    { id: KPIType.OperatingExpenses, withHint: true, sortable: true },
    { id: KPIType.OperatingProfit, withHint: true, sortable: true },
    { id: KPIType.Profit, withHint: true, sortable: true },
    { id: KPIType.Roi, withHint: true, sortable: true }
  ]

  const analyticsComponent = MainModule.getAnalyticsComponent()
  const getProductListings = analyticsComponent.provideGetProductListingReports()

  const location = useLocation()

  const refreshTable = (page: number = 1): void => {
    const shopId = selectedShopId()
    const productId = props.product?.product.id
    if (!shopId || !productId) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getProductListings.execute(
        shopId,
        productId,
        periods(),
        offset,
        DEFAULT_PAGE_SIZE,
        orderBy(),
        searchTerm()
      ), location
    )
      .then((listings) => {
        setListings(listings)
      })
      .catch((_) => {
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  createEffect(() => {
    if (props.product) {
      setPage(1)
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  })

  createEffect(() => {
    orderBy()
    searchTerm()
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  const showNoResults = (): boolean => {
    return listings()?.values.length === 0
  }

  const isFiltering = (): boolean => {
    return !!searchTerm()
  }

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), isFiltering(), listings()?.values)
  }

  return (
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h2 class="page-title"><RectangleStackSolid size={6}/><Trans key="ls_product_listings"/></h2>
        <span class="text-sm"><Trans key="ls_product_appears"/></span>
      </div>
      <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title_or_variation"/>
      <ProductFilters orderBy={orderBy} setOrderBy={setOrderBy} isLoading={isLoading}/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table orderBy={orderBy} setOrderBy={setOrderBy} columns={columns}
                   status={getTableStatus} tableId="product_listings">
              <For each={listings()?.values}>{(listing) => (
                <ProductListingTableRow listing={listing} product={props.product!.product} isLoading={isLoading}/>
              )}</For>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={listings()?.size ?? 0} page={page} setPage={setPage}/>
          </Show>
        </div>
      </div>
    </div>
  )
}

export default ProductListingsTable
