import type { Config } from 'tailwindcss'

import generated from '@tailwindcss/forms'
import defaultTheme from 'tailwindcss/defaultTheme'

const colorClasses = [
  'blue', 'green', 'purple', 'orange', 'pink', 'red', 'yellow', 'gray', 'amber'
]
const colorGradients: number[] = Array.from({ length: 9 }, (_, index) => (index * 100))
colorGradients.unshift(50)

const sizeClasses = [3, 4, 5, 6, '3.5']

const config: Config = {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx,css,md,mdx,html,json,scss}',
    './node_modules/flowbite/**/*.js'
  ],
  darkMode: 'class',
  plugins: [
    generated
  ],
  safelist: [
    ...colorClasses.flatMap((color) => (
      colorGradients.map((gradient) => `bg-${color}-${gradient}`)
    )),
    ...sizeClasses.map((size) => `h-${size} w-${size} size-${size}`)
  ],
  theme: {
    extend: {
      colors: {
        etsy: {
          50: '#FEF5F1',
          100: '#FDEBE2',
          200: '#FBD4C1',
          300: '#F9BA9A',
          400: '#F59565',
          500: '#F1641E',
          600: '#DD530E',
          700: '#C54A0C',
          800: '#A33D0A',
          900: '#782D07'
        },
        japanese: {
          50: '#E8FEFF',
          100: '#CBF5F7',
          200: '#9DEEF2',
          300: '#60DFE5',
          400: '#34C7CF',
          500: '#1DA6AD',
          600: '#1D7F85',
          700: '#1D6C70',
          800: '#17585C',
          900: '#1E4446'
        },
        forest_green: {
          50: '#F0FFF7',
          100: '#D7FAE7',
          200: '#BFF2D7',
          300: '#98EBBE',
          400: '#74D4A0',
          500: '#56BF86',
          600: '#4BA574',
          700: '#298553',
          800: '#216B43',
          900: '#195233'
        },
        maize: {
          50: '#FFF9EB',
          100: '#FFF0CC',
          200: '#FFE29C',
          300: '#FAD57A',
          400: '#EBC15B',
          500: '#DEAD35',
          600: '#D19F26',
          700: '#C49112',
          800: '#946900',
          900: '#7A5700'
        },
        june_bud: {
          50: '#F9FCE5',
          100: '#F4FCC2',
          200: '#E9F792',
          300: '#D9EB73',
          400: '#CFE359',
          500: '#BCCD54',
          600: '#98A834',
          700: '#76851C',
          800: '#5C690E',
          900: '#414A0A'
        },
        blue_gray: {
          50: '#F8FAFC',
          100: '#F1F5F9',
          200: '#E2E8F0',
          300: '#CBD5E1',
          400: '#94A3B8',
          500: '#64748B',
          600: '#475569',
          700: '#334155',
          800: '#1E293B',
          900: '#0F172A'
        }
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
        poppins: ['Poppins', 'Inter', ...defaultTheme.fontFamily.sans]
      },
      fontSize: {
        24: '1.5rem'
      },
      height: {
        3.5: '0.875rem'
      },
      minHeight: {
        9: '2.25rem',
        64: '64px'
      },
      minWidth: {
        6: '1.5rem'
      },
      maxWidth: {
        xxs: '16rem'
      },
      paddingRight: {
        18: '4.5rem'
      },
      width: {
        3.5: '0.875rem'
      }
    }
  }
}

export default config
