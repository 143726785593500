import { type KpiEntity } from '../../../analytics/data/entities/kpi.entity'
import { type KPIType } from '../../../analytics/domain/models/kpi'
import { type ListingEntity } from './listing.entity'
import { type CogsEntity, type CogsTypeEntity } from './cogs.entity'

export class ProductEntity {
  constructor(
    public readonly product_id: number,
    public readonly variant_name: string,
    public readonly listing: ListingEntity,
    public readonly cogs_type: CogsTypeEntity,
    public readonly cogs?: CogsEntity,
    public readonly first_order_timestamp?: number,
    public readonly sku?: string,
    public readonly price?: number,
    public readonly quantity?: number,
    public readonly is_available?: boolean,
    public readonly is_deleted?: boolean
  ) {
  }
}

export class ProductReportEntity {
  constructor (
    public readonly data_points: Record<KPIType, KpiEntity>,
    public readonly product: ProductEntity,
    public readonly missing_cogs: boolean
  ) {
  }
}

export class SKUProductReportEntity {
  constructor (
    public readonly data_points: Record<KPIType, KpiEntity>,
    public readonly products: ProductEntity[],
    public readonly missing_cogs: boolean,
    public readonly sku?: string
  ) {
  }
}
