import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import { Cached } from '@coris-ts/helpers/utilities'
import { RepositoryMapper } from '@coris-ts/repository/repository-mapper'
import { provideDefaultNetworkDataSource } from '@coris-ts/repository/data-source/network.data-source'
import { SingleDataSourceRepository } from '@coris-ts/repository/single-data-source.repository'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCurrentUserInteractor } from './domain/interactors/get-current-user.interactor'
import { UserEntity } from './data/entities/user.entity'
import { UserEntityToUserMapper } from './domain/mappers/user.mappers'
import { type User } from './domain/models/user'
import { PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { VoidMapper } from '@coris-ts/repository/mapper/mapper'
import { UpdateCurrentUserInteractor } from './domain/interactors/update-current-user.interactor'

export interface AuthComponent {
  provideGetCurrentUser: () => GetCurrentUserInteractor
  provideUpdateCurrentUser: () => UpdateCurrentUserInteractor
}

export class AuthDefaultModule implements AuthComponent {
  constructor (private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getUserRepository(): RepositoryMapper<UserEntity, User> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      UserEntity
    )

    const repository = new SingleDataSourceRepository(dataSource, dataSource, dataSource)

    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new UserEntityToUserMapper(),
      new VoidMapper()
    )
  }

  public provideGetCurrentUser(): GetCurrentUserInteractor {
    return new GetCurrentUserInteractor(
      new GetInteractor<User>(this.getUserRepository())
    )
  }

  public provideUpdateCurrentUser(): UpdateCurrentUserInteractor {
    return new UpdateCurrentUserInteractor(
      new PutInteractor<User>(this.getUserRepository())
    )
  }
}
