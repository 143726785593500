export enum ShopType {
  Etsy = 'etsy',
  Printful = 'printful',
  Printify = 'printify'
}

export enum IntegrationType {
  Printful = 'printful',
  Printify = 'printify'
}

export enum FetchStatus {
  Pending = 'pending',
  Failed = 'failed',
  Completed = 'completed'
}

export class Integration {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly fetchStatus: FetchStatus,
    public readonly invalidToken: boolean
  ) {
  }
}

export class Shop {
  constructor (
    public readonly id: number,
    public readonly name: string,
    public readonly url: string,
    public readonly imageUrl: string,
    public readonly firstFetch: FetchStatus,
    public readonly fullFetch: FetchStatus,
    public readonly invalidToken: boolean,
    public readonly integrations: Record<IntegrationType, Integration | undefined>,
    public readonly currencyCode: string,
    public readonly lastListingFetchDate?: Date,
    public readonly lastReportFetchDate?: Date
  ) {}

  public get isFirstFetchReady(): boolean {
    return this.firstFetch === FetchStatus.Completed
  }

  public get isFullyFetched(): boolean {
    return this.fullFetch === FetchStatus.Completed
  }
}
