import {PutRepository} from "@coris-ts/repository/repository";
import {Query, VoidQuery} from "@coris-ts/repository/query/query";
import {DefaultOperation, Operation} from "@coris-ts/repository/operation/operation";

export class PutInteractor<T> {
    constructor(private readonly repository: PutRepository<T>) {}

    public execute(
        value: T | undefined,
        query: Query = new VoidQuery(),
        operation: Operation = new DefaultOperation(),
    ): Promise<T> {
        return this.repository.put(value, query, operation);
    }
}
