import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { HttpParameters } from '@coris-ts/data/parameter-type'

export class GetShopQuery extends GetNetworkQuery {
  constructor (private readonly shopId: number) {
    super(ApiEndpoint.Shop)
  }

  public get urlParameters(): HttpParameters {
    return {
      shopId: this.shopId
    }
  }
}
