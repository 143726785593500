import LockedViewDialog, { type LockedViewDialogProps } from '../../shared/components/LockedViewDialog'
import ListingsUpgrade from '../../../assets/images/listings-upgrade.png'
import MiniCalendarDays from '../../../assets/heroicons/MiniCalendarDays'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import { type Component } from 'solid-js'
import ShoppingBagSolid from '../../../assets/heroicons/ShoppingBagSolid'

const dialogConfig: LockedViewDialogProps = {
  title: 'ls_locked_listings_title',
  image: ListingsUpgrade,
  features: [
    'ls_locked_listings_feature_1',
    'ls_locked_listings_feature_2',
    'ls_locked_listings_feature_3',
    'ls_locked_listings_feature_4',
    'ls_locked_listings_feature_5'
  ],
  extraFeatures: [
    { icon: <MiniCalendarDays />, name: 'ls_locked_listings_extra_feature_1' },
    { icon: <MiniEtsy />, name: 'ls_locked_listings_extra_feature_2' },
    { icon: <ShoppingBagSolid size={5} />, name: 'ls_locked_listings_extra_feature_3' }
  ]
}

const LockedListingsDialog: Component = () => {
  return (
    <LockedViewDialog {...dialogConfig} />
  )
}

export default LockedListingsDialog
