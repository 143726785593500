import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type Shop } from '../models/shop'
import { GetShopQuery } from '../../data/queries/get-shop.query'

export class GetShopInteractor {
  constructor(
    private readonly getShop: GetInteractor<Shop>
  ) {}

  public async execute(shopId: number): Promise<Shop> {
    return await this.getShop.execute(new GetShopQuery(shopId))
  }
}
